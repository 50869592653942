import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function HighlightsLandingSkeleton() {
  const [cardCount, setCardCount] = useState(1);
  useEffect(() => {
    const updateCardCount = () => {
      const width = window.innerWidth;
      if (width >= 1280) {
        setCardCount(3);
      } else if (width >= 768) {
        setCardCount(2);
      } else {
        setCardCount(1);
      }
    };

    updateCardCount();
    window.addEventListener("resize", updateCardCount);

    return () => window.removeEventListener("resize", updateCardCount);
  }, []);
  return (
    <>
    <div className="md:px-[24px] px-[16px]">
      <div className="container mx-auto">
        <div className="flex justify-between pb-[16px]">
          <div className=" flex items-center gap-[8px]">
          <Skeleton width={30} borderRadius={30} height={30} />
        <Skeleton width={100} borderRadius={30} height={25} />
        </div>
        <Skeleton width={100} borderRadius={30} height={25} />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[20px]">
          {[...Array(cardCount)].map((_, index) => (
            <div className="  w-full rounded-[12px] border-[1px]   h-[421px]">
              <div className="h-[248px] flex  justify-end  pr-[24px] relative items-end animate-pulse rounded-t-[12px] bg-[#E6E6E6] ">
                <div className="bg-[#747474] h-[40px]  -mb-[20px] rounded-full w-[40px]"></div>
              </div>
              <div className="px-[15px] py-[16px]">
                <div className="flex justify-between items-center">
                  <Skeleton width={70} height={25} />
                </div>

                <div className="pt-[15px] flex items-center gap-[10px]">
                  <Skeleton width={25} height={25} />
                  <Skeleton width={83} borderRadius={26} height={15} />
                </div>

                <div className="pt-[10px]   gap-[10px]">
                  <Skeleton count={2} borderRadius={26} height={20} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    </>
  );
}

export default HighlightsLandingSkeleton;
