import React from 'react'
import Skeleton from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";

function PhotooftheweekSkeleton() {
  return (
    <div className=" md:w-[300px] h-[600px] ">
    <Skeleton  height={600} borderRadius={12} />
 
  </div>
  )
}

export default PhotooftheweekSkeleton