import React, { useEffect, useState } from "react";
import HighlightsCard from "../../Components/Cards/HighlightsCard";
import { FiArrowUpRight } from "react-icons/fi";
import LargeButton from "../../Buttons/Largebutton";
import HighlightsModel from "../../Components/Highlights/HighlightsModel";
import HighlightsViewAllSkelton from "../../Components/Skeleton/Highlights/HighlightsViewAllSkelton";
import { Link } from "react-router-dom";
function HighlightsViewAllPage() {
  const [highlightsData, setHighlightsData] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedHighlightId, setSelectedHighlightId] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [setCountText] = useState("1 of 0");
  const [displayCount, setDisplayCount] = useState(8);
  const [showAllCards, setShowAllCards] = useState(false); 

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(8);
      } 
      else if (windowWidth >= 1280 && windowWidth < 1540) { 
        setDisplayCount(6);
      } else if (windowWidth >= 768 && windowWidth < 1280) { 
        setDisplayCount(4);
      } else {
        setDisplayCount(4);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sortehighlights= highlightsData.slice().sort((a, b) => a.id - b.id);
  const reversedhighlights = sortehighlights.slice().reverse();
  const highlightToDisplay = showAllCards ? reversedhighlights : reversedhighlights.slice(0, displayCount);


  const toggleShowAllCards = () => {
    setShowAllCards(!showAllCards);
  };

  useEffect(() => {
    fetchHighlightsData();
  }, []);

  const fetchHighlightsData = () => {
    setLoading(true); 
    fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setHighlightsData(data.data);
        setLoading(false); 
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setLoading(true); 
      });
  };

  const [selectedHighlight, setSelectedHighlight] = useState(null);

  useEffect(() => {
    if (selectedHighlightId) {
      fetchHighlightDetails(selectedHighlightId);
    }
  }, [selectedHighlightId]);

  const fetchHighlightDetails = (highlightId) => {
    fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}${highlightId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedHighlight(data);
      })
      .catch((error) => {
        console.error(
          "There was a problem with fetching highlight details:",
          error
        );
      });
  };

  const nextItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === highlightsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === 0 ? highlightsData.length - 1 : prevIndex - 1
    );
  };

  const updateCountNumber = (index) => {
    const currentCount = index + 1;
    const totalCount = highlightsData.length;
    setCountText(`${currentCount} of ${totalCount}`);
  };

  const handleHighlightClick = (highlightId) => {
    setSelectedHighlightId(highlightId);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  function truncateString(str, maxWords) {
    const words = str.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "";
    }
    return str;
  }

  if (highlightsData.length === 0) {
    return (
      <div>
        <HighlightsViewAllSkelton />
      </div>
    );
  }

  return (
    <>
      <div className="footer_bottom_box h-[144px] "></div>
      <div className="pt-[64px] relative  font-Rubik md:pb-[108px] pb-[84px] md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[16px]">
            <div className="flex gap-[8px] items-center text-[18px]">
            <Link to="/">
              <div className="flex cursor-pointer gap-[4px] items-center text-[#4D4D4D]">
              
                <h3>HOME</h3>
                <FiArrowUpRight />
              </div>
              </Link>
              <h3 className="text-[#9E120E] underline leading-[20px]">
                HIGHLIGHTS
              </h3>
            </div>
            
            <div className="text-[#A50808] flex flex-col gap-[8px] italic lg:text-[36px] md:text-[28px] text-[16px] leading-[120%]">
              <h3>SPOTLIGHT</h3>
              <h2 className="font-medium lg:text-[48px] md:text-[40px] text-[28px] ">
                EXPLORE OUR TOP PICKS
              </h2>
            </div>

            {loading ? (
              <HighlightsViewAllSkelton /> 
            ) : (
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[12px]">
                {highlightToDisplay.map((highlight) => (
                  <HighlightsCard
                    key={highlight.id}
                    image={highlight.image}
                    video_link={highlight.video_link}
                    title={truncateString(highlight.title, 10)}
                    heading={highlight.heading}
                    date={highlight.date}
                    handleHighlightClick={() => handleHighlightClick(highlight.id)}
                  />
                ))}
              </div>
            )}
{displayCount < highlightsData.length && (
            <div className="w-full flex md:flex-row flex-col justify-center contact-component">
              <LargeButton onClick={toggleShowAllCards}>                <h3>{showAllCards ? "VIEW LESS" : "VIEW MORE"}</h3></LargeButton>
            </div>
              )}
          </div>
        </div>
      </div>

      {modalOpen && selectedHighlight && (
        <HighlightsModel
          modalOpen={modalOpen}
          handleClose={handleClose}
          selectedHighlight={selectedHighlight}
          nextItem={nextItem}
          prevItem={prevItem}
          updateCountNumber={updateCountNumber}
          highlightsData={highlightsData}
          currentItemIndex={currentItemIndex}
        />
      )}
    </>
  );
}

export default HighlightsViewAllPage;
