import React, { useEffect, useState } from "react";
import ShopHeroBanner from "../../Components/Shop/ShopHeroBanner";
import ShopViewAll from "../../Components/Shop/ShopViewAll";

function ShopViewAllPage() {
  const [shopProducts, setShopProducts] = useState([]);
 
  useEffect(() => {
    const fetchShopProducts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL_SHOPS);
        if (!response.ok) {
          throw new Error('Failed to fetch shop products');
        }
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          const publishedProducts = data.data.filter(product => product.status === "Published");
          setShopProducts(publishedProducts);
        } else {
          throw new Error('API returned success false or invalid data format');
        }
      } catch (error) {
        console.error('Error fetching shop products:', error);
      }
    };

    fetchShopProducts();
  }, []);
 

  return (
    <>
      <div>
        <ShopHeroBanner />
      </div>
      <div>
        <ShopViewAll shopProducts={shopProducts} />
      </div>
    </>
  );
}

export default ShopViewAllPage;
