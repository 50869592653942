import React, { useRef } from "react";
import { jersey_img, shop_bg } from "../../Constants/Data";
import Whitebutton from "../../Buttons/Whitebutton";

function ShopHeroBanner() {
  const shopSectionRef = useRef(null);

  const scrollToShopSection = () => {
    if (shopSectionRef.current) {
      shopSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      className="flex items-end  text-white font-Rubik  w-full md:h-[560px] h-full object-cover"
      style={{
        backgroundImage: `linear-gradient(90deg, rgba(101,13,10,0.7540266106442577) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,1) 100%),url(${shop_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="md:px-[24px] px-[16px] pt-[100px] flex justify-center w-full">
        <div className="container mx-auto">
          <div className="w-full flex md:flex-row flex-col-reverse  justify-between lg:gap-0 md:gap-[32px]  items-center lg:pr-[64px]  pb-[36px]">
            <div className="flex flex-col w-full gap-[20px]">
              <div className="flex flex-col gap-[12px] text-center md:text-start">
                <div className=" lg:leading-[150%] flex flex-col lg:gap-[16px] md:gap-[12px] italic">
                  <h2 className="lg:text-[44px] md:text-[36px] text-[32px] font-semibold ">
                    SPORT YOUR SPIRIT
                  </h2>
                  <h5 className="uppercase md:text-[22px]  text-[16px]">
                    carnival jersey - 2024
                  </h5>
                </div>
                <p className="text-[16px] leading-[150%] lg:w-[718px]  md:block hidden">
                  Introducing the Johnians '94 Batch Printed Jersey, crafted
                  from premium polyester for comfort and style. It's more than
                  clothing; it's a symbol of pride. Represent your alma mater or
                  celebrate a special year. Get yours now and relive the
                  memories.
                </p>
              </div>
              <Whitebutton  onClick={scrollToShopSection}>SHOP NOW</Whitebutton>
            </div>
            <img src={jersey_img} alt="" className="lg:w-[393px] md:w-[352px] lg:h-[392px] md:h-[352px]  h-[345px]" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopHeroBanner;
