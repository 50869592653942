import React, { useState } from "react";
import { new_history_image, old_history_image } from "../Constants/Data";
import { IoPlayCircle } from "react-icons/io5";

function AboutHistory() {
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle the read more/less state
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="py-[150px]  z-10 bg-white relative md:px-[24px] px-[16px] h-full font-Rubik">
      <div className="container mx-auto">
        <div className="flex flex-col gap-[56px]">
          <div className="flex justify-between lg:flex-row flex-col w-full gap-[24px]">
            <div className="flex flex-col gap-[16px] w-full justify-center">
              <div className=" italic text-center ">
                <h3 className="text-[#A50808] md:text-[36px] text-[20px] leading-[120%]">
                  A JOURNEY THROUGH
                </h3>
                <h4 className="font-medium md:text-[48px] text-[28px] text-[#A50808] leading-[120%]">
                  JOHNIANS'S HISTORY
                </h4>
              </div>

              <img
                src={old_history_image}
                alt=""
                className="object-cover lg:hidden block"
              />
              <div className="flex flex-col gap-[16px] text-justify   text-[#212121] text-[16px] font-light leading-[22px]">
                <p>
                  Jaffna Johnians sports club, the name rings thousands of bells
                  in my heart. I was a very small part of this organisation from
                  1985 till 1990. Even though I am a lifetime member.
                </p>
                <p>
                  First of many memories was when Sooriakumar Anna came home
                  back in 1985 to ask my parents’ permission for me to play a
                  friendly game against Old Golds (Jaffna college old boys). I
                  just finished my first ever cricket season playing under 15
                  matches for St. John’s College. I was awarded best fielder by
                  the school for that season, and I thought Johnians Sports Club
                  probably short of players and thought this person can field.
                  When I got to Jaffna College grounds, I was star struck. As a
                  15-year-old, seeing Soori Anna, Thevapalan Anna, Mahinda Anna,
                  Kulan Anna, Ganespanjan Anna, Vaheesan Anna and few others in
                  the same dressing room was surreal.
                </p>
                <p>
                  I was waiting for someone to come say hello to me as I could
                  not get any words out of my mouth. The ice was broken when all
                  of them come said hey and tapped on my shoulder and said
                  welcome. These names are still legendary in SJC cricket and
                  still give me goosebumps whenever I meet any of them. When we
                  took the field for the first inning I was given the ball as a
                  first change bowler. Then only I realised that I was not
                  brought in to field I am going to be one of them. I was nerves
                  and shaking all the way to the popping crease. Soori Anna at
                  mid-off and Maninda Anna at Mid-on encouraged me every time I
                  needed a pat on the back. This particular game really thought
                  me cricket is a game for confident minds and the team members
                  are soldiers fighting with you all the way. This not only did
                  some good for my cricket but also taught me a few lessons in
                  life too.
                </p>

                {isExpanded && (
                  <>
                    <p>
                      There are a lots of memories with Jaffna Johnians Sports
                      Club, winning Umpires Association tournament in 1986,
                      winning a few Shabra trophies, winning couple of Jolly
                      Star tournaments, losing a couple of the finals all of
                      which put cream on my cricketing days. I was also joined
                      by few more legendary names over those years such as
                      Raveendra, The great Thirukumar, Nesakumar, Ahilan,
                      Jeyashankar, Niranjan Bhuvanaratnam, Satheesan,
                      Surenkumar, Prashanthan, Murali so on.{" "}
                    </p>

                    <p>
                      I started under the captaincy of Sooriakumar Anna, then
                      played under Thevapalan Anna, Mahinda Anna, Raveendra,
                      Vaheesan Anna. I captained St. John’s college for two
                      years in a row and been an unbeaten skipper. But I was
                      really hoping I will be given the captaincy for Jaffna
                      Johnians. When the time came, I was over the moon. I took
                      the role as my first ever and really enjoyed and became
                      unbeaten too for the two friendly games I captained.
                    </p>
                    <p>
                      Another memorable day was when we Jaffna Johnians Sports
                      Club introduced a six-a-side tournament in 1987 at SJC
                      ground for the first time in Jaffna, the first ever ball
                      was bowled by me for Jaffna Johnians Sports Club against
                      Grasshoppers Sports Club and the first ever ball of the
                      tournament was bowled by myself which also brought me the
                      first wicket of the tournament. We did not win the
                      tournament, but it was an experience.{" "}
                    </p>
                    <p>
                      These fond memories will never go away from me and Jaffna
                      Johnians Sports Club, not far behind St. John’s College,
                      has played a major part in my life to shape me as a person
                      I am now.{" "}
                    </p>

                    <p>
                      I was a very minute part of St. John’s College and Jaffna
                      Johnians Sports club. I was not a great cricketer compared
                      to a lot of names who played cricket for both St. John’s
                      College and Jaffna Johnians sports Club, but the joy,
                      lessons learned, friendship, respect I gain from past and
                      present fellow Johnians is huge.
                    </p>

                    <h5 className="text-black font-medium">Thana Sanjeevan</h5>
                    <span>(1989 batch proud Johnian)</span>
                  </>
                )}

                <div className="flex justify-center">
                  <button
                    onClick={toggleExpand}
                    className="text-[#ffffff] flex item underline-offset-0 font-medium bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px] w-fit px-[12px] py-[8px] focus:outline-none"
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            </div>

            {/* <img src={old_history_image} alt="" className="object-cover hidden lg:block" /> */}
          </div>
          <div className="flex justify-between w-full lg:flex-row flex-col-reverse gap-[24px]">
            <img
              src={new_history_image}
              alt=""
              className="object-cover hidden lg:block"
            />
            <div className="flex flex-col gap-[32px] lg:w-[630px] justify-center">
              <div className=" italic flex items-center gap-[12px] ">
                <IoPlayCircle className="text-[#A50808] text-[26px] " />

                <h4 className="font-medium text-[24px] text-[#A50808] leading-[120%]">
                  OUR VISIONN
                </h4>
              </div>
              <img
                src={new_history_image}
                alt=""
                className="object-cover block lg:hidden"
              />
              <div className="flex flex-col gap-[16px]  text-[#212121] text-[16px] font-light leading-[22px]">
                <p>
                  To establish a vibrant space for St. John’s College alumni to
                  pursue their passion for sports, enabling them to achieve
                  their personal and athletic goals while fostering community
                  and camaraderie.
                </p>
              </div>
              <div className=" italic flex items-center gap-[12px] ">
                <IoPlayCircle className="text-[#A50808] text-[26px] " />

                <h4 className="font-medium text-[24px] text-[#A50808] leading-[120%]">
                  OUR MISSION
                </h4>
              </div>
              <img
                src={new_history_image}
                alt=""
                className="object-cover block lg:hidden"
              />
              <div className="flex flex-col gap-[16px]  text-[#212121] text-[16px] font-light leading-[22px]">
                <p>
                  To provide St. John’s College old boys with comprehensive
                  sports facilities, programs, and resources that promote
                  physical fitness, skill development, and lifelong connections.
                  We aim to create a supportive environment that inspires alumni
                  to actively engage in sports, enhancing their well-being and
                  fostering a strong sense of community.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutHistory;
