import React, { useEffect, useState } from 'react'
import { IoPlayCircle } from 'react-icons/io5'
import Smallbutton from '../../Buttons/Smallbutton'
import { Link } from 'react-router-dom'
import ShopCard from '../Cards/ShopCard'



function ShopLatestedProducts({currentItemId}) {
  const [shopProducts, setShopProducts] = useState([]);
    const [displayCount, setDisplayCount] = useState(2);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(4);
      } else if (windowWidth >= 1280 && windowWidth < 1540) {
        setDisplayCount(3);
      } else if (windowWidth >= 680 && windowWidth < 1280) {
        setDisplayCount(2);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchShopProducts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL_SHOPS);
        if (!response.ok) {
          throw new Error('Failed to fetch shop products');
        }
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          const publishedProducts = data.data.filter(product => product.status === "Published");
          setShopProducts(publishedProducts);
        } else {
          throw new Error('API returned success false or invalid data format');
        }
      } catch (error) {
        console.error('Error fetching shop products:', error);
      }
    };

    fetchShopProducts();
  }, []);

  const filteredSportsData = shopProducts
  ? shopProducts.filter((shopProducts) => shopProducts.id != currentItemId)
  : [];
  const reversedshop = [...filteredSportsData].reverse();
  return (
   <>
   <div className='md:pt-[64px] pt-[48px] pb-[48px] flex flex-col px-[16px] md:px-[24px] gap-[16px]'>
   <div className="flex justify-between pr-[30px]">
              <div className="flex text-primary italic font-medium  gap-[12px] items-center sports-news-heading">
                <IoPlayCircle className=" text-[26px] " />
                <h3 className="text-[24px]">LATESTED PRODUCTS</h3>
              </div>

              <Link to="/shopviewall" className="md:block hidden">
                <Smallbutton>
                  <h2>VIEW ALL</h2>
                </Smallbutton>
              </Link>
            </div>

            <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]">
            {reversedshop.slice(0, displayCount).map((shop) => (
              <ShopCard
                key={shop.id}
                shop={shop}
                new_tag={shop.new}
                thumbnail_image={shop.thumbnail_image}
                product_category={shop.product_category}
                final_price={shop.final_price}
                price={shop.price}
                discount={shop.discount}
                name={shop.name}
                sub_title={shop.sub_title}
                discount_status={shop.discount_status}
                handleshops={`/shopindividualpage/${shop.id}-${shop.subtitle.replace(/\s+/g, '')}`}
              />
            ))}
          </div>

          
          <Link to="/shopviewall" className="md:hidden block">
                <Smallbutton>
                  <h2>VIEW ALL</h2>
                </Smallbutton>
              </Link>

   </div>
   </>
  )
}

export default ShopLatestedProducts