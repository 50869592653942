import React, { useState, useEffect } from 'react';
import Smallbutton from '../../Buttons/Smallbutton';
import { IoPlayCircle } from 'react-icons/io5';
import ExploreOursportsblogs from './ExploreOursportsblogs';
import { Link } from 'react-router-dom';
import SportBlogMobileCarosel from './SportBlogMobileCarosel';
import BlogLandingSkeleton from '../Skeleton/Blog/BlogLandingSkeleton';

function Sportsblog() {
  const [sportsData, setSportsData] = useState([]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_BLOGS;
      console.log("Fetching data from:", apiUrl); 

      try {
        const response = await fetch(apiUrl);
        console.log("API response status:", response.status); 
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data); 
        const publishedBlogs = data.filter(
          (data) => data.status === "published"
        );
        setSportsData(publishedBlogs); 
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (sportsData.length === 0) {
    return (
      <div>
        <BlogLandingSkeleton />
      </div>
    );
  }
  return (
    <div className='md:py-[116px] py-[64px] md:px-[24px] px-[16px]'>
      <div className='container mx-auto  flex flex-col gap-[16px]'>
        <div className=''>
          <div className='flex justify-between pr-[30px]'>
            <div className="flex text-primary italic font-medium  gap-[12px] items-center sports-news-heading">
              <IoPlayCircle className=" text-[26px] " />
              <h3 className="md:text-[24px] text-[18px]">EXPLORE OUR SPORTS BLOGS</h3>
            </div>

            <Link to ="/sportsblogviewall" className='md:block hidden contact-component' onClick={scrollToTop}>
            <Smallbutton>
              <h2 className=''>VIEW ALL</h2>
            </Smallbutton>
            </Link>
          </div>
        </div>
        <div className='md:block hidden'>
        {sportsData && <ExploreOursportsblogs sportsData={sportsData} />}

        </div>

        <div className='md:hidden block'>
        {sportsData && <SportBlogMobileCarosel sportsData={sportsData} />}
        </div>
      </div>

      <Link to ="/sportsblogviewall" className='md:hidden block ' onClick={scrollToTop}>
            <Smallbutton>
              <h2>VIEW ALL</h2>
            </Smallbutton>
            </Link>


    </div>
  );
}

export default Sportsblog;
