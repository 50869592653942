import React from 'react'

function TermsAndCondition() {
  return (
    <>
    <div className="bg-header-gradient h-[144px] z-10 "></div>
    <div className="pt-[48px] py-[96px] md:px-[24px] px-[16px]">
      <div className="container mx-auto">
        <div className="flex flex-col font-Rubik  gap-[48px]">
          <h2 className="font-medium italic lg:text-[44px] md:text-[40px] text-[32px] text-[#B82222] leading-[150%]">
          TERMS AND CONDITION
          </h2>
          <div className="flex flex-col gap-[32px] te">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <div className="flex flex-col gap-[16px] text-[#212121] font-light">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                ea commodo consequat. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[32px]">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <div className="flex flex-col gap-[16px] text-[#212121] font-light">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[32px]">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <ul className="flex flex-col gap-[32px] list-disc pl-[20px]  text-[#212121] font-light">
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea
              </li>
              <li>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea
              </li>
            </ul>
          </div>

          <div className="flex flex-col gap-[32px]">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <div className="flex flex-col gap-[16px] text-[#212121] font-light">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-[32px]">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <div className="flex flex-col gap-[16px] text-[#212121] font-light">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-[32px]">
            <h3 className="font-medium italic text-[16px] leading-[150%]">
              LOREM IPSUM DOLOR SIT AMET
            </h3>
            <div className="flex flex-col gap-[16px] text-[#212121] font-light">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default TermsAndCondition