import React from 'react'
import Header from '../Components/Header'
import { Outlet } from "react-router-dom";
import Footer from '../Components/Footer';

function Rootlayout() {
  return (
    <>
    <header>
        <Header/>
    </header>

    <div>
        <Outlet/>
    </div>

    <footer>
        <Footer/>
    </footer>
    </>
  )
}

export default Rootlayout