import React, { useEffect, useState } from "react";
import Smallbutton from "../../Buttons/Smallbutton";
import { Link } from "react-router-dom";
import { IoPlayCircle } from "react-icons/io5";
import SportsBlogCard from "../Cards/SportBlogCard";

function LatestedBlogs({idIndivitual}) {
  const [sportsData, setSportsData] = useState(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_BLOGS;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setSportsData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    console.log('===========idIndivitual===========');
    console.log(idIndivitual);
    console.log('====================================');
    fetchData();
  }, []);

  const filteredSportsData = sportsData
  ? sportsData.filter((blog) => blog.id != idIndivitual)
  : [];
  

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath1 = "/storage/blogdetails/";

  return (
    <>
      <div className="py-[96px] px-[16px] md:px-[24px]">
        <div className="container mx-auto  flex flex-col gap-[16px]">
          <div className="">
            <div className="flex justify-between pr-[30px]">
              <div className="flex text-primary italic font-medium  gap-[12px] items-center sports-news-heading">
                <IoPlayCircle className=" text-[26px] " />
                <h3 className="text-[24px]">LATESTED BLOGS</h3>
              </div>

              <Link to="/sportsblogviewall" className="hidden md:block contact-component" onClick={scrollToTop}>
                <Smallbutton>
                  <h2>VIEW ALL</h2>
                </Smallbutton>
              </Link>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px] ">
            {filteredSportsData &&
              filteredSportsData.map((blog) => (
               
                  <SportsBlogCard
                  key={blog.id} 
                    blog={blog}
                    apiUrl={apiUrl}
                    imagePath1={imagePath1}
               
                  />

              
              ))}

            <Link to="/sportsblogviewall" className="block md:hidden" onClick={scrollToTop}>
              <Smallbutton>
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default LatestedBlogs;
