import React, { useEffect, useState } from "react";
import {
  contact_herobanner_bg,
  facebook_icon,
  gmail_icon,
  location_icon,
  pattern_bg,
  phone_icon,
} from "../Constants/Data";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { IoIosWarning } from "react-icons/io";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Toaster, toast } from 'react-hot-toast'; 
const options = [
  { value: "General enquiry", label: "General enquiry" },
  { value: "Donation", label: "Donation" },
  { value: "Blog update", label: "Blog update" },
  { value: "Tournament Registration ", label: "Tournament Registration " },
  { value: "Join Member", label: "Join Member" },
  { value: "Buy Merchant Products", label: "Buy Merchant Products" },
];

function Contactus() {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    reason: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  

  const handleChange = (selectedOption) => {
    setFormData({
      ...formData,
      reason: selectedOption.value,
    });
    setErrors({
      ...errors,
      reason: "",
    });
  
  };

  const handleChangePhone = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
    setErrors({
      ...errors,
      phoneNumber: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.success("Order submitted successfully!");
    const validationRules = {
      fullName: "Full Name is required",
      email: "Email is required",
      phoneNumber: "Mobile Number is required",
      reason: "Please select the option",
      message: "Message is required",
    };

    const newErrors = {};

    Object.entries(validationRules).forEach(([field, rule]) => {
      if (!formData[field].trim()) {
        newErrors[field] = rule;
      } else if (field === "email" && !/^\S+@\S+\.\S+$/.test(formData.email)) {
        newErrors.email = "Enter a valid email address";
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);

        const response = await new Promise((resolve) =>
          setTimeout(() => resolve({ status: 200 }), 1000)
        );

        if (response.status) {
          setFormData({
            fullName: "",
            email: "",
            phoneNumber: "",
            reason: "",
            message: "",
          });
          setSubmitted(true);
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        
        toast.error("There was an error submitting the form.");
      }
      
      
      finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [submitted]);

  return (
    <>
    <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
      <div
        className=" flex  justify-center items-end h-[560px] w-full object-cover     "
        style={{
          backgroundImage: `linear-gradient(-270deg, rgba(24, 24, 24, 0.85), rgba(101, 13, 10, 0.85)),url(${contact_herobanner_bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="w-full flex justify-end">
        <img
          src={pattern_bg}
          alt=""
          className="absolute -z-10 md:h-[800px] lg:h-auto "
        />
      </div>

      <div className=" lg:-mt-[280px] md:-mt-[350px]  -mt-[380px]  lg:px-[24px] px-[16px]   ">
        <div className="container  mx-auto">
          <div className="flex  lg:flex-row flex-col   w-full justify-between text-white font-Rubik ">
            <div className="w-full">
              <div className="contatct-contents lg:w-[607px]  flex flex-col gap-[80px]   ">
                <div className="flex flex-col pb-[16px] lg:pb-0  gap-[16px]">
                  <h2 className="md:text-[58px] text-[32px] md:leading-[68px] leading-[42px] flex flex-col   font-medium italic">
                    <span className="font-light text-[28px] md:text-[58px]">
                      {" "}
                      Let's Create Sporting{" "}
                    </span>
                    Moments Together
                  </h2>

                  <p className="font-light text-[16px] md:flex hidden   leading-[24px] tracking-[0.2px]">
                    Welcome to our sports club! We're delighted to connect with
                    you. Whether you're a seasoned athlete, an aspiring player,
                    a dedicated supporter, or someone simply interested in our
                    activities, we're here to assist you.
                  </p>
                </div>
                <div className=" lg:flex  flex-col hidden  gap-[36px]">
                  <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
                    <img src={location_icon} alt="" />
                    <div className="text-[#9E120E] flex flex-col gap-[8px]">
                      <h3 className="font-medium text-[20px]">LOCATION</h3>
                      <h4 className="text-[#4D4D4D] text-[16px]">
                        St.John's College, Main Street, Jaffna, Sri Lanka, 40000
                      </h4>
                    </div>
                  </div>

                  <div className="flex   items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
                    <img src={gmail_icon} alt="" />
                    <div className="text-[#9E120E] flex flex-col gap-[8px]">
                      <h3 className="font-medium text-[20px]">EMAIL ADDRESS</h3>
                      <h4 className="text-[#4D4D4D] text-[16px]">
                        stjohns@sltnet.lk
                      </h4>
                    </div>
                  </div>
                  <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
                    <img src={phone_icon} alt="" />
                    <div className="text-[#9E120E] flex flex-col gap-[8px]">
                      <h3 className="font-medium text-[20px]">PHONE</h3>
                      <h4 className="text-[#4D4D4D] text-[16px]">
                         (+94) 21 222 2432
                      </h4>
                    </div>
                  </div>
                  <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
                    <img src={facebook_icon} alt="" />
                    <div className="text-[#9E120E] flex flex-col gap-[8px]">
                      <h3 className="font-medium text-[20px]">FACEBOOK</h3>
                      <h4 className="text-[#4D4D4D] text-[16px]">
                        Jaffna Johnians Sports Club 
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-form-background font-Rubik  rounded-[12px] h-full w-full pt-[34px] md:px-[24px] px-[16px] pb-[24px] ">
              <form
                className="flex flex-col w-full gap-[32px]   "
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-[16px] ">
                  <div className="grid items-start lg:grid-cols-1  grid-cols-1  md:grid-cols-2 gap-4  font-medium leading-[24px] ">
                    <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
                      <div className="flex  justify-between px-[4px]">
                        <label>FULL NAME</label>
                      </div>
                      <input
                        type="text"
                        name="fullName"
                        placeholder="Enter Name"
                        value={formData.fullName}
                        onChange={(e) =>
                          setFormData({ ...formData, fullName: e.target.value })
                        }
                        className={` input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black    focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px] ${
                          errors.fullName ? "border-red-500 " : ""
                        }`}
                      />
                      {errors.fullName && (
                        <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                          {errors.fullName}
                          <IoIosWarning />
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
                      <div className="flex justify-between px-[4px]">
                        <label>EMAIL ADDRESS </label>
                      </div>
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        placeholder="Enter email address"
                        className={`input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black    focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px]  ${
                          errors.email ? "border-red-500" : ""
                        }`}
                      />
                      {errors.email && (
                        <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                          {errors.email}
                          <IoIosWarning />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="grid items-start lg:grid-cols-1  grid-cols-1  md:grid-cols-2 gap-4  font-medium leading-[24px] ">
                    <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
                      <div className="flex justify-between px-[4px]">
                        <label>PHONE</label>
                      </div>
                      <PhoneInput
                        defaultCountry="LK" 
                        value={formData.phoneNumber}
                        onChange={handleChangePhone}
                        placeholder="(000) 000-0000"
                        className={` input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black     focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px]
                  
                        ${errors.phoneNumber ? "border-red-500" : ""}`}
                      />
                      {errors.phoneNumber && (
                        <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                          {errors.phoneNumber}
                          <IoIosWarning />
                        </span>
                      )}
                    </div>

                    <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]  ">
                      <div className="flex justify-between px-[4px]">
                        <label>WHY YOU CONTACT US</label>
                      </div>
                      <Select
                        classNames="list"
                        value={options.find(
                          (option) => option.value === formData.eventType
                        )}
                        name="eventType"
                        onChange={handleChange}
                        placeholder="Select the option"
                        options={options}
                    

                        isSearchable={false}
                        className={`border-solid border-[#00000009] border-[1px] rounded-[12px] ${
                          errors.eventType ? "border-red-500" : ""
                        }`}
                      />

                      
                      {errors.eventType && (
                        <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                          {errors.eventType}
                          <IoIosWarning />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px] ">
                    <div className="flex justify-between px-[4px]">
                      <label>MESSAGE</label>
                    </div>
                    <textarea
                      name="message"
                      rows={6}
                      id="message"
                      value={formData.message}
                      onChange={(e) =>
                        setFormData({ ...formData, message: e.target.value })
                      }
                      className={`bg-[#ffffff] rounded-[12px]  text-[14px] font-medium text-[#4D4D4D]  px-[16px] pl-[16px]  outline-none   lining-nums  placeholder-[#939191] placeholder:font-medium placeholder:text-[16px] h-[200px] hover:bg-[#F6F6F6] pt-[16px]   focus:placeholder-[#F6F6F6] valid:before:bg-black    placeholder-shown:bg-[#ffffff] border-[1px] focus:bg-[#F6F6F6]   ${
                        errors.message ? "border-red-500" : ""
                      }`}
                      placeholder="Type something here..."
                    ></textarea>
                    {errors.message && (
                      <span className="flex gap-[4px] items-center text-sm text-red-700  ">
                        {errors.message}
                        <IoIosWarning />
                      </span>
                    )}
                  </div>
                </div>

                <button
                  className={`h-[60px] text-[18px] text-white uppercase flex items-center justify-center ${
                    loading
                      ? "bg-[#5D0F0C]"
                      : submitted
                      ? "bg-black"
                      : "bg-[#9E120E] rounded-[8px] hover:bg-[#7E100C]"
                  }`}
                  disabled={loading}
                  onClick={handleSubmit}
                  style={{ position: "relative" }}
                >
                  {loading && (
                    <div className="absolute flex items-center ml-[20px]">
                      <div className="loader"></div>
                    </div>
                  )}
                  {!loading && (
                    <>
                      {submitted && (
                        <RiCheckboxCircleFill className="w-6 h-6 text-white mr-2" />
                      )}
                      {submitted ? "Submitted" : "SEND MESSAGE"}
                    </>
                  )}
                  {loading && !submitted && (
                    <span className="ml-2">Sending</span>
                  )}
                </button>
              </form>
            </div>
          </div>

          <p className="font-light text-[16px] md:hidden flex font-Rubik  leading-[24px] tracking-[0.2px]">
            Welcome to our sports club! We're delighted to connect with you.
            Whether you're a seasoned athlete, an aspiring player, a dedicated
            supporter, or someone simply interested in our activities, we're
            here to assist you.
          </p>
          <div className=" lg:hidden font-Rubik  grid md:grid-cols-2 grid-cols-1 mt-[48px]  gap-[36px]">
            <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
              <img src={location_icon} alt="" />
              <div className="text-[#9E120E] flex flex-col gap-[8px]">
                <h3 className="font-medium text-[20px]">LOCATION</h3>
                <h4 className="text-[#4D4D4D] text-[16px]">
                  St.John's College, Main Street, Jaffna, Sri Lanka, 40000
                </h4>
              </div>
            </div>

            <div className="flex   items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
              <img src={gmail_icon} alt="" />
              <div className="text-[#9E120E] flex flex-col gap-[8px]">
                <h3 className="font-medium text-[20px]">EMAIL ADDRESS</h3>
                <h4 className="text-[#4D4D4D] text-[16px]">
                  stjohns@sltnet.lk
                </h4>
              </div>
            </div>
            <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
              <img src={phone_icon} alt="" />
              <div className="text-[#9E120E] flex flex-col gap-[8px]">
                <h3 className="font-medium text-[20px]">PHONE</h3>
                <h4 className="text-[#4D4D4D] text-[16px]">
                   (+94) 21 222 2432
                </h4>
              </div>
            </div>
            <div className="flex  items-start gap-[12px] border-solid border-b-[1px] pb-[12px]  ">
              <img src={facebook_icon} alt="" />
              <div className="text-[#9E120E] flex flex-col gap-[8px]">
                <h3 className="font-medium text-[20px]">FACEBOOK</h3>
                <h4 className="text-[#4D4D4D] text-[16px]">
                  Jaffna Johnians Sports Club 
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactus;
