import React, { useEffect, useState } from "react";
import OtherPlayers from "../../Components/SportsPlayers/OtherPlayers";

import { FiArrowUpRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { player_pattern } from "../../Constants/Data";
import PlayerIndividualSkeleton from "../../Components/Skeleton/Players/PlayerIndividualSkeleton";

function PlayerIndividualPage() {
  const { id } = useParams();
  const [player, setPlayer] = useState(null);
  

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL_PLAYERS}/${id}`;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        // Log the full response for debugging
        console.log("API Response:", data);

        if (data.success && data.data) {
          const playerData = data.data.player;
          if (playerData && playerData.status === "Published") {
            setPlayer(playerData);
          } else {
            throw new Error(
              "Player not published or invalid player data format"
            );
          }
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

   

    fetchData();
  }, [id]);

  if (!player) {
    return <div>
       <PlayerIndividualSkeleton/>
    </div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/player/";

  console.log("All sports players:", player);

  const renderHighScoreOpponent = () => {
    if (!player || !player.playermatch || player.playermatch.length === 0) return null;

    const highestScoreMatch = player.playermatch.reduce(
      (prevMatch, currentMatch) => {
        return currentMatch.playermatch_highestscore >
          prevMatch.playermatch_highestscore
          ? currentMatch
          : prevMatch;
      },
      player.playermatch[0]
    );

    if (!highestScoreMatch.playermatch_opponent) {
      return null; 
    }

    const opponentName = highestScoreMatch.playermatch_opponent
    .trim() 
    .split(/\s+/) 
    .map(word => word.charAt(0))
    .join("");
    return <p>{opponentName}</p>;
  };

  return (
    <>
      <div className="bg-header-gradient h-[144px] z-10 "></div>

      {/* PLAYER --///--- (DETAILS) ---- PERSONAL  */}
      <div className="pt-[48px] pb-[108px]  font-Rubik md:px-[24px] px-[16px]  ">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[16px]  ">
            <div className=" flex flex-col  gap-[12px] b z-10">
              <div className="flex   gap-[8px] items-center md:text-[18px] text-[14px]">
                <Link to="/">
                <div className="flex  gap-[4px] items-center text-[#4D4D4D]">
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                </Link>
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  <h3>PLAYERS</h3>
                  <FiArrowUpRight />
                </div>
                <h3 className="text-[#9E120E]  uppercase underline  leading-[20px]">
                {player.firstname}
                <span className="font-bold"> {player.lastname}</span>
                </h3>
              </div>
            </div>
           
            <div className="flex lg:flex-row  flex-col     ">
             
              <div className="w-full bg-custom-gradient  relative  lg:h-[526px] h-full flex lg:flex-row flex-col        z-10 rounded-[12px]   font-Rubik cursor-pointer">
                 
              <div className=" flex justify-center lg:hidden ">
                  <img src={player_pattern} alt="" className=" absolute  -z-10   " />
                </div>
                <div className="flex lg:flex-row flex-col-reverse w-full lg:pl-[32px]">
                
                  <div className="individual-card-content flex    w-full  items-center ">
                    <div className="flex flex-col gap-[16px] w-full lg:items-start items-center bg-[#980C0C] pt-[12px] lg:pt-0 lg:bg-transparent ">
                      <h3 className="uppercase flex flex-col lg:text-[36px] text-[24px] lg:text-[#9E120E] text-white">
                        {player.firstname}
                        <span className="font-bold"> {player.lastname}</span>
                      </h3>

                      <h4 className="uppercase bg-[#E0E0E0] text-[14px] p-[8px] text-[#3F3F3F] font-medium  rounded-full">
                        {player.position}
                      </h4>
                      <div className="flex flex-col items-center lg:items-start  gap-[16px] border-t-[1px]  w-full lg:w-[480px]  justify-center lg:justify-start  border-[#CECECE] lg:text-black text-white ">
                        <div className="flex   border-b-[1px] lg:border-hidden w-full justify-center lg:justify-start  ">
                          <div className="flex flex-col py-[12px] px-[16px] gap-[12px] text-center lg:text-start border-r-[1px] text-[14px]  border-[#CECECE]">
                            <h4>BORN</h4>
                            <h4 className="font-medium">
                              {formatDate(player.date_of_birth)}
                            </h4>
                          </div>

                          <div className="flex flex-col py-[12px] px-[16px] gap-[12px] text-center capitalize lg:text-start lg:border-r-[1px] text-[14px]  border-[#CECECE]">
                            <h4>BATTING STYLE</h4>
                            <h4 className="font-medium">
                              {player.batting_style}
                            </h4>
                          </div>

                          <div className="lg:flex flex-col py-[12px] capitalize px-[16px] gap-[12px] hidden  text-[14px]  ">
                            <h4>BOWLING STYLE</h4>
                            <h4 className="font-medium">
                              {player.bowling_style}
                            </h4>
                          </div>
                        </div>

                        <div className="lg:flex flex-col gap-[8px] hidden   ">
                          <h3 className="text-[#212121] text-[20px] font-medium italic uppercase">
                            about <span>{player.name}</span>
                          </h3>
                          <p className="lg:text-[16px] text-[12px]   ">
                           {truncateText(player.detail,300)}
                          </p>
                        </div>
                        <div className="flex flex-col capitalize py-[12px] px-[16px] gap-[12px] lg:hidden text-center  text-[14px]  ">
                          <h4>BOWLING STYLE</h4>
                          <h4 className="font-medium">
                            {player.bowling_style}
                          </h4>
                        </div>
                        
                      </div>
                    
                    </div>
                  
                  </div>
                  
                
                  <div className="  flex justify-center  w-full    ">
                    
                    <img
                      src={
                        player.image
                          ? `${apiUrl}${imagePath}${player.image}`
                          : ""
                      }
                      alt={player.name}
                      className="   object-cover pl    "
                    />
                     <div className=" lg:flex hidden flex-row-reverse    ">
                  <img src={player_pattern} alt="" className=" absolute  -z-10   " />
                </div>
                  </div>
                  
                </div>

                <div className="lg:bg-[#980C0C] bg-[#DAD9DC] lg:w-[250px]  flex flex-col lg:gap-0 gap-[12px] justify-between  items-center lg:text-white text-[#212121]  lg:rounded-r-[12px] py-[32px] px-[24px]">
                  <div className=" lg:w-[148px] w-full lg:text-[22px] text-[18px] font-bold p-4 border-b-[1px] border-[#C6C6C6]">
                    <h3>ALL STATS</h3>
                  </div>

                  <div className="flex lg:flex-col flex-row-reverse lg:justify-center justify-between w-full items-center lg:text-[24px] text-[14px]  font-bold px-4 py-2 border-b-[1px] border-[#C6C6C6]">
                    <h3>{player.total_match}</h3>
                    <h3 className="text-[12px] lg:text-[#EDEDED] font-normal">
                      MATCHES
                    </h3>
                  </div>

                  <div className="flex lg:flex-col flex-row-reverse lg:justify-center justify-between w-full items-center lg:text-[24px] text-[14px]   font-bold px-4 py-2 border-b-[1px] border-[#C6C6C6]">
                    <h3>{player.total_run}</h3>
                    <h3 className="text-[12px] lg:text-[#EDEDED] font-normal">
                      RUNS
                    </h3>
                  </div>

                  <div className="flex lg:flex-col flex-row-reverse lg:justify-center justify-between w-full items-center lg:text-[24px] text-[14px]   font-bold px-4 py-2 border-b-[1px] border-[#C6C6C6]">
                    <div className="flex gap-[8px]">
                      <h3>{player.highest_score}</h3>
                      <span>v</span>
                      <div className=" uppercase">
                        {renderHighScoreOpponent()}
                      </div>
                    </div>
                    <h3 className="text-[12px] lg:text-[#EDEDED] font-normal">
                      HIGHEST SCORE
                    </h3>
                  </div>

                  <div className="flex lg:flex-col flex-row-reverse lg:justify-center justify-between w-full items-center lg:text-[24px] text-[14px]   font-bold px-4 py-2 border-b-[1px] border-[#C6C6C6]">
                    <h3>{player.total_wicket}</h3>
                    <h3 className="text-[12px] lg:text-[#EDEDED] font-normal">
                      WICKETS
                    </h3>
                  </div>

                  <div className="flex flex-col gap-[8px] lg:hidden  ">
                    <h3 className="text-[#212121]  text-[16px] font-medium italic uppercase">
                      about <span>{player.name}</span>
                    </h3>
                    <p className="text-[14px] ">{player.detail}</p>
                  </div>
                  
                </div>
              </div>
            </div>
            
          </div>
          
        </div>
        
      </div>
      {/* !!!! --- /// --- personal --- details -- */}

      {/* --- other --- player ==== card ++++ */}
      <div>
        <OtherPlayers idIndivitual={player.id} />
      </div>

      {/* ****CARD --//-- PLAYER -- ''' OTHER */}
    </>
  );
}

export default PlayerIndividualPage;
