import React, { useEffect, useRef, useState } from "react";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import { profile_img } from "../../Constants/Data";
import SocialShareButton from "../../Buttons/SocialShareButton";

function SportsBlogCard({
  blog,
  apiUrl,
  imagePath1,

}) {
  const [showShareButtons, setShowShareButtons] = useState(false);
  const blogDetails = blog.blog_details;
  const profileImageSrc = blog.profile_image
    ? `${apiUrl}/storage/blog/${blog.profile_image}`
    : profile_img;
  const componentRef = useRef(null);

  const pageUrl = `${window.location.origin}/blogindividualpage/${blog.id}-${blogDetails && blogDetails.blog_title ? blogDetails.blog_title.replace(/\s+/g, '') : '-'}`;
  const pageTitle = blogDetails && blogDetails.blog_title ? blogDetails.blog_title : "";
  const pageImage = blogDetails && blogDetails.blog_bannerimage
    ? `${apiUrl}${imagePath1}${blogDetails.blog_bannerimage}`
    : "";

  console.log('Current page sssssss URL:', pageUrl);
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const toggleShareButtons = () => {
    setShowShareButtons((prevShowShareButtons) => !prevShowShareButtons);
  };

  const closeShareButtons = () => {
    setShowShareButtons(false);
  };

  const truncateText = (text, maxLength) => {
    if (text && text.length > maxLength) {
      return text.substring(0, maxLength) + "..";
    } else {
      return text;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        closeShareButtons();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="w-full border-[1px] border-[solid] border-[#D6D6D6] font-Rubik flex flex-col gap-[24px] rounded-[12px]">
      <div>
        <img
          src={
            blogDetails.blog_bannerimage
              ? `${apiUrl}${imagePath1}${blogDetails.blog_bannerimage}`
              : ""
          }
          alt=""
          className="h-[259px] w-full object-cover rounded-t-[12px]"
        />
      </div>
      <div className="flex flex-col relative gap-[32px] px-[20px] pb-[20px]">
        
        {showShareButtons && (
          <div className={`absolute -top-1 right-4 social-share ${showShareButtons ? 'visible' : 'hidden'}`} ref={componentRef} >
            <SocialShareButton close={closeShareButtons}
            pageUrl={pageUrl}
            pageTitle={pageTitle}
            pageImage={pageImage}
            imagePath1={imagePath1} />
          </div>
        )}
        <div className="flex justify-between">
          <div className="flex gap-[12px] items-center">
            <img
              src={profileImageSrc}
              alt=""
              className="w-[50px] border-[2px] border-[#D6D6D6] bg-teal-50 h-[50px] object-cover rounded-full"
            />

            <div className="italic text-[#B82222]">
              <h3 className="uppercase font-medium text-[16px]">
                {blog.author_name}
              </h3>
              <h4 className="text-[14px]">
                Published : {formatDate(blog.date)}
              </h4>
            </div>
          </div>

          <div className=" ">
            <div
              className="bg-[#B82222] w-[40px] h-[40px] cursor-pointer rounded-full flex items-center text-[24px] text-white justify-center transition duration-300 ease-in-out"
              onClick={toggleShareButtons}
            >
              <GoShareAndroid />
            </div>
          </div>
        </div>

        <Link to={`/blogindividualpage/${blog.id}-${blogDetails && blogDetails.blog_title ? blogDetails.blog_title.replace(/\s+/g, '') : '-'}`} onClick={handleClick}>

          <div className="text-[#212121] flex flex-col gap-[12px]">
            <h2 className="font-medium text-[24px] leading-[28px]">
              {truncateText(blogDetails.blog_title, 65)}
            </h2>
            <p className="text-[16px] leading-[22px]">
              {blogDetails.blog_subtitles &&
                blogDetails.blog_subtitles.length > 0 &&
                blogDetails.blog_subtitles[0].contents &&
                blogDetails.blog_subtitles[0].contents.length > 0 &&
                truncateText(
                  blogDetails.blog_subtitles[0].contents[0].description,
                  118
                )}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SportsBlogCard;
