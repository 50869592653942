import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GoShareAndroid } from "react-icons/go";
import { IoPlayCircle } from "react-icons/io5";
import Smallbutton from "../../Buttons/Smallbutton";
import { white_calender } from "../../Constants/Data";
import GalleryLandingSkeleton from "../Skeleton/Gallery/GalleryLandingSkeleton";
import SocialShareButton from "../../Buttons/SocialShareButton";
import { Helmet } from "react-helmet";

function GalleryOtherAlbum({ currentItemId }) {
  const [galleryData, setGalleryData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const componentRef = useRef(null);

  const closeShareButtons = () => {
    setShowShareButtons(false);
  };

  const toggleShareButtons = (item) => {
    if (item && item.id) {
      setCurrentItem(item);
      setShowShareButtons((prevShowShareButtons) => !prevShowShareButtons);
    }
  };

  useEffect(() => {
    fetchGalleryData();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchGalleryData = () => {
    fetch(process.env.REACT_APP_API_URL_GALLERYS)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setGalleryData(data.data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const filteredGalleryData = galleryData.filter(
    (item) => item.id !== currentItemId
  );

  const lastIndex = filteredGalleryData.length - 1;
  const limitedGalleryData = isMobile
    ? filteredGalleryData.slice(lastIndex, lastIndex + 1)
    : filteredGalleryData.slice(lastIndex - 1, lastIndex + 1);

  if (!galleryData.length) {
    return (
      <div>
        <GalleryLandingSkeleton />
      </div>
    );
  }

  const apiUrl = process.env.REACT_APP_API_URL;
  const thumbnailPath = "/storage/gallery_thumbnail/";

  const title = galleryData.title || "Default Title";
  const mainImage = galleryData.main_image || "default_image.jpg";
  const description = `View details and media for ${title}`;
  const pageUrl = `${window.location.origin}/galleryindividualpage/${
    galleryData.id
  }-${title.replace(/\s+/g, "")}`;

  return (
    <>
      <Helmet>
        <title>Gallery</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${apiUrl}/storage/${mainImage}`} />
        <meta property="og:url" content={pageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={`${apiUrl}/storage/${mainImage}`} />
      </Helmet>
      <div className="py-[64px] md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[16px]">
            {/* gallery heading */}
            <div className="flex md:justify-between justify-center pr-[30px]">
              <div className="flex text-primary italic font-medium gap-[12px] items-center sports-news-heading">
                <IoPlayCircle className="text-[26px]" />
                <h3 className="md:text-[24px] text-[18px]">OTHER ALBUM</h3>
              </div>

              <Link
                to="/galleryviewall"
                className="md:block hidden contact-component"
              >
                <Smallbutton>
                  <h2>VIEW ALL</h2>
                </Smallbutton>
              </Link>
            </div>

            <div className="flex gap-[24px]">
              {limitedGalleryData.reverse().map((item) => {
                const imagesCount = item.medias.reduce((count, media) => {
                  return (
                    count + (media.images ? JSON.parse(media.images).length : 0)
                  );
                }, 0);

                const videosCount = item.medias.reduce((count, media) => {
                  return count + (media.youtube_link ? 1 : 0);
                }, 0);

                const pageUrl = item.id
                  ? `${window.location.origin}/galleryindividualpage/${item.id}`
                  : "";
                console.log("Page URL:", pageUrl);

                return (
                  <div key={item.id} className="gallery-item w-full">
                    <div
                      className="h-[262px] w-full  rounded-[12px] flex fle-col p-[24px] text-white font-Rubik object-cover"
                      style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),url(${apiUrl}${thumbnailPath}${item.gallery_thumbnail})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      {/* gallery contents */}
      
                      <Link
                        to={`/galleryindividualpage/${item.id}-${
                          item.title ? item.title.replace(/\s+/g, "") : "-"
                        }`}
                        onClick={handleClick}
                        className="w-full"
                      >
                        <div className="gallery-contents w-full h-full flex flex-col justify-between">
                          <div className="flex items-center justify-between">
                            <div className="flex h-full gap-[4px] text-[14px]">
                              {videosCount > 0 && (
                                <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                                  {videosCount} Videos
                                </h4>
                              )}
      
                              {imagesCount > 0 && (
                                <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                                  {imagesCount} Photos
                                </h4>
                              )}
                            </div>
                          </div>
      
                          <div>
                            <div className="flex items-center gap-[10px]">
                              <img src={white_calender} alt="" />
                              <h3>{item.published_date}</h3>
                            </div>
                            <h2 className="lg:w-[425px] capitalize lg:text-[20px] text-[16px] font-medium lg:leading-[28px] leading-[24px]">
                              {item.title}
                            </h2>
                          </div>
                        </div>
                      </Link>
                      <div className="flex   items-start w-fit justify-end">
                        <div
                          className="bg-[#FFFFFF]  cursor-pointer  absolute   w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                          onClick={() => toggleShareButtons(item)}
                        >
                          <GoShareAndroid />
                        </div>
      
                        {showShareButtons &&
                          currentItem &&
                          currentItem.id === item.id && (
                            <div
                              className={`absolute z-10 -mt-[10px] social-share visible`}
                              ref={componentRef}
                            >
                              <SocialShareButton
                                close={closeShareButtons}
                                pageUrl={`${
                                  window.location.origin
                                }/galleryindividualpage/${currentItem.id}-${
                                  currentItem.title
                                    ? currentItem.title.replace(/\s+/g, "")
                                    : "-"
                                }`}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <Link
              to="/galleryviewall"
              className="md:hidden"
              onClick={handleClick}
            >
              <Smallbutton>
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryOtherAlbum;
