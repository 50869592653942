import React from "react";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Rootlayout from "../Layout/Rootlayout";
import Homepage from "../Pages/Homepage";
import NotFound from "../Layout/NotFound";
import Aboutuspage from "../Pages/Aboutuspage";
import Contactuspage from "../Pages/Contactuspage";
import Membershippage from "../Pages/Membershippage";
import Fundingpage from "../Pages/Fundingpage";
import Cricketpage from "../Pages/Cricketpage";
import SportsNewsViewPage from "../Pages/Newspage/Sportsnewsviewpage";
import SportsnewsViewAll from "../Pages/Newspage/SportsnewsViewAll";
import BlogViewAllPage from "../Pages/BlogPage/BlogViewAllPage";
import BlogIndividualPage from "../Pages/BlogPage/BlogIndivualPage";
import GalleryViewAllPage from "../Pages/GalleryPage/GalleryViewAllPage";
import GalleryIndivualPage from "../Pages/GalleryPage/GalleryIndivualPage";
import HighlightsViewAllPage from "../Pages/HighlightsPage/HighlightsViewAllPage";
import MatchScoreViewAllPage from "../Pages/MatchScorePage/MatchScoreViewAllPage";
import MatchSummaryPage from "../Pages/MatchScorePage/MatchSummaryPage";
import PlayerViewAllPage from "../Pages/PlayerPage/PlayerViewAllPage";
import PlayerIndividualPage from "../Pages/PlayerPage/PlayerIndividualPage";
import ShopViewAllPage from "../Pages/ShopPage/ShopViewAllPage";
import ShopIndividualPage from "../Pages/ShopPage/ShopIndividualPage";
import PrivacyandPolicy from "../Pages/PrivacyandPolicy";
import TermsAndCondition from "../Pages/TermsAndCondition";








const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Rootlayout/>} errorElement={<NotFound/>}>
      <Route index element={<Homepage/>} />
      <Route path="/aboutus" element={<Aboutuspage/>} />
      <Route path="/contactus" element={<Contactuspage/>} />
      <Route path="/membership" element={<Membershippage/>} />
      <Route path="/funding" element={<Fundingpage/>} />
      <Route path="/cricket" element={<Cricketpage/>} />
      <Route path="/sportsnewsviewpage/:id" element={<SportsNewsViewPage />} />
      <Route path="/sportsnewsviewall" element={<SportsnewsViewAll />} />
      <Route path="/sportsblogviewall" element={<BlogViewAllPage />} />
      <Route path="/blogindividualpage/:id" element={<BlogIndividualPage />} />
      <Route path="/galleryviewall" element={<GalleryViewAllPage />} />
      <Route path="/galleryindividualpage/:id" element={<GalleryIndivualPage />} />
      <Route path="/highlightsviewall" element={<HighlightsViewAllPage />} />
      <Route path="/matchscoreviewall" element={<MatchScoreViewAllPage />} />
      <Route path="/matchsummarypage/:id" element={<MatchSummaryPage />} />
      <Route path="/playerviewall" element={<PlayerViewAllPage/>} />
      <Route path="/playerindividualpage/:id" element={<PlayerIndividualPage/>} />
      <Route path="/shopviewall" element={<ShopViewAllPage/>} />
      <Route path="/shopindividualpage/:id" element={<ShopIndividualPage/>} />
      <Route path="/privacyandpolicy" element={<PrivacyandPolicy/>} />
      <Route path="/termsandcondition" element={<TermsAndCondition/>} />
      

      


      


    </Route>
  )
);

const Root = () => {
  return <RouterProvider router={router} />;
};

export default Root;
