import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import ShopCard from '../Cards/ShopCard';
function ShopCardCarosel() {
    const [shopProducts, setShopProducts] = useState([]);
    useEffect(() => {
        const fetchShopProducts = async () => {
          try {
            const response = await fetch(process.env.REACT_APP_API_URL_SHOPS);
            if (!response.ok) {
              throw new Error('Failed to fetch shop products');
            }
            const data = await response.json();
            if (data.success && Array.isArray(data.data)) {
              const publishedProducts = data.data.filter(product => product.status === "Published");
              setShopProducts(publishedProducts);
            } else {
              throw new Error('API returned success false or invalid data format');
            }
          } catch (error) {
            console.error('Error fetching shop products:', error);
          }
        };
    
        fetchShopProducts();
      }, []);
      const reversedshop = [...shopProducts].reverse().slice(0,4);
  return (
    <Swiper
    spaceBetween={30}
    centeredSlides={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="swiper-container"
  >

{reversedshop.slice(0, ).map((shop) => (
     <SwiperSlide  key={shop.id}>
      <ShopCard
               
                new_tag={shop.new}
                thumbnail_image={shop.thumbnail_image}
                product_category={shop.product_category}
                final_price={shop.final_price}
                price={shop.price}
                discount={shop.discount}
                name={shop.name}
                // sub_title={shop.subtitle}
                discount_status={shop.discount_status}
                handleshops={`/shopindividualpage/${shop.id}-${shop.subtitle.replace(/\s+/g, '')}`}
              />
              </SwiperSlide>
            ))}
            
      </Swiper>
  )
}

export default ShopCardCarosel