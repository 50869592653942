import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { players_background } from "../../Constants/Data";
import LargeButton from "../../Buttons/Largebutton";
import { Link } from "react-router-dom";

function SportsPlayerCarosel({ players }) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/player/";
  return (
    <>
      <Swiper
        spaceBetween={-80}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="flex  h-[250px]  object-cover"
      >
        {players.map((player, index) => (
          <SwiperSlide key={index} className="">
            <div
              className="w-[255px] relative  h-[180px] flex justify-center    z-10 rounded-[12px] overflow-hidden  font-Rubik cursor-pointer"
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),url(${players_background})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="flex flex-col   justify-between w-full ">
                <div className="flex justify-start items-start w-full p-[12px]">
                  <h3 className="uppercase bg-white text-[#980C0C] px-[16px] py-[8px] text-[12px] rounded-[8px] font-medium">
                    {player.position}
                  </h3>
                </div>
                <div className="playercard h-[99px] flex   px-[16px] w-full  text-center    items-end justify-center ">
                  <h3 className="   text-white uppercase text-[16px] pb-[10px]">
                    {player.name}
                  </h3>
                </div>
              </div>

              <img
               src={
                player.image ? `${apiUrl}${imagePath}${player.image}` : ""
              }
                alt={player.name}
                className="object-cover absolute h-full -z-20 -mr-[150px] rounded-[12px] "
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Link to="playerviewall" onClick={scrollToTop}>
      <div className="md:hidden block  pt-[16px] ">
        <LargeButton>
          <h3>ALL PLAYERS</h3>
        </LargeButton>
      </div>
      </Link>
    </>
  );
}

export default SportsPlayerCarosel;
