import React, { useState } from "react";
import { GoArrowUp } from "react-icons/go";
import { FaFacebook, FaPhoneAlt, FaTwitter, FaYoutube } from "react-icons/fa";
import { TbMailFilled } from "react-icons/tb";
import { motion } from "framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

function Tap_Mobile_Menu({closeMobileMenu}) {
  const [isOpen, setIsOpen] = useState({
    aboutUs: false,
    membership: false,
    cricket: false,
    blog: false,
  });

  const toggleDropdown = (section) => {
    setIsOpen((prevState) => {
   
      const newState = {
        aboutUs: false,
        membership: false,
        cricket: false,
        blog: false,
      };
     
      newState[section] = !prevState[section];
      return newState;
    });
  };

  return (
    <>
      <div className="inset-0 absolute   z-20 h-screen">
        
        <div className="md:container mx-auto font-Rubik   ">
          <div className="w-full  flex flex-col px-[16px]  md:pt-[150px] pt-[120px] md:gap-[48px] gap-[24px] bg-[#050505] md:absolute  md:h-[1300px]      inset-0">
            <motion.div
              className="bg-white flex justify-center text-[#9E120E] font-medium text-[16px] w-full md:gap-[32px] gap-[8px] px-[16px] md:px-[16px] py-[12px] h-[80px]"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 0.5, stiffness: 70 }}
            >
              <h3 className="px-[20px] flex justify-center py-[16px] md:w-[236px]  ">
                MEMBERSHIP
              </h3>

              <Link to="/sportsblogviewall" onClick={closeMobileMenu}>
              <h3 className="px-[20px] flex justify-center py-[16px] md:w-[236px]  ">
                SHOP
              </h3>
              </Link>
              <Link to="/sportsblogviewall" onClick={closeMobileMenu}>
              <h3 className="px-[20px] flex justify-center py-[16px] md:w-[236px]  ">
                BLOG
              </h3>
              </Link>
            </motion.div>

            <motion.div
              className="flex flex-col gap-[8px] text-white"
              initial={{ opacity: 1, y: "100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "100%" }}
              transition={{ duration: 0.8, type: "", stiffness: 60 }}
            >
              <div className="flex md:flex-row flex-col gap-[24px] justify-center">

              <Link to="/" onClick={closeMobileMenu} className="w-full">
                <div className="flex  contact-component justify-between w-full border-b-[1px] border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>HOME </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
                <Link to="/galleryviewall" onClick={closeMobileMenu} className="w-full">
                <div className="flex w-full  contact-component  justify-between border-b-[1px] border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>GALLERY </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
                <Link to="/sportsnewsviewall" onClick={closeMobileMenu} className="w-full">
                <div className="flex contact-component  w-full justify-between border-b-[1px] border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>NEWS </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
              </div>
              <div className="flex md:flex-row flex-col w-full gap-[24px] justify-center">
              <Link to="/shopviewall" onClick={closeMobileMenu} className="w-full">
                <div className="flex contact-component  justify-between w-full border-b-[1px]  border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>SHOP </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
                <Link to="/highlightsviewall" onClick={closeMobileMenu} className="w-full">
                <div className="flex contact-component  w-full  justify-between border-b-[1px]  border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>HIGHLIGHTS </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
                <Link to="/contactus" onClick={closeMobileMenu} className="w-full">
                <div className="flex contact-component text-nowrap  w-full  justify-between border-b-[1px]  border-[#ffffff2e] md:text-[24px] text-[20px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>CONTACT US </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                </Link>
              </div>
            </motion.div>
            <motion.div
              className=" flex flex-col "
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, x: "100%" }}
              transition={{ duration: 1.1, type: "", stiffness: 50 }}
            >
              {/* Aboutus and membership navigations */}

              <div className="flex  md:flex-row flex-col justify-center ">
                <div className=" px-[16px] w-full md:flex hidden flex-col gap-[8px]  ">
                
                  <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                    ABOUT US
                  </h3>
                
                  <div>
                  <Link
                    to="/aboutus#history"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      History
                    </h3>
                    </Link>
                    <Link
                     to="/aboutus#committeeMembers"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      committee members
                    </h3>
                    </Link>
                    <Link
                     to="/aboutus#whyChooseUs"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Funding
                    </h3>
                    </Link>
                    <Link
                     to="/aboutus#faq"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      FAQ
                    </h3>
                    </Link>
                    <Link
                     to="/aboutus#getConnected"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Social links
                    </h3>
                    </Link>
                  </div>
                </div>

                <div className=" w-full flex md:hidden flex-col gap-[8px]">
                  <h3
                    className="text-[#9E120E] flex justify-between items-center md:text-[24px] text-[20px] px-[20px] py-[16px] font-medium cursor-pointer"
                    onClick={() => toggleDropdown("aboutUs")}
                  >
                    ABOUT US{" "}
                    <span>
                    <IoIosArrowDown
                      className={`text-[#9E120E] transition-transform duration-300 ${
                        isOpen.membership ? "rotate-180" : ""
                      }`}
                    />
                    </span>
                  </h3>
                  {isOpen.aboutUs && (
                    <motion.div
                      initial={{ opacity: 0, y: "-20%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 1, y: "-100%" }}
                      transition={{ duration: 0.8, type: "", stiffness: 60 }}
                    >
                       <Link
                    to="/aboutus#history"
                    onClick={closeMobileMenu}
                  >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        History
                      </h3>
                      </Link>
                      <Link
                     to="/aboutus#committeeMembers"
                    onClick={closeMobileMenu}
                  >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Committee Members
                      </h3>
                      </Link>
                      <Link
                     to="/aboutus#whyChooseUs"
                    onClick={closeMobileMenu}
                  >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Funding
                      </h3>
                      </Link>

                      <Link
                     to="/aboutus#faq"
                    onClick={closeMobileMenu}
                  >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        FAQ
                      </h3>
                      </Link>
                      <Link
                     to="/aboutus#getConnected"
                    onClick={closeMobileMenu}
                  >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Social Links
                      </h3>
                      </Link>
                    </motion.div>
                  )}
                </div>
                <div className="px-[16px] w-full md:flex hidden flex-col gap-[8px]  ">
                  <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                    MEMBERSHIP
                  </h3>
                  <div>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Annual
                    </h3>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Global
                    </h3>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Lifetime
                    </h3>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Benefits
                    </h3>
                    <h3 className="px-[20px]  py-[12px] text-[#6A6A6A] text-[24px] ">
                      Become Member
                    </h3>
                  </div>
                </div>

                <div className=" w-full flex md:hidden flex-col gap-[8px]">
                  <h3
                    className="text-[#9E120E] flex justify-between items-center md:text-[24px] text-[20px] px-[20px] py-[16px] font-medium cursor-pointer"
                    onClick={() => toggleDropdown("membership")}
                  >
                    MEMBERSHIP
                    <span>
                    <IoIosArrowDown
                      className={`text-[#9E120E] transition-transform duration-300 ${
                        isOpen.membership ? "rotate-180" : ""
                      }`}
                    />
                    </span>
                  </h3>
                  {isOpen.membership && (
                    <motion.div
                      initial={{ opacity: 0, y: "-20%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 1, y: "-100%" }}
                      transition={{ duration: 0.8, type: "", stiffness: 60 }}
                    >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Annual
                      </h3>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Global
                      </h3>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Lifetime
                      </h3>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Benefits
                      </h3>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Become Member
                      </h3>
                    </motion.div>
                  )}
                </div>
              </div>
              {/* About and member ---// details */}

              <div className="flex md:flex-row flex-col justify-center">
                {/* cricket and blog details */}
                <div className="px-[16px] w-full md:flex hidden flex-col  gap-[8px]  ">
                  <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                    CRICKET
                  </h3>
                  <div>
                  <Link to="/playerviewall" onClick={closeMobileMenu}>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Team
                    </h3>
                    </Link>
                    {/* <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Fixture
                    </h3> */}
                    <Link to="/matchscoreviewall" onClick={closeMobileMenu}>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Match summary
                    </h3>
                    </Link>
                  </div>
                </div>

                <div className=" w-full flex md:hidden flex-col gap-[8px]">
                  <h3
                    className="text-[#9E120E] flex justify-between items-center md:text-[24px] text-[20px] px-[20px] py-[16px] font-medium cursor-pointer"
                    onClick={() => toggleDropdown("cricket")}
                  >
                    CRICKET
                    <span>
                    <IoIosArrowDown
                      className={`text-[#9E120E] transition-transform duration-300 ${
                        isOpen.membership ? "rotate-180" : ""
                      }`}
                    />
                    </span>
                  </h3>
                  {isOpen.cricket && (
                    <motion.div
                      initial={{ opacity: 0, y: "-20%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 1, y: "-100%" }}
                      transition={{ duration: 0.8, type: "", stiffness: 60 }}
                    >
                      <Link to="/playerviewall" onClick={closeMobileMenu}>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Team
                      </h3>
                      </Link>
                      {/* <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Fixture
                      </h3> */}
                      <Link to="/matchscoreviewall" onClick={closeMobileMenu}>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Match Summary
                      </h3>
                      </Link>
                      {/* <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Get Ticket
                      </h3> */}
                    </motion.div>
                  )}
                </div>
                <div className="px-[16px] w-full md:flex hidden  flex-col gap-[8px]  ">
                  <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                    BLOG
                  </h3>
                  <div>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Recent Blogs
                    </h3>
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      Create Own Blog
                    </h3>
                  </div>
                </div>
                <div className=" w-full flex md:hidden flex-col gap-[8px]">
                  <h3
                    className="text-[#9E120E] flex justify-between items-center md:text-[24px] text-[20px] px-[20px] py-[16px] font-medium cursor-pointer"
                    onClick={() => toggleDropdown("blog")}
                  >
                    BLOG{" "}
                    <span>
                    <IoIosArrowDown
                      className={`text-[#9E120E] transition-transform duration-300 ${
                        isOpen.membership ? "rotate-180" : ""
                      }`}
                    />
                    </span>
                  </h3>
                  {isOpen.blog && (
                    <motion.div
                      initial={{ opacity: 0, y: "-20%" }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 1, y: "-100%" }}
                      transition={{ duration: 0.8, type: "", stiffness: 60 }}
                    >
                       <Link to="/sportsblogviewall#recentblog" onClick={closeMobileMenu}>
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Recent Blogs
                      </h3>
                      </Link>
                      <Link to="/sportsblogviewall#blogform"  onClick={closeMobileMenu} >
                      <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px]">
                        Create Own Blog
                      </h3>
                      </Link>
                    </motion.div>
                  )}
                </div>

                {/* cricket and blog details */}
              </div>
            </motion.div>
            {/* bottom details */}
            <motion.div
              className="text-white p-[16px] flex md:flex-row flex-col-reverse md:gap-[98px] gap-[12px]"
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, x: "100%" }}
              transition={{ duration: 1.5, type: "", stiffness: 50 }}
            >
              <div className="flex text-[#565555] text-[16px] leading-[20px] md:gap-[54px] justify-between">
                <h3>Terms & Conditions</h3>
                <h3>Privacy Policy</h3>
              </div>

              <div className="flex md:gap-[20px] text-[30px] text-white justify-between md:justify-start">
                <FaFacebook />
                <FaYoutube />
                <FaTwitter />
                <FaPhoneAlt />
                <TbMailFilled />
              </div>
            </motion.div>
            {/* bottom details */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Tap_Mobile_Menu;
