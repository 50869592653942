import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function GalleryViewAllSkeleton() {
  return (
   <>
     <div className="bg-header-gradient h-[144px] z-10 "></div>
           <div className='container mx-auto pt-[48px]'>
     <div className="md:py-[100px] py-[50px] md:px-[24px]  px-[16px] ">
      <div>
      <Skeleton width={300} height={26} borderRadius={40} />
      <Skeleton width={150} height={30} borderRadius={40} />
      </div>
        
          <div className="flex md:flex-row flex-col gap-[32px] w-full pt-[20px] ">
            <div className="bg-[#d0cfcf] md:w-[586px] h-[672px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            <div className='w-full flex flex-col gap-[32px]'>
            <div className="bg-[#d0cfcf] w-full h-[320px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex  justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>

            <div className="bg-[#d0cfcf] w-full h-[320px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            </div>
          </div>

          <div className="flex md:flex-row-reverse flex-col  gap-[32px] w-full pt-[20px] ">
            <div className="bg-[#d0cfcf] md:w-[586px] h-[672px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            <div className='w-full flex flex-col gap-[32px]'>
            <div className="bg-[#d0cfcf] w-full h-[320px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex  justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>

            <div className="bg-[#d0cfcf] w-full h-[320px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            </div>
          </div>
        </div>
        </div>

   </>
  )
}

export default GalleryViewAllSkeleton