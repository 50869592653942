import React from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function GalleryLandingSkeleton() {
  return (
    <>

        <div className='container mx-auto'>
     <div className="md:py-[100px] py-[50px] md:px-[24px]  px-[16px] ">
        <div className='flex justify-between'>
          <div className="flex  gap-[12px] ">
            <Skeleton width={30} height={30} borderRadius={40} />
            <Skeleton width={147} height={26} borderRadius={40} />
            
          </div>
          <div>
            <Skeleton width={127} height={26} borderRadius={40} />
            </div>
            </div>
          <div className="flex gap-[32px] w-full pt-[20px] ">
            <div className="bg-[#d0cfcf] w-full h-[226px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            <div className="bg-[#d0cfcf] w-full h-[226px] hidden md:flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
          </div>
        </div>
        </div>
     
    </>
  )
}

export default GalleryLandingSkeleton