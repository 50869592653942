// Sportsnewsmain.jsx
import React from "react";
import SportsNewsCard from "../Cards/SportsNewsCard";
import SportsNewsLandingPageSkeleton from "../Skeleton/SportsNewsLandingPageSkeleton";

function Sportsnewsmain({ data }) {
  const sortedNews = data.slice().sort((a, b) => a.id - b.id).reverse();
  const limitedNews = sortedNews.slice(0, 1);
  function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  }

  if (!data.length) {
    return <div>
  <SportsNewsLandingPageSkeleton/>
    </div>;
  }

  return (
    <div className="lg:w-[464px] w-full h-full font-Rubik border-[1px] border-solid rounded-[12px]">
      {limitedNews.map((news) => (
        <SportsNewsCard
          key={news.id}
          youtubeThumbnail={news.youtube_thumbnail}
          image={news.image}
          youtubeLink={news.youtube_link}
          title={news.title}
          heading={news.heading}
          publishedDate={news.published_date}
          content={news.subnews && news.subnews[0] && news.subnews[0].subnewscontent && news.subnews[0].subnewscontent.length > 0
            ? truncateText(news.subnews[0].subnewscontent[0].content, 200)
            : ''}
          handleNewsClick={`/sportsnewsviewpage/${news.id}-${news.title ? news.title.replace(/\s+/g, '') : '-'}`}
        />
      ))}
    </div>
  );
}

export default Sportsnewsmain;
