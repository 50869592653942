// PreviousBlogs.js
import React, { useRef, useState } from "react";
import { IoPlayCircle } from "react-icons/io5";
import { calender_icon } from "../../Constants/Data";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import SocialShareButton from "../../Buttons/SocialShareButton";

function PreviousBlogs({ sportsData }) {
  const [showShareButtons, setShowShareButtons] = useState(null);
  const componentRef = useRef(null);
  const toggleShareButtons = (id) => {
    setShowShareButtons((prevId) => (prevId === id ? null : id)); // Toggle visibility for the clicked card
  };

  const closeShareButtons = () => {
    setShowShareButtons(null); 
  };

  const sortedblog = sportsData
    ? sportsData
        .slice()
        .sort((a, b) => b.id - a.id)
        .reverse()
    : [];
  const limitedblogs = sortedblog.slice(0, 3);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };
  



  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/blog/";
  const pageUrl = (id, title) => `${window.location.origin}/blogindividualpage/${id}-${title.replace(/\s+/g, '')}`;

  return (
    <>
      <div className="flex flex-col justify-center  gap-[16px] font-Rubik   ">
        <div className="flex text-primary italic font-medium gap-[12px] md:items-center items-start sports-news-heading ">
          <IoPlayCircle className="text-[26px]" />
          <h3 className="text-[24px]  leading-[24px]">
            EXPLORE OUR PREVIOUS BLOGS
          </h3>
        </div>

        <div className="flex flex-col gap-[28px]   ">
          {limitedblogs.map((blog) => (
            <div key={blog.id}>
              <div className="flex flex-col gap-[16px] rounded-[12px]  px-[24px] py-[16px] border-[1px] border-solid border-[#0000001b]">
                <div className="flex justify-between items-center lg:w-[520px]">
                  <div className="flex items-center gap-[12px] text-[#B82222] text-[18px] font-medium uppercase italic">
                    <img
                      src={
                        blog.profile_image
                          ? `${apiUrl}${imagePath}${blog.profile_image}`
                          : ""
                      }
                      alt=""
                      className="w-[50px] h-[50px] object-cover border-[2px] border-[#D6D6D6] bg-teal-50 rounded-full"
                    />
                    <h3>{blog.author_name}</h3>
                  </div>
                  <div className="flex relative z-10 items-start h-full justify-end">
                  <div className="bg-[#B82222] w-[40px]  h-[40px] rounded-full flex items-center text-[24px] text-white justify-center" onClick={() => toggleShareButtons(blog.id)}>
                    <GoShareAndroid />
                  </div>

                  {showShareButtons === blog.id && (
          <div className={`absolute  -right-1 -top-2 social-share ${showShareButtons ? 'visible' : 'hidden'}`} ref={componentRef} >
            <SocialShareButton close={closeShareButtons}
           pageUrl={pageUrl(blog.id, blog.blog_details.blog_title || 'untitled')}
          
            />
          </div>
        )}
        </div>
                </div>
                <Link to={`/blogindividualpage/${blog.id}-${blog.blog_details.blog_title && blog.blog_details.blog_title ? blog.blog_details.blog_title.replace(/\s+/g, '') : '-'}`} >
                <div className="flex flex-col gap-[4px]">
                  <h2 className="uppercase text-[18px] font-medium italic leading-[150%] lg:w-[472px]">
                    {" "}
                    
                       {blog.blog_details.blog_title}
                      
                  </h2>

                  <div className="flex gap-[10px] items-center text-[14px]">
                    <img src={calender_icon} alt="" />
                    <h3>{formatDate(blog.date)}</h3>
                  </div>
                </div>
                </Link>
              </div>
             
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default PreviousBlogs;
