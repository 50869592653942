import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function CricketIndividualSkeleton() {
  return (
    <>
      <div className="md:pb-[90px] pb-[32px]">
        <div className="footer_bottom_box h-[144px]"></div>

        <div className="bg-[#EFEFEF]  ">
          <div className="container mx-auto ">
            <div className="flex flex-col justify-center h-[132px]">
              <span>
                {" "}
                <Skeleton width={100} height={20} borderRadius={20} />
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CricketIndividualSkeleton;
