import React from "react";

function Whitebutton({ onClick, disabled, children }) {
  return (
    <div className=" md:peer-hover:bg-[#414040] hover:rounded-[20px]   flex items-center justify-start ">
      <button
        className=" bg-[#FFFFFF]  hover:bg-[#757575]  hover:text-white focus:bg-[#9E120E] focus:text-white flex md:flex-row  flex-row-reverse items-center justify-center md:w-[172px] w-full px-[20px] py-[4px]  h-[44px] rounded-[20px] text-[#9E120E] font-Rubik italic text-[14px]  leading-[16px]"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  );
}

export default Whitebutton;
