import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function SportsNewsLandingPageSkeleton() {
  return (
    <>
      <div className=" lg:w-[464px] w-full rounded-[12px] border-[1px]   h-[600px]">
        <div className="h-[300px] animate-pulse rounded-t-[12px] bg-[#E6E6E6] "></div>
        <div className="px-[20px] py-[16px]">
          <div className="flex justify-between items-center">
            <Skeleton width={70} height={25} />
            <Skeleton width={40} borderRadius={26} height={40} />
          </div>

          <div className="pt-[20px] flex items-center gap-[10px]">
            <Skeleton width={25} height={25} />
            <Skeleton width={83} borderRadius={26} height={15} />
          </div>

          <div className="pt-[20px]  md:w-[424px] gap-[10px]">
            <Skeleton count={2} borderRadius={26} height={26} />
          </div>

          <div className="pt-[20px]   ">
            <Skeleton count={3} borderRadius={26} height={10} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SportsNewsLandingPageSkeleton;
