import React, { useState, useEffect } from "react";
import Whitebutton from "../../Buttons/Whitebutton";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Watchvideobutton from "../../Buttons/Watchvideobutton";
import { white_calender } from "../../Constants/Data";
import ReactPlayer from "react-player";
function Sportsnewsherobanner({ data }) {
  const [showVideoModal, setShowVideoModal] = useState(false);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    } else {
      return text;
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openVideoModal = () => {
    setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  const reversedData = data
    .slice()
    .sort((a, b) => a.id - b.id)
    .reverse();

  useEffect(() => {
    if (showVideoModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showVideoModal]);

  // const apiUrl = "http://127.0.0.1:8000";
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/news/";
  const thumbnailPath = "/storage/youtube_thumbnail/";
  const youtubeUrl =
    reversedData.length > 0 ? reversedData[0].youtube_link : "";
  return (
    <div className="sports-hero-banner">
      {reversedData.length > 0 && reversedData[0] && (
        <div
          className="flex px-[16px] md:px-[24px] justify-start pb-[64px] items-end text-white font-Rubik lg:h-[560px] h-[700px] w-full object-cover"
          style={{
            backgroundImage: `linear-gradient(-270deg, rgba(101,13,10,0.8), rgba(0,0,0,0.86) ),
          url(${
            reversedData[0].heading === "image" && reversedData[0].image
              ? `${apiUrl}${imagePath}${reversedData[0].image}`
              : reversedData[0].heading === "video" &&
                reversedData[0].youtube_thumbnail
              ? `${apiUrl}${thumbnailPath}${reversedData[0].youtube_thumbnail}`
              : ""
          })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto md:px-[24px] px-[16px]">
            <div className="lg:w-[891px] flex flex-col gap-[16px]">
              <div className=" bg-[#e6e6e633] px-[12px] py-[4px]  md:relative flex md:flex-row  flex-row-reverse items-center justify-center md:w-[141px]   h-[32px] rounded-[8px] text-white font-Rubik italic font-medium border-[1px] border-solid border-[#ffffff41] text-[16px] leading-[16px]">
                <h3>LATEST NEWS</h3>
              </div>

              <div className="flex gap-[10px] items-center">
                <img src={white_calender} alt="Calendar Icon" />
                {reversedData[0].published_date}
              </div>

              <div>
                <h2 className="md:text-[36px] text-[24px] font-medium italic  uppercase leading-[150%]">
                  {truncateText(reversedData[0].title, 64)}
                </h2>
                {/* <h3 className="font-light italic text-[18px] text-[#D6D6D6]">
                  {reversedData[0].content && reversedData[0].content.length > 0 ? truncateText(reversedData[0].content[0], 200) : ""}
                </h3> */}
                <h3 className="font-light italic  text-[18px] text-[#D6D6D6]">
                  {reversedData[0].subnews &&
                  reversedData[0].subnews.length > 0 &&
                  reversedData[0].subnews[0].subnewscontent &&
                  reversedData[0].subnews[0].subnewscontent.length > 0
                    ? truncateText(
                        reversedData[0].subnews[0].subnewscontent[0].content,
                        200
                      )
                    : ""}
                </h3>
              </div>

              <div className="flex flex-col gap-[10px]">
                <div className="flex md:flex-row flex-col gap-[12px]">
                  <Link
                    to={`/sportsnewsviewpage/${reversedData[0].id}-${
                      reversedData[0].title
                        ? reversedData[0].title.replace(/\s+/g, "")
                        : "-"
                    }`}
                    style={{ textDecoration: "none" }}
                    onClick={scrollToTop}
                  >
                    <Whitebutton>READ MORE</Whitebutton>
                  </Link>

                  {reversedData[0].youtube_link && (
                    <div>
                      <Watchvideobutton onClick={openVideoModal}>
                        WATCH VIDEO
                      </Watchvideobutton>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {showVideoModal && (
        <div className="fixed inset-0 z-50  bg-[#050505] flex items-center justify-center h-screen  overflow-x-scroll scrollbar">
          <div className="container mx-auto modal-content w-full h-full flex flex-col md:gap-[64px] gap-[48px] justify-center items-end  relative ">
            <div
              className=" flex gap-[12px]   cursor-pointer items-center justify-center w-[115px] h-[51px] border-[1px] border-[#ffffff0e] border-solid  text-white"
              onClick={closeVideoModal}
            >
              <h3>Close</h3>
              <IoClose className="text-[24px]" />
            </div>

            {/* <iframe
              className="md:h-[672px] h-[279px] px-[16px] md:px-[24px]"
              src={`${embedUrl}?modestbranding=1&controls=1`}
              title="YouTube Video"
              frameBorder="0"
              width="100%"
              allowFullScreen
            ></iframe> */}
            <ReactPlayer
              className=" h-[279px] px-[16px] md:px-[24px]"
              url={youtubeUrl}
              controls
              width="100%"
              height={600}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Sportsnewsherobanner;
