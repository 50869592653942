import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import SportnewsRecommended from "../../Components/SportsNews/SportnewsRecommended";
import NewsIndividualPageSkeleton from "../../Components/Skeleton/NewsIndividualPageSkeleton";
import RecomenededSkeleton from "../../Components/Skeleton/RecomenededSkeleton";
import { play_icon } from "../../Constants/Data";
import { IoClose } from "react-icons/io5";
import SocialShareButton from "../../Buttons/SocialShareButton";

function SportsNewsViewPage() {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL_NEWS}/${id}`;
      console.log(`API URL: ${apiUrl}`);

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        setNewsItem(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      }

      const minLoadingTime = new Promise((resolve) =>
        setTimeout(resolve, 3000)
      );

      Promise.all([minLoadingTime]).then(() => {
        setLoading(false);
      });
    };

    fetchData();
  }, [id]);

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/news/";
  const thumbnailPath = "/storage/youtube_thumbnail/";

  if (!newsItem || newsItem.status === "unpublish") {
    return<> <NewsIndividualPageSkeleton /> <RecomenededSkeleton /> </>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const getEmbedUrl = (videoUrl) => {
    if (!videoUrl) return "";
    const url = new URL(videoUrl);
    const videoId = url.searchParams.get("v") || url.pathname.split("/").pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const embedUrl = newsItem ? getEmbedUrl(newsItem.youtube_link) : "";

  return (
    <div>
      <div className="bg-header-gradient h-[144px] "></div>
      
        <div
          ref={componentRef}
          className="container mx-auto pt-[40px] pb-[96px] md:px-[24px] px-[16px] font-Rubik"
        >
          {error ? (
            <p>Error: {error}</p>
          ) : newsItem ? (
            <div className="flex flex-col gap-[16px]">
              {newsItem.heading === "video" && newsItem.youtube_link ? (
                <div
                  className="relative flex items-center w-full justify-center h-[600px] px-4 md:px-0"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${apiUrl}${thumbnailPath}${newsItem.youtube_thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={openModal}
                >
                  <img src={play_icon} alt="Play" className="w-[54px]" />
                </div>
              ) : (
                <img
                  src={
                    newsItem.image
                      ? `${apiUrl}${imagePath}${newsItem.image}`
                      : ""
                  }
                  alt="News Thumbnail"
                  className="h-[600px] w-full object-cover"
                />
              )}
              <div className="flex flex-col gap-[16px]">
                <div className="flex md:flex-row flex-col justify-between md:gap-0 gap-[16px] items-center">
                  <h3 className="italic text-[14px]">
                    Published: {formatDate(newsItem.published_date)}
                  </h3>
                  <div className="share-buttons relative justify-center flex gap-[8px]">
                    <div className=" flex ">
                      <SocialShareButton
                        pageUrl={`${
                          window.location.origin
                        }/sportsnewsviewpage/${newsItem.id}-${
                          newsItem.title
                            ? newsItem.title.replace(/\s+/g, "")
                            : "-"
                        }`}
                        pageTitle={newsItem.title}
                        pageImage={
                          newsItem.image
                            ? `${apiUrl}${imagePath}${newsItem.image}`
                            : ""
                        }
                        className="hidden"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h2 className="text-[#B82222] lg:text-[44px] md:text-[32px] text-[28px] leading-[150%] font-medium italic uppercase">
                    {newsItem.title}
                  </h2>
                </div>
                <div className="flex flex-col text-[16px] gap-[32px]">
                  <h2 className="font-medium italic leading-[150%] uppercase">
                    {newsItem.content_title}
                  </h2>
                  <div className="font-light leading-[24px]">
                    {newsItem.subnews && newsItem.subnews.length > 0 && (
                      <div className="flex flex-col md:gap-[48px] gap-[32px]">
                        {newsItem.subnews.map((sub, subIndex) => (
                          <div
                            key={subIndex}
                            className="flex flex-col text-[16px] gap-[32px]"
                          >
                            <h3 className="uppercase italic font-medium">
                              {sub.content_title}
                            </h3>
                            <div className="font-light leading-[24px] text-justify">
                              {sub.subnewscontent.map(
                                (subContent, contentIndex) => (
                                  <p key={contentIndex} className="py-[10px]">
                                    {subContent.content}
                                  </p>
                                )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {modalOpen && (
                <div className="fixed inset-0 z-50  bg-[#050505] flex items-center justify-center">
                  <div className="container mx-auto modal-content w-full flex flex-col md:gap-[64px] gap-[48px] justify-end items-end relative">
                    <div
                      className=" flex gap-[12px]   cursor-pointer items-center justify-center w-[115px] h-[51px] border-[1px] border-[#ffffff0e] border-solid  text-white"
                      onClick={closeModal}
                    >
                      <h3>Close</h3>
                      <IoClose className="text-[24px]" />
                    </div>

                    {newsItem.youtube_link && (
                      <iframe
                        className="md:h-[672px] h-[279px] px-[16px] md:px-[24px]"
                        src={`${embedUrl}?modestbranding=1&controls=1`}
                        title="YouTube Video"
                        frameBorder="0"
                        width="100%"
                        allowFullScreen
                      ></iframe>
                    )}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      
      {loading ? (
        <RecomenededSkeleton />
      ) : (
        <div>
          <SportnewsRecommended idIndivitual={newsItem.id} />
          <div className="text-black"></div>
        </div>
      )}
    </div>
  );
}

export default SportsNewsViewPage;

