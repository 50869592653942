import React, { useState } from "react";
import { about_why_choose_us_img, icon_icon } from "../Constants/Data";

function AboutWhychooseus() {
  const [selectedSport, setSelectedSport] = useState("Cricket");

  const sportsData = {
    Cricket : [
      {
        title: "Professional Coaching",
        description: "Our club boasts experienced coaches who provide personalized training programs tailored to individual needs and goals.",
        image: icon_icon
      },
      {
        title: "Professional Training and Instruction",
        description: "Participating in sports teaches valuable life skills such as teamwork, leadership, discipline, and resilience.",
        image: icon_icon
      },
      {
        title: "Variety of Sports and Activities",
        description: "With a diverse range of sports and activities available, there's something for everyone at our club.",
        image: icon_icon
      }
    ],
    Football: [
      {
        title: "Football Coaching",
        description: "Our club boasts experienced coaches who provide personalized training programs tailored to individual needs and goals.",
        image: icon_icon
      },
      {
        title: "Team Building",
        description: "Participating in sports teaches valuable life skills such as teamwork, leadership, discipline, and resilience.",
        image: icon_icon
      },
      {
        title: "Skill Development",
        description: "Our club boasts experienced coaches who provide personalized training programs tailored to individual needs and goals.",
        image: icon_icon
      }
    ],
    Volleyball: [
      {
        title: "Volleyball Training",
        description: "Our club boasts experienced coaches who provide personalized training programs tailored to individual needs and goals.",
        image: icon_icon

      },
      {
        title: "Team Strategy",
        description: "Participating in sports teaches valuable life skills such as teamwork, leadership, discipline, and resilience.",
        image: icon_icon
      },
      {
        title: "Fitness and Conditioning",
        description: "Our club boasts experienced coaches who provide personalized training programs tailored to individual needs and goals.",
        image: icon_icon
      }
    ]
  };

  const handleSportClick = (sport) => {
    setSelectedSport(sport);
  };

  return (
    <>
      <div className="py-[108px] lg:h-[995px] md:px-[24px] px-[16px] font-Rubik">
        <div className="container mx-auto">
          <div className="flex  gap-[32px]">
            <div className=" lg:block hidden">
              <img
                src={about_why_choose_us_img}
                alt=""
                className="h-[679px]    object-cover"
              />
            </div>
            <div className="why-choose-us-contents overflow-hidden flex flex-col gap-[32px] justify-center ">
              <div className="flex flex-col gap-[16px]">
                <div className="why-choose-us-headings italic lg:w-[738px] w-full flex flex-col md:gap-[12px] gap-[8px]">
                  <h4 className="md:text-[24px] text-[20px] md:leading-[24px] text-[#B72828]">
                    WHY CHOOSE US
                  </h4>
                  <h2 className="md:text-[48px] text-[24px]  font-medium text-[#A50808] leading-[120%]">
                    {" "}
                    DISCOVER THE BENEFITS OF CHOOSING US
                  </h2>
                </div>
              
                  <div className="about-filter   flex gap-[12px] uppercase text-white text-[16px] italic">
                    <button
                      className={selectedSport === "Cricket" ? "active-filter bg- rounded-[20px]" : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"}
                      onClick={() => handleSportClick("Cricket")}
                    >
                      <div className="px-[20px] h-[32px] rounded-[20px] flex justify-center items-center">
                        <h3>CRICKET</h3>
                      </div>
                    </button>
                    <button
                      className={selectedSport === "Football" ? "active-filter bg- rounded-[20px]" : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"}
                      onClick={() => handleSportClick("Football")}
                    >
                      <div className="px-[20px] h-[32px] rounded-[20px] flex justify-center items-center">
                        <h3>FOOTBALL</h3>
                      </div>
                    </button>
                    <button
                      className={selectedSport === "Volleyball" ? "active-filter bg- rounded-[20px]" : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"}
                      onClick={() => handleSportClick("Volleyball")}
                    >
                      <div className="px-[20px] h-[32px] rounded-[20px] flex justify-center items-center">
                        <h3>VOLLEYBALL</h3>
                      </div>
                    </button>
                  </div>
               
              </div>
              <div className="flex flex-col gap-[32px]">
                {sportsData[selectedSport].map((content, index) => (
                  <div key={index} className="flex md:flex-row flex-col gap-[24px] md:items-center items-start">
                    
                    <img src={content.image} alt=" "  />
                 
                    <div className="flex flex-col gap-[8px]">
                      <h3 className="text-[24px] leading-[28px] font-medium">{content.title}</h3>
                      <p className="text-[16px] leading-[22px] ">
                        {content.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutWhychooseus;
