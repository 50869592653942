import React from "react";
import { playground_icon } from "../../Constants/Data";
import { Link } from "react-router-dom";

function MatchCard({
  title,
  match_day,
  match_date,
  match_time,
  status,
  teams,
  result,
  playground,
  statusClassName,
  viewMatchSummaryClassName,
  handleClick,
}) {
  const isHidden =
    status === "Upcoming" || status === "Cancelled" || status === "Live";

  const formatTime = (timeString, timeZone) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      timeZone,
      hour12: false,
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const istTime = formatTime(match_time, "Asia/Kolkata");

  const gmtTime = formatTime(match_time, "GMT");

  let summaryText;
  switch (status) {
    case "Recent":
      summaryText = "VIEW MATCH SUMMARY";
      break;
    case "Canceled":
      summaryText = "CANCELED";
      break;
    case "Upcoming":
      summaryText = `MATCH BEGINS AT ${istTime} IST (${gmtTime} GMT)`;
      break;

    case "Live":
      summaryText = "VIEW LIVE SCORE";
      break;
    default:
      summaryText = "VIEW MATCH SUMMARY";
      break;
  }

  const getShortenedName = (schoolName) => {
    const words = schoolName.split(" ");

    if (words.length > 1) {
      return words.map((word) => word[0].toUpperCase()).join("");
    } else {
      return schoolName;
    }
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/team1_logo/";
  const imagePath1 = "/storage/team2_logo/";

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month}  ${year}`;
  };

  const cardContent = (
    <div className={`w-full  ${status}`}>
      <div className="w-full lg:h-[260px] h-[310px] border-[1px] border-green-border-gradient   flex flex-col gap-[32px] bg-custom-gradient pt-[16px] pb-[12px] px-[16px] rounded-t-[12px] font-Rubik">
        <div className="flex lg:flex-row flex-col-reverse gap-[12px] lg:gap-0 items-center lg:items-start w-full justify-between">
          <div className="flex items-center lg:items-start w-full flex-col gap-[4px]">
            <h3 className="text-[#4D4D4D] font-medium text-[16px] tracking-[0.2px] uppercase">
              {title}
            </h3>

            {status !== "Live" && (
              <div className="flex gap-[14px] text-[#2F2F2F] text-[14px] tracking-[0.2px]">
                <div className="flex gap-[4px]">
                  <h2>{match_day},</h2>
                  <h2>{formatDate(match_date)}</h2>
                </div>
                <h2 className="text-[#3A225D]">{match_time}</h2>
              </div>
            )}
          </div>

          <h3
            className={`flex items-center gap-[8px] uppercase italic text-[16px] rounded-[8px] h-[32px] px-[20px] py-[8px] ${statusClassName}`}
          >
            {status === "Live" && (
              <div class="blobs-container">
                <div class="blob red"></div>
              </div>
            )}
            {status}
          </h3>
        </div>
        <div className="flex flex-col gap-[12px]">
          <div className="flex flex-col gap-[4px] items-center w-full">
            {!isHidden && (
              <h2 className="text-[#232020] text-center capitalize truncate w-[320px] font-medium">
                {result}
              </h2>
            )}
            <div className="bg-[#FFFFFF] h-[97px] flex flex-col justify-center px-[16px] w-full py-[16px] rounded-[12px] border-[#0000000f] border-[1px] border-solid">
              <div className="flex w-full uppercase text-[#4D4D4D] gap-[8px] text-[18px] items-center justify-center">
                <div className="flex gap-[10px] items-center">
                  <div className="justify-end flex flex-col items-end">
                    <h2
                      className={`school-name ${
                        isHidden
                          ? "text-[24px] font-medium"
                          : "text-[18px] font-medium"
                      }`}
                    >
                      {getShortenedName(teams[0].school_name)}
                    </h2>
                    {!isHidden && (
                      <div className="flex lg:flex-row flex-col font-Urbanist md:items-center items-end gap-[4px]">
                        <p className="text-[#111111] flex items-center lg:text-[24px] text-[20px] font-bold">
                          <span className="score">{teams[0].score}</span>/
                          <span className="wickets">{teams[0].wicket}</span>
                        </p>
                        <span className="text-[16px] font-normal text-[#7E7E7E] overs">
                          ({teams[0].overs})
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="bg-white h-[40px] w-[40px] drop-shadow-lg rounded-full flex items-center justify-center border-solid border-[1px] border-[#DADADA]">
                    <img
                      src={
                        teams[0].school_logo
                          ? `${apiUrl}${imagePath}${teams[0].school_logo}`
                          : ""
                      }
                      alt=""
                      className=" rounded-full bg-white h-[38px] w-[38px]  object-cover"
                    />
                  </div>
                </div>
                <span className="text-[#A82929] italic">VS</span>
                <div className="flex gap-[10px] items-center">
                  <div className="bg-white h-[40px]  drop-shadow-lg w-[40px]  rounded-full flex items-center justify-center border-solid border-[1px] border-[#DADADA]">
                    <img
                      src={
                        teams[1].school_logo
                          ? `${apiUrl}${imagePath1}${teams[1].school_logo}`
                          : ""
                      }
                      alt=""
                      className=" rounded-full h-[38px] w-[38px]  object-cover"
                    />
                  </div>
                  <div>
                    <h2
                      className={`school-name truncate  w-[90px] ${
                        isHidden
                          ? "text-[24px] font-medium"
                          : "text-[18px] font-medium"
                      }`}
                    >
                      {getShortenedName(teams[1].school_name)}
                    </h2>
                    {!isHidden && (
                      <div className="flex lg:flex-row flex-col gap-[4px] font-Urbanist items-start md:items-center">
                        <p className="text-[#111111] flex items-center lg:text-[24px] text-[20px] font-bold">
                          <span className="score">{teams[1].score}</span>/
                          <span className="wickets">{teams[1].wicket}</span>
                        </p>
                        <span className="text-[16px] font-normal text-[#7E7E7E] overs">
                          ({teams[1].overs})
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-[6px] text-[14px] justify-center tracking-[0.2px]">
            <img src={playground_icon} alt="" className="h-[16px] w-[21px]" />
            <p>{playground}</p>
          </div>
        </div>
      </div>
      <div className={viewMatchSummaryClassName}>
        <h4>{summaryText}</h4>
      </div>
    </div>
  );

  return (
    <>
      {status === "Recent" || status === "Upcoming" || status === "Live" ? (
        <Link
          className="w-full"
          to={handleClick}
          style={{ textDecoration: "none" }}
        >
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </>
  );
}

export default MatchCard;
