import React from 'react'
import { play_icon } from '../Constants/Data'

function Watchvideobutton ({ onClick, disabled, children })  {
  return (
    <div className=" md:peer-hover:bg-[#414040] hover:rounded-[20px]   flex items-center justify-start ">
    <button
      className="border-[1px] border-solid  hover:bg-white  hover:text-black  flex gap-[4px] md:flex-row  flex-row-reverse items-center justify-center md:w-[144px] w-full px-[12px] py-[8px]  h-[44px] rounded-[20px] text-white font-Rubik italic text-[14px]  leading-[16px]"
      onClick={onClick}
      disabled={disabled}
    >

      <img src={play_icon} alt="" className='h-[24px] w-[24px]' />
      {children}
    </button>
  </div>
  )
}

export default Watchvideobutton