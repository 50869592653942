import React from 'react'

function Homebanner() {
  return (
    <div
    className="hero-banner flex flex-col md:h-[960px]  h-[700px]   md:items-start md:justify-center   w-full px-4 md:px-[24px]   "
   
  ></div>
  )
}

export default Homebanner