import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function ViewAllSkeleton() {
    const [numSkeletons, setNumSkeletons] = useState(9);

    useEffect(() => {
      const updateSkeletonCount = () => {
        if (window.innerWidth >= 1280) {
          setNumSkeletons(9); 
        } else if (window.innerWidth >= 768) {
          setNumSkeletons(6); 
        } else {
          setNumSkeletons(6); 
        }
      };
      updateSkeletonCount();
  
      window.addEventListener('resize', updateSkeletonCount);
      return () => window.removeEventListener('resize', updateSkeletonCount);
    }, []);
  
     
    const skeletonCards = Array.from({ length: numSkeletons });
  return (
    <>
      <div className="py-[108px] md:px-[24px] px-[16px] ">
        <div className="container mx-auto">
          <span className="flex gap-[12px] pb-[36px]">
            <Skeleton width={26} borderRadius={26} height={26} />
            <Skeleton width={167} borderRadius={26} height={26} />
          </span>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          {skeletonCards.map((_, index) => (
            <span className="p-[12px]">
              <div className="bg-[#E0E0E0] h-[244px] rounded-[12px] animate-pulse"></div>
              <div className="lg:w-[381px] w-[340px] pt-[18px] ">
                <Skeleton height={21} borderRadius={20} />
              </div>

              <div className="w-[230px] pt-[8px] ">
                <Skeleton height={21} borderRadius={20} />
              </div>
            </span>
              ))}

             
          </div>
          <span className="flex pt-[24px] justify-center w-full">
                <div className="md:w-[184px] w-full">
                <Skeleton  height={32} borderRadius={20} />
                </div>
                <div className="md:flex hidden" >
                <Skeleton width={32}  height={32} borderRadius={20} />
                </div>
              </span> 
        </div>
      </div>
    </>
  );
}

export default ViewAllSkeleton;
