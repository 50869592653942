import React, { useEffect, useState } from "react";
import HighlightsCard from "../Cards/HighlightsCard";
import HighlightsModal from "./HighlightsModel";
import { white_calender } from "../../Constants/Data";



function truncateString(str, maxWords) {
  const words = str.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "";
  }
  return str;
}

function HighlightsMain({
  highlightsData,
  handleHighlightClick,
  modalOpen,
  selectedHighlightId,
  handleClose,
  nextItem,
  prevItem,
  updateCountNumber,
  currentItemIndex,
}) {
  const [selectedHighlight, setSelectedHighlight] = useState(null);

  useEffect(() => {
    if (selectedHighlightId) {
     
      fetchHighlightDetails(selectedHighlightId);
    }
  }, [selectedHighlightId]);

  const fetchHighlightDetails = (highlightId) => {
    fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}${highlightId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedHighlight(data);
      })
      .catch((error) => {
        console.error(
          "There was a problem with fetching highlight details:",
          error
        );
      });
  };
  

  return (
    <>
      <div className="highlights-scroll flex font-Rubik w-full  gap-[20px] overflow-x-auto">
        {highlightsData.map((highlight, index) => (
          <HighlightsCard
          key={highlight.id}
          image={highlight.image}
          video_link={highlight.video_link}
          title={truncateString(highlight.title, 13)}
          date={highlight.date}
          cardWidth="426px"
          boxWidth="426px"
          color="white"
          bgColor="#791817"
          dateColor="white"
          calenderIcon={white_calender}
        
          handleHighlightClick={() => handleHighlightClick(highlight.id)}
          />
        ))}

        {modalOpen && selectedHighlight && (
          <HighlightsModal
            modalOpen={modalOpen}
            handleClose={handleClose}
            selectedHighlight={selectedHighlight}
            nextItem={nextItem}
            prevItem={prevItem}
            updateCountNumber={updateCountNumber}
            highlightsData={highlightsData}
            currentItemIndex={currentItemIndex}
          />
        )}
      </div>
    </>
  );
}

export default HighlightsMain;