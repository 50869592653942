import React from 'react'
import { about_hero_bg } from '../Constants/Data'

function Aboutherobanner() {
  return (
    <div
    className=" flex px-[16px] md:px-[24px]  justify-center  items-center  text-center text-white font-Rubik h-[580px] w-full object-cover     "
    style={{
      backgroundImage: `linear-gradient(-270deg, rgba(101,13,10,0.8), rgba(0,0,0,0.86) ),url(${about_hero_bg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <div >
        <h1 className='about-heading md:text-[68px] text-[32px] font-medium italic leading-[150%]'>BUILDING A LEGACY</h1>
        <h3 className='about-description md:text-[28px] text-[14px] leading-[120%] font-light italic'> DISCOVER THE BENEFITS OF CHOOSING US</h3>
    </div>
  </div>
  )
}

export default Aboutherobanner