import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
function CricketViewAllSkeleton() {
    const [cardCount, setCardCount] = useState(1);
    useEffect(() => {
        const updateCardCount = () => {
          const width = window.innerWidth;
          if (width >= 1280) {
            setCardCount(6);
          } else if (width >= 768) {
            setCardCount(6);
          } else {
            setCardCount(4);
          }
        };
    
        updateCardCount();
        window.addEventListener("resize", updateCardCount);
    
        return () => window.removeEventListener("resize", updateCardCount);
      }, []);
  return (
    <>
      <div className="bg-header-gradient h-[144px] "></div>
     <div className="md:px-[24px] px-[16px] py-[96px]">
        <div className="container mx-auto">
            <div className='md:w-[500px] mb-[20px]'>
        <Skeleton count={2} height={30} borderRadius={20} />
        </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4  ">
          {[...Array(cardCount)].map((_, index) => (
              <div
                key={index}
                className="h-[302px]   bg-custom-gradient animate-pulse border-[1px] rounded-[12px] "
              >
                <div className="flex gap-[20px] px-[16px] pt-[24px]">
                  <div className="w-full">
                    <Skeleton count={2} height={20} borderRadius={20} />
                  </div>
                  <Skeleton width={120} height={32} />
                </div>

                <div className="pt-[32px] flex flex-col justify-center items-center  ">
                  <span className="pb-[5px]">
                    <Skeleton width={190} height={20} borderRadius={20} />
                  </span>
                  <div className="w-full px-[24px]">
                    <div className="h-[72px] border-[1px] bg-white w-full rounded-[12px]">
                      <div className="flex  justify-center px-[24px] gap-[20px] items-center py-[16px] ">
                        <div className="flex items-center gap-[10px]">
                          <Skeleton width={42} height={29} />
                          <Skeleton width={40} height={40} borderRadius={30} />
                        </div>
                        <h4 className="text-[#A82929] italic text-[20px] font-Rubik font-medium">
                          VS
                        </h4>
                        <div className="flex items-center gap-[10px]">
                          <Skeleton width={40} height={40} borderRadius={30} />
                          <Skeleton width={42} height={29} />
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-[6px] pb-[10px] justify-center">
                      <Skeleton width={30} height={29} />
                      <Skeleton width={170} height={29} borderRadius={30} />
                    </div>
                  </div>
                  <div className="h-[52px] rounded-b-[12px] w-full bg bg-[#D4D4D4] "></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </>
  )
}

export default CricketViewAllSkeleton