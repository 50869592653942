import React, { useEffect, useState } from "react";
import { RiWhatsappFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { MdFacebook } from "react-icons/md";
import { FiLink } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { FacebookShareButton } from "react-share";
import {Helmet} from "react-helmet";
function SocialShareButton({
  onClick,
  disabled,
  children,
  close,
  pageUrl,
  pageTitle,
  pageImage, // Corrected prop declaration
  className,
   bgColor = "#EAEAEA"
}) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    console.log(pageUrl);
  }, [pageUrl]);

  const copyLink = () => {
    navigator.clipboard.writeText(pageUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    });
  };

  const shareWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${pageTitle}\n${pageUrl}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      pageUrl
    )}&text=${encodeURIComponent(pageTitle)}&media=${encodeURIComponent(
      pageImage 
    )}`;
    window.open(twitterUrl, "_blank");
    <meta property="og:title" content="<%=info.Title%>" />
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <>

      <div>
        <button
          className="px-[16px] py-[12px] flex gap-[32px] text-white text-[20px] rounded-[40px] bg-[#EAEAEA]"
          onClick={onClick}
          disabled={disabled}
          style={{ backgroundColor: bgColor }} 
        >
          <div className="flex gap-[16px]">
            <button
              className="bg-[#B72828] p-[8px] rounded-full"
              onClick={shareWhatsApp}
            >
              <RiWhatsappFill />
            </button>

            <button
              className="flex items-center bg-[#B72828] p-[8px] rounded-full"
              onClick={shareTwitter}
            >
             <meta property="og:title" content="<%=info.Title%>" />
              <FaXTwitter />
            </button>

            {isValidUrl(pageUrl) ? (
              <FacebookShareButton
                url={pageUrl}
                quote={pageTitle}
                hashtag={"#Johnians"}
                className="Demo__some-network__share-button"
              >
                <div className="flex items-center bg-[#B72828] p-[8px] rounded-full">
                  <MdFacebook />
                </div>
              </FacebookShareButton>
            ) : (
              <div className="flex items-center bg-[#B72828] p-[8px] rounded-full">
                <MdFacebook />
              </div>
            )}
            <button
              className="flex items-center bg-[#B72828] p-[8px] rounded-full"
              onClick={copyLink}
            >
              <FiLink />
            </button>
            {copied && (
              <span
                onClick={close}
                className="z-30 absolute text-[14px] text-[#b1aaaa] mt-14 ml-16"
              >
                Link Copied!
              </span>
            )}
          </div>
          <button
            className={`flex items-center bg-[#606060] p-[8px] rounded-full ${className}`}
            onClick={close}
          >
            <IoClose />
          </button>
          {children}
        </button>
      </div>
    </>
  );
}

export default SocialShareButton;
