import React from "react";
import { motion } from "framer-motion";
import { GoArrowUp } from "react-icons/go";
import { Link } from "react-router-dom";

const Mobilemenu = ({
  closeMobileMenu,
  isMobileMenuOpen,
  toggleMobileMenu,
}) => {
  return (
    <>
      <div className="w-full z-20  h-screen overflow-hidden flex text-[white]   font-Rubik inset-0 absolute">
        <div className="justify-start  hidden lg:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.5, stiffness: 70 }}
          />
        </div>

        <div className=" hidden  xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1.5, stiffness: 70 }}
          />
        </div>

        <div className=" hidden  xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1.3, stiffness: 70 }}
          />
        </div>

        <div className=" hidden  xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1, stiffness: 70 }}
          />
        </div>

        <div className=" hidden  xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.8, stiffness: 70 }}
          />
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.7, stiffness: 70 }}
          />
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.5, stiffness: 70 }}
          />
        </div>

        <div className="hidden   xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.4, stiffness: 70 }}
          />
        </div>

        <div className=" hidden   xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.3, stiffness: 70 }}
          />
        </div>

        <div className=" relative   w-full lg:flex">
          <motion.div
            className="bg-black lg:w-[243px] absolute   z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.5, stiffness: 70 }}
          />
        </div>

        <div className=" absolute inset-0 z-10 mx-auto">
          <div className="flex justify-center">
            <motion.div
              className="bg-black  pt-[236px]  z-30 flex flex-col gap-[8px]  w-[280px]  border-r-[1px] border-[#ffffff29]   h-screen"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 0.8, type: "", stiffness: 60 }}
            >
              <Link to="/" onClick={closeMobileMenu}>
                <div className="flex contact-component justify-between  border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>HOME </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>

              <Link to="/galleryviewall" onClick={closeMobileMenu}>
                <div className="flex contact-component  justify-between border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>GALLERY </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>

              <Link to="/sportsnewsviewall" onClick={closeMobileMenu}>
                <div className="flex contact-component justify-between border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>NEWS </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>

              <Link to="/shopviewall" onClick={closeMobileMenu}>
                <div className="flex contact-component justify-between border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>SHOP </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>
              <Link to="/highlightsviewall" onClick={closeMobileMenu}>
                <div className="flex contact-component justify-between border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3>HIGHLIGHTS </h3>
                  <ul className="rotate-45 menu">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>
              <Link to="/contactus" onClick={closeMobileMenu}>
                <div className="flex text-nowrap contact-component justify-between group border-b-[1px] border-[#ffffff2e] text-[24px]  font-medium items-center px-[20px] py-[10px]">
                  <h3 className="">CONTACT US </h3>
                  <ul className="rotate-45 menu ">
                    <li>
                      <a>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile " />
                        </span>
                        <span>
                          <GoArrowUp className="hi-icon hi-icon-mobile text-[28px]" />
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </Link>
            </motion.div>

            <motion.div
              className="bg-black w-[320px] pt-[236px] z-30 border-r-[1px] border-[#ffffff29]  h-screen"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 1, type: "", stiffness: 50 }}
            >
              <div className="px-[16px] flex flex-col gap-[8px]  ">
                <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                  ABOUT US
                </h3>
                <div >
                  <Link
                    to="/aboutus#history"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                      History
                    </h3>
                  </Link>
                  <Link
                     to="/aboutus#committeeMembers"
                    onClick={closeMobileMenu}
                  >
                    <h3 className="px-[20px] text-nowrap py-[12px] text-[#6A6A6A] text-[24px] ">
                      committee members
                    </h3>
                  </Link>
                  <Link
                     to="/aboutus#whyChooseUs"
                    onClick={closeMobileMenu}
                  >
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Funding
                  </h3>
                  </Link>

                  <Link
                     to="/aboutus#faq"
                    onClick={closeMobileMenu}
                  >
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    FAQ
                  </h3>
                  </Link>
                  <Link
                     to="/aboutus#getConnected"
                    onClick={closeMobileMenu}
                  >
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Social links
                  </h3>
                  </Link>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="bg-black w-[320px] pt-[236px] z-30 border-r-[1px] border-[#ffffff29]  h-screen"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 1.2, type: "", stiffness: 40 }}
            >
              <div className="px-[16px] flex flex-col gap-[8px]  ">
                <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                  MEMBERSHIP
                </h3>
                <div>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Annual
                  </h3>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Global
                  </h3>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Lifetime
                  </h3>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Benefits
                  </h3>
                  <h3 className="px-[20px] text-nowrap py-[12px] text-[#6A6A6A] text-[24px] ">
                    Become Member
                  </h3>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="bg-black w-[320px] pt-[236px] z-30 border-r-[1px] border-[#ffffff29]  h-screen"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 1, type: "", stiffness: 30 }}
            >
              <div className="px-[16px] flex flex-col gap-[8px]  ">
                <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                  CRICKET
                </h3>
                <div>
                <Link to="/playerviewall" onClick={closeMobileMenu}>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Team
                  </h3>
                  </Link>
                  {/* <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Fixture
                  </h3> */}
                <Link to="/matchscoreviewall" onClick={closeMobileMenu}> 
                  <h3 className="px-[20px] py-[12px] text-nowrap text-[#6A6A6A] text-[24px] ">
                    Match summary
                  </h3>
                  </Link > 
                  {/* <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Get ticket
                  </h3> */}
                </div>
              </div>
            </motion.div>

            <motion.div
              className="bg-black w-[320px] pt-[236px]   border-r-[1px] border-[#ffffff29]  h-screen"
              initial={{ opacity: 0, y: "-100%" }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 1, y: "-100%" }}
              transition={{ duration: 1.3, type: "", stiffness: 20 }}
            >
              <div className="px-[16px] flex flex-col gap-[8px]  ">
                <h3 className="text-[#9E120E] text-[24px] px-[20px] py-[16px] font-medium ">
                  BLOG
                </h3>
                <div>
                  <Link to="/sportsblogviewall#recentblog" onClick={closeMobileMenu}>
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Recent Blogs
                  </h3>
                  </Link>
                  <Link to="/sportsblogviewall#blogform"  onClick={closeMobileMenu} >
                  <h3 className="px-[20px] py-[12px] text-[#6A6A6A] text-[24px] ">
                    Create Own Blog
                  </h3>
                  </Link>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="justify-end hidden w-full lg:flex">
          <motion.div
            className=" bg-black lg:w-[243px]       inset-0   border-r-[1px]  border-[#ffffff29]   h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1, type: "", stiffness: 10 }}
          ></motion.div>
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]      in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.5, stiffness: 70 }}
          />
        </div>
        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]      in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.6, stiffness: 70 }}
          />
        </div>
        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]      in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.7, stiffness: 70 }}
          />
        </div>
        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]      in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.7, stiffness: 70 }}
          />
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 0.9, stiffness: 70 }}
          />
        </div>
        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1, stiffness: 70 }}
          />
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1.1, stiffness: 70 }}
          />
        </div>

        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1.3, stiffness: 70 }}
          />
        </div>
        <div className="  hidden xl:flex">
          <motion.div
            className="bg-black lg:w-[243px]    z-10  in border-r-[1px] border-[#ffffff29]  h-screen  flex flex-col  text-center font-Raleway italic text-[18px] font-semibold text-white gap-[12px] "
            initial={{ opacity: 0, y: "-100%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 1, y: "-100%" }}
            transition={{ duration: 1.5, stiffness: 70 }}
          />
        </div>
      </div>
    </>
  );
};

export default Mobilemenu;
