import React, { useState, useEffect } from 'react';
import Sportsnewsallnews from '../../Components/SportsNews/Sportsnewsallnews';
import Sportsnewsherobanner from '../../Components/SportsNews/Sportsnewsherobanner';
import NewsSkeletonBanner from '../../Components/Skeleton/NewsSkeletonBanner';
import ViewAllSkeleton from '../../Components/Skeleton/ViewAllSkeleton';

function SportsnewsViewAll() {
  const [loading, setLoading] = useState(true);
  const [allSportsNews, setAllSportsNews] = useState([]);
  

  useEffect(() => {
    
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_NEWS;
      console.log(`API URL: ${apiUrl}`);
      

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log('Fetched data:', data);
        
        // const allNews = [...data.sportsVideo, ...data.sportsPhoto];
        // console.log('Combined sports news:', allNews);

        const publishedNews = data.filter(news => news.status === 'published');
        console.log('Filtered published news:', publishedNews);
        
        setAllSportsNews(publishedNews);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
    };

    fetchData();
  }, []);



    if (allSportsNews.length === 0) {
    return <div><NewsSkeletonBanner />
          <ViewAllSkeleton /></div>; 
  }
  console.log('All sports news:', allSportsNews); 
  return (
    <>
      {loading ? (
        <>
          <NewsSkeletonBanner />
          <ViewAllSkeleton />
        </>
      ) : (
        <>
          <Sportsnewsherobanner data={allSportsNews} />
          <Sportsnewsallnews allSportsNews={allSportsNews} />
        </>
      )}
    </>
  );
}

export default SportsnewsViewAll;
