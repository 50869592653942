import React, { useState } from "react";
import {
  minus_icon,
  mobile_minus_icon,
  mobile_plus_icon,
  plus_icon,
} from "../Constants/Data";

const Cricket = [
  {
    id: 1,
    question: "What cricket facilities does Johnians Sports Club offer?",
    answer:
      "Johnians Sports Club features a fully equipped cricket ground with practice nets, a scoreboard, and changing rooms for players.",
  },
  {
    id: 2,
    question:
      "Are there coaching sessions available for cricket at Johnians Sports Club?",
    answer:
      "Yes, Johnians Sports Club provides professional coaching sessions for various age groups and skill levels to enhance players' cricket skills.",
  },
  {
    id: 3,
    question: "Does Johnians Sports Club organize cricket tournaments?",
    answer:
      "Yes, the club regularly organizes local cricket tournaments and friendly matches to promote competitive play among members.",
  },
  {
    id: 4,
    question: "Can members book the cricket ground for private events?",
    answer:
      "Yes, members can book the cricket ground for private events, including matches and training sessions, subject to availability.",
  },
  {
    id: 5,
    question:
      "What age groups are eligible to join cricket programs at Johnians Sports Club?",
    answer:
      "Cricket programs at Johnians Sports Club are open to all age groups, from juniors to adults, with tailored training sessions for each age group.",
  },
  {
    id: 6,
    question:
      "What equipment do I need to bring for cricket practice at Johnians Sports Club?",
    answer:
      "Players are encouraged to bring their own cricket gear, including bats, pads, and helmets, although the club provides some equipment for beginners.",
  },
];

const Football = [
  {
    id: 1,
    question: "What football facilities does Johnians Sports Club offer?",
    answer:
      "Johnians Sports Club has a professional-grade football field with proper markings, goals, and lighting for evening matches.",
  },
  {
    id: 2,
    question:
      "Are there football coaching sessions available at Johnians Sports Club?",
    answer:
      "Yes, the club offers coaching sessions for all age groups, focusing on skill development and team strategies.",
  },
  {
    id: 3,
    question: "Does Johnians Sports Club organize football tournaments?",
    answer:
      "Yes, Johnians Sports Club organizes regular football tournaments for members, promoting competitive play and teamwork.",
  },
  {
    id: 4,
    question: "Can members book the football field for private events?",
    answer:
      "Yes, members can book the football field for private matches and training sessions, subject to availability.",
  },
  {
    id: 5,
    question:
      "What age groups are eligible to join the football programs at Johnians Sports Club?",
    answer:
      "Football programs at Johnians Sports Club are open to all ages, with tailored training for juniors, youth, and adults.",
  },
  {
    id: 6,
    question:
      "What equipment do I need for football practice at Johnians Sports Club?",
    answer:
      "Players should bring their own football boots, shin guards, and any personal equipment, though some gear is available for loan at the club.",
  },
];

const Volleyball = [
  {
    id: 1,
    question: "What volleyball facilities does Johnians Sports Club offer?",
    answer:
      "Johnians Sports Club features multiple indoor and outdoor volleyball courts equipped with quality nets and flooring to ensure a great playing experience.",
  },
  {
    id: 2,
    question:
      "Are there volleyball coaching sessions available at Johnians Sports Club?",
    answer:
      "Yes, the club offers coaching sessions for all skill levels, focusing on technique, teamwork, and competitive strategies.",
  },
  {
    id: 3,
    question: "Does Johnians Sports Club organize volleyball tournaments?",
    answer:
      "Yes, Johnians Sports Club hosts regular volleyball tournaments for members to encourage competitive play and community engagement.",
  },
  {
    id: 4,
    question: "Can members book the volleyball courts for private events?",
    answer:
      "Absolutely! Members can book the volleyball courts for private games or training sessions, subject to availability.",
  },
  {
    id: 5,
    question:
      "What age groups can participate in volleyball programs at Johnians Sports Club?",
    answer:
      "Volleyball programs at Johnians Sports Club are open to all ages, with specific training sessions for juniors, youth, and adults.",
  },
  {
    id: 6,
    question:
      "What equipment do I need for volleyball practice at Johnians Sports Club?",
    answer:
      "Players should bring their own volleyball shoes, knee pads, and any personal equipment. Volleyballs are available for use at the club.",
  },
];

function FAQ() {
  const [activeQuestionId, setActiveQuestionId] = useState(1);
  const [partnerType, setPartnerType] = useState("Cricket");

  const toggleActiveQuestion = (id) => {
    setActiveQuestionId((prevActiveQuestionId) =>
      prevActiveQuestionId === id ? null : id
    );
  };

  const handlePartnerTypeClick = (type) => {
    setPartnerType(type);
  };

  let questions = [];

  switch (partnerType) {
    case "Cricket":
      questions = Cricket;
      break;
    case "Football":
      questions = Football;
      break;
    case "Volleyball":
      questions = Volleyball;
      break;

    default:
      questions = Cricket;
  }

  return (
    // faq heading section
    <div className=" md:px-[24px] px-[16px] md:py-[108px] py-[64px] ">
      <div className="container  mx-auto">
        <div className="flex lg:flex-row flex-col gap-[28px] lg:gap-[48px]   font-Rubik  w-full ">
          <div className="flex flex-col   gap-[16px]">
            <h3 className="md:text-[48px] lg:w-[457px] leading-[120%] text-[28px] text-[#A50808]  font-medium italic">
              FREQUENTLY ASKED QUESTIONS
            </h3>
            {/* faq filter section */}
            <div className="faq-filter flex gap-[12px]  text-white text-[16px] italic">
              <button
                className={
                  partnerType === "Cricket"
                    ? "active-filter  rounded-[20px]"
                    : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"
                }
                onClick={() => setPartnerType("Cricket")}
              >
                <div className=" px-[20px] h-[32px]  flex justify-center items-center">
                  <h3>CRICKET</h3>
                </div>
              </button>
              <button
                className={
                  partnerType === "Football"
                    ? "active-filter  rounded-[20px]"
                    : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"
                }
                onClick={() => setPartnerType("Football")}
              >
                <div className="px-[20px] h-[32px] rounded-[20px] flex justify-center items-center">
                  <h3>FOOTBALL</h3>
                </div>
              </button>

              <button
                className={
                  partnerType === "Volleyball"
                    ? "active-filter rounded-[20px]"
                    : "bg-[#A5A5A5] hover:bg-[#646464] rounded-[20px]"
                }
                onClick={() => handlePartnerTypeClick("Volleyball")}
              >
                <div className=" px-[20px] h-[32px] rounded-[20px] flex justify-center items-center">
                  <h3>VOLLEYBALL</h3>
                </div>
              </button>
            </div>
          </div>
          {/* Faq question map section */}
          <div className="w-full ">
            <div className="questions  flex  flex-col gap-[16px] w-full  text-start font-normal text-[18px]  ">
              {questions.map((faq) => (
                <div key={faq.id}>
                  <div
                    className="flex  flex-col  w-full justify-between  rounded-[44px]   hover:text-black     "
                    onClick={() => toggleActiveQuestion(faq.id)}
                  >
                    <div className="flex w-full justify-between md:items-center   ">
                      <button
                        className={`${
                          activeQuestionId === faq.id
                            ? "active  w-full rounded-[44px] md:pl-[28px] pl-[20px] md:p-[20px] pr-[12px] py-[10px] "
                            : " bg-[#F6F6F6] w-full  rounded-[44px] md:pl-[28px] pl-[24px] md:p-[20px] pr-[12px] py-[10px]"
                        } `}
                      >
                        <div className="flex items-center justify-center ">
                          <div className="  justify-between font-medium leading-[24px]   w-full flex items-start text-start    rounded-[44px] text-[16px] ">
                            {faq.question}
                          </div>
                          <div className=" md:hidden ">
                            {activeQuestionId === faq.id ? (
                              <img
                                src={mobile_minus_icon}
                                alt=""
                                className="w-[36px] icon rotate "
                              />
                            ) : (
                              <img
                                src={mobile_plus_icon}
                                alt=""
                                className="w-[36px] icon"
                              />
                            )}
                          </div>
                        </div>
                      </button>
                      <div className="md:block hidden ">
                        {activeQuestionId === faq.id ? (
                          <img
                            src={minus_icon}
                            alt=""
                            className="w-[60px] icon rotate  "
                          />
                        ) : (
                          <img
                            src={plus_icon}
                            alt=""
                            className="w-[60px] icon"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`answer ${
                      activeQuestionId === faq.id ? "show" : ""
                    }`}
                  >
                    {faq.answer && (
                      <div className=" pl-[24px] pt-[8px] pb-[16px] md:pr-[38px] pr-[24px] text-start font-light leading-[24px] text-black text-[16px]">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
