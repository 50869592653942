import React from "react";
import { Link } from "react-router-dom";

function ShopCard({
  thumbnail_image,
  product_category,
  price,
  final_price,
  discount,
  name,
  sub_title,
  new_tag,
  discount_status,
  handleshops
  
}) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const thumbnailPath = "/storage/thumbnails/";

  return (
    <>
        <Link to={handleshops}  style={{ textDecoration: "none" }} onClick={handleClick}>
      <div className="box  font-Rubik border-[1px] rounded-[12px] overflow-hidden">
      {new_tag === "New" && (
      <div className="relative flex items-start justify-start ">
          <h4 className="absolute uppercase text-white  bg-[#9E120E] px-[16px] mt-4 -ml-10 flex justify-center py-[8px] w-[150px] -rotate-45  ">{new_tag}</h4>
          </div>
          )}
        <div className="bg-[#E9E9E9]  p-[20px] rounded-t-[12px] h-[345px]  flex justify-center">

          <img  src={thumbnail_image ? `${apiUrl}${thumbnailPath}${thumbnail_image}` :""} alt="" className="h-[297px] object-cover" />
        </div>
        <div className=" flex flex-col  items-start px-[16px] py-[12px] gap-[12px] ">
          <h3 className="px-[12px] py-[4px] bg-[#666666]  rounded-[4px] text-white uppercase font-medium text-[10px]  leading-[150%] ">
            {product_category}
          </h3>

          {discount_status !== "Unvisible" && (
            <div className="flex gap-[4px]">
              <div className="flex gap-[8px] items-center">
                <h5 className="text-[24px] font-medium leading-[150%]">
                  Rs.{final_price}
                </h5>
                <h5 className="text-[#2121216b] line-through">Rs.{price}</h5>
              </div>
              <h5 className="text-[#006B2B] bg-[#C7FFDD] w-[99px] px-[16px] py-[8px] rounded-[8px]">
                {discount}% OFF
              </h5>
            </div>
          )}

{discount_status !== "Visible" && (
   <h5 className="text-[24px] font-medium leading-[150%]">
   Rs.{price}
 </h5>
   )}



          <div className=" flex flex-col gap-[4px]">
            <h3 className="text-[18px] uppercase text-[#9E120E] font-medium leading-[150%]">
              {name}
            </h3>
            <h4 className="text-[#212121] font-light text-[12px] leading-[150%]">
              {sub_title}
            </h4>
          </div>
        </div>
      </div>
      </Link>
    </>
  );
}

export default ShopCard;
