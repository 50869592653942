import React, { useEffect, useState } from "react";
import { IoPlayCircle } from "react-icons/io5";
import LargeButton from "../../Buttons/Largebutton";
import SportsNewsCard from "../Cards/SportsNewsCard";

function Sportsnewsallnews({ allSportsNews }) {
  const [showAllCards, setShowAllCards] = useState(false);
  const [displayCount, setDisplayCount] = useState(6); 

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1280) {
        setDisplayCount(6);
      } else if (windowWidth >= 768 && windowWidth < 1280) { 
        setDisplayCount(4);
      } else {
        setDisplayCount(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sortedNews = allSportsNews.slice().sort((a, b) => a.id - b.id);
  const reversedNews = sortedNews.slice().reverse();
  const newsToDisplay = showAllCards ? reversedNews : reversedNews.slice(0, displayCount);

  const toggleShowAllCards = () => {
    setShowAllCards(!showAllCards);
  };

  function truncateText(text, maxLength) {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  }

  

  return (
    <>
      <div className="py-[108px] px-[24px]">
        <div className="container mx-auto font-Rubik">
          <div className="flex flex-col gap-[32px] text-[24px] leading-[24px]">
            <div className="text-[#A50808] italic font-medium flex gap-[12px] items-center">
              <IoPlayCircle className="text-[#A50808]" />
              <h3>RECENT NEWS</h3>
            </div>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[24px]">
              {newsToDisplay.map((news) => (
                <SportsNewsCard
                  key={news.id}
                  youtubeThumbnail={news.youtube_thumbnail}
                  youtubeLink={news.youtube_link}
                  image={news.image}
                  title={news.title}
                  heading={news.heading}
                  publishedDate={news.published_date}
                  content={truncateText(news.content && news.content[0] ? news.content[0] : '', 100)}
                  handleNewsClick={`/sportsnewsviewpage/${news.id}{newsItem.id}-${news.title ? news.title.replace(/\s+/g, '') : '-'}`}
                />
              ))}
            </div>
            <div className="justify-center w-full md:flex">
              
            {displayCount < allSportsNews.length && (
              <LargeButton onClick={toggleShowAllCards} className="contact-component">
                <h3>{showAllCards ? "VIEW LESS" : "VIEW MORE"}</h3>
              </LargeButton>
               )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sportsnewsallnews;
