import React, { useEffect, useState } from "react";
import MatchCard from "../Cards/MatchCard";
import CricketLandingSkeleton from "../Skeleton/Cricket/CricketLandingSkeleton";

function MatchScore() {
  const [matchData, setMatchData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_MATCHES}`
        );
        const data = await response.json();
        setMatchData(data.data);
      } catch (error) {
        console.error("Error fetching sports data:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  if (!matchData.length) {
    return <CricketLandingSkeleton />;
  }

  const liveMatches = matchData.filter((match) => match.status === "Live");

  const nonLiveMatches = matchData
    .filter((match) => match.status !== "Live")
    .reverse();

  const limitedMatchData = [...liveMatches, ...nonLiveMatches].slice(0, 3);

  return (
    <div className="md:py-[110px] py-[80px]  pb-[64px]  px-[24px]">
      <div className="container mx-auto">
        <div className="match-card flex w-full overflow-x-scroll gap-[16px]">
          {limitedMatchData.map((match) => {
            let statusClassName;
            let viewMatchSummaryClassName;

            switch (match.status) {
              case "Recent":
                statusClassName = "bg-[#FFD4D2] text-[#9E120E]";
                viewMatchSummaryClassName =
                  "flex justify-center text-white text-[14px] font-Rubik bg-red-gradient py-[12px] rounded-b-[12px]";
                break;
              case "Upcoming":
                statusClassName = "bg-[#D4F1D2] text-[#006B2B]";
                viewMatchSummaryClassName =
                  "flex justify-center text-white text-[14px] font-Rubik bg-green-gradient py-[12px] rounded-b-[12px]";
                break;
              case "Cancelled":
                statusClassName = "bg-[#D4D4D4] text-[#231F20]";
                viewMatchSummaryClassName =
                  "flex justify-center text-white text-[14px] font-Rubik bg-[#555555] py-[12px] rounded-b-[12px]";
                break;

              case "Live":
                statusClassName = "bg-[#F1F1F1] text-[#9E120E]";
                viewMatchSummaryClassName =
                  "flex justify-center text-white text-[14px] font-Rubik bg-[#B82222] py-[12px] rounded-b-[12px]";
                break;
              default:
                statusClassName = "";
                viewMatchSummaryClassName =
                  "flex justify-center text-white text-[14px] font-Rubik py-[12px] rounded-b-[12px]";
                break;
            }

            return (
              <MatchCard
                key={match.id}
                title={match.match_name}
                match_day={new Date(match.date).toLocaleDateString("en-US", {
                  weekday: "long",
                })}
                match_date={new Date(match.date).toLocaleDateString("en-US")}
                match_time={match.time}
                status={match.status}
                teams={[
                  {
                    school_name: match.team1_name,
                    score: match.team1_score,
                    wicket: match.team1_wicket,
                    overs: match.team1_over,
                    school_logo: match.team1_logo,
                  },
                  {
                    school_name: match.team2_name,
                    score: match.team2_score,
                    wicket: match.team2_wicket,
                    overs: match.team2_over,
                    school_logo: match.team2_logo,
                  },
                ]}
                result={match.winner_title}
                playground={match.location}
                statusClassName={statusClassName}
                viewMatchSummaryClassName={viewMatchSummaryClassName}
                handleClick={`/matchsummarypage/${match.id}-${match.match_name ? match.match_name.replace(/\s+/g, '') : '-'}-${match.status ? match.status.replace(/\s+/g, '') : '-'}`}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MatchScore;
