import React from "react";
import LargeButton from "../Buttons/Largebutton";


function Getmembershipposter() {
  return (
    <div
      className="get-membership-poster flex flex-col h-[481px]     md:items-start md:justify-center justify-end  w-full px-4 md:px-[24px]   "
     
    >
      <div className="container z-20 mx-auto">
        <div className=" get-membership-content flex flex-col gap-[24px] md:w-[614px] font-Rubik italic">
          <div className="flex flex-col md:text-start text-center gap-[4px]">
            <h2 className="text-[#9E120E] font-medium md:text-[40px] text-[22px] md:leading-[51px] leading-[28px]"> GET MEMBERSHIP FOR ACCESS ALL PREMIUM BENEFITSM </h2>
            <p className="md:text-[20px] text-[16px]">
              Coach, Captain and his Comrades are geared up, and keen to begin
              the season Bold!
            </p>
          </div>

          <div className="lg:w-[200px] contact-component" >
            <LargeButton>
              <h3>GET MEMBERSHIP</h3>
            </LargeButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Getmembershipposter;
