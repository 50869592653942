import React, { useEffect, useRef, useState } from 'react'
import BlogHeroBanner from '../../Components/SportsBlog/BlogHeroBanner'
import BlogViewAll from '../../Components/SportsBlog/BlogViewAll';
import BlogRequestForm from '../../Components/SportsBlog/BlogRequestForm';
import PreviousBlogs from '../../Components/SportsBlog/PreviousBlogs';
import BlogViewAllSkeleton from '../../Components/Skeleton/Blog/BlogViewAllSkeleton';
import { useLocation } from 'react-router-dom';

function BlogViewAllPage() {
  const [sportsData, setSportsData] = useState([]);
  const [isLoading] = useState(true);
  const location = useLocation();

  
  const blogform = useRef(null);
  const recentblog = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      const hash = location.hash;
      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0); 
      }
    }
  }, [location, isLoading]);


  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_BLOGS;
      console.log("Fetching data from:", apiUrl); 

      try {
        const response = await fetch(apiUrl);
        console.log("API response status:", response.status); 
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data); 
        const publishedBlogs = data.filter(
          (data) => data.status === "published"
        );
       
        setSportsData(publishedBlogs); 
      } catch (error) {
        console.error("Error fetching data:", error);
       
      }
    };

    fetchData();
  }, []);




  if (sportsData.length === 0) {
    return <div><BlogViewAllSkeleton /></div>; 
  }
  return (
   <>
   
   <div>
   {sportsData && <BlogHeroBanner sportsData={sportsData} />}
   </div>

   <div ref={recentblog} id='recentblog'>
   {sportsData && <BlogViewAll sportsData={sportsData} />}
   </div>


{/* Request form and previous blog */}
   <div className='py-[64px] md:px-[24px] px-[16px] '>
    <div className='container mx-auto '>
    <div className=' flex lg:flex-row flex-col gap-[64px]'  ref={blogform} id="blogform" >
    <BlogRequestForm/>
    {sportsData && <PreviousBlogs sportsData={sportsData} />}
    </div>
   </div>
   </div>
   {/* Request form and previous blog */}
   </>
  )
}

export default BlogViewAllPage