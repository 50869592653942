import React, { useEffect, useState } from "react";
import ShopContactForm from "../../Components/Shop/ShopContactForm";
import ShopLatestedProducts from "../../Components/Shop/ShopLatestedProducts";
import ShopIndividualMain from "../../Components/Shop/ShopIndividualMain";
import { useParams } from "react-router-dom";
import ShopIndividualPageSkeleton from "../../Components/Skeleton/Shop/ShopIndividualPageSkeleton";

function ShopIndividualPage() {
  const { id } = useParams();
  const [shopProduct, setShopProduct] = useState(null);


  const [error, setError] = useState(null);
  

  useEffect(() => {
    const fetchShopProduct = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL_SHOPS}/${id}`);
    
        if (!response.ok) {
          throw new Error('Failed to fetch shop product');
        }
        const data = await response.json();
        if (data && data.success && data.data) {
          
          setShopProduct(data.data);
          console.log(data.data);
        } else {
          throw new Error('Invalid data format');
        }
      } catch (error) {
        setError(error.message);
      } finally {
     
      }
    };

    fetchShopProduct();
  }, [id]);

  if (!shopProduct) return <div>
    <ShopIndividualPageSkeleton/>
  </div>;
  if (error) return <div>Error: {error}</div>;
  if (!shopProduct) return null;
  

  return (
    <>

   
      <div className="bg-header-gradient h-[144px] z-10"></div>
      
      <div className="pt-[48px] b pb-[108px] font-Rubik bg-white relative md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex lg:flex-row flex-col justify-between xl:gap-0 gap-[32px]  ">
          <div className="xl:w-[659px] lg:w-[638px]">
              <ShopIndividualMain shopProduct={shopProduct} />
            </div> 
            <div className="xl:w-[650px]">
              <ShopContactForm shopProduct={shopProduct} />
            </div>
          </div>
        </div>
      </div>
      <div className="border-t-[1px] border-[#E5DADA]">
        <div className="container mx-auto">
          <ShopLatestedProducts currentItemId={shopProduct.id} />
        </div>
      </div>
    </>
  );
}

export default ShopIndividualPage;
