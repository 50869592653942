import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function PlayersViewAllSkeleton() {
    const skeletonCards = Array(4).fill(0);
  return (
    <>
    <div>
      
      <div className="border-[1px] relative border-[#D7D7D7] w-full "></div>
      <div className="flex justify-center mb-[48px]">
      <div className="mb-[30px] absolute -mt-[22px] ">
        <Skeleton width={100} height={35} borderRadius={12} />
      </div>
    
      </div>
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        {skeletonCards.map((_, index) => (
          <div
            key={index}
            className=" h-[341px] rounded-[12px] p-[20px] bg-[#c1c1c1] flex flex-col justify-end items-center animate-pulse"
          >
            <div>
              <Skeleton width={170} height={20} borderRadius={12} />
            </div>
            <div className="bg-[#b1b1b1] mt-[10px] h-[75px] w-full rounded-[8px]"></div>
          </div>
        ))}
      </div>
      </div>
    </>
  );
}

export default PlayersViewAllSkeleton;
