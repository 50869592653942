import React, { useEffect, useState } from "react";
import MemorableSportingEvents from "./MemorableSportingEvents";
import SportsGalleryCarosel from "./SportsGalleryCarosel";
import { IoPlayCircle } from "react-icons/io5";
import Smallbutton from "../../Buttons/Smallbutton";
import { Link } from "react-router-dom";
import GalleryLandingSkeleton from "../Skeleton/Gallery/GalleryLandingSkeleton";

function SportsGallery() {
  const [galleryData, setGalleryData] = useState([]);
 

  useEffect(() => {
    fetchGalleryData();
  }, []);

  const fetchGalleryData = () => {
    fetch(process.env.REACT_APP_API_URL_GALLERYS)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        
        const publishedData = data.data.filter(item => item.status === "published");
        setGalleryData(publishedData);
      
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      
      });
  };
  if (!galleryData.length) {
    return <div>
<GalleryLandingSkeleton/>
    </div>;
  }

  return (
    <>

<div className="md:py-[60px] py-[48px] md:px-[24px] px-[16px] ">
      <div className="container mx-auto">
        <div className="flex flex-col gap-[16px]">
          {/* gallery heading */}
          <div className="flex md:justify-between justify-center pr-[30px]">
            <div className="flex text-primary italic font-medium  gap-[12px] items-center sports-news-heading">
              <IoPlayCircle className="text-[26px]" />
              <h3 className="md:text-[24px] text-[18px]">
                MEMORABLE SPORTING EVENTS{" "}
              </h3>
            </div>

            <Link to="/galleryviewall" className="md:block hidden contact-component">
              <Smallbutton>
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </Link>
          </div>
      <div className="md:block hidden">
        <MemorableSportingEvents galleryData={galleryData} />
      </div>
      <div className="md:hidden block">
        <SportsGalleryCarosel galleryData={galleryData} />
      </div>

      <Link to="/galleryviewall" className="md:hidden">
              <Smallbutton>
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </Link>

      </div>
      </div>
      </div>
    </>
  );
}

export default SportsGallery;
