import React, { useEffect, useState } from 'react'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"
function BlogLandingSkeleton() {
    const [cardCount, setCardCount] = useState(1);
    useEffect(() => {
      const updateCardCount = () => {
        const width = window.innerWidth;
        if (width >= 1280) {
          setCardCount(3);
        } else if (width >= 768) {
          setCardCount(2);
        } else {
          setCardCount(1);
        }
      };
  
      updateCardCount();
      window.addEventListener("resize", updateCardCount);
  
      return () => window.removeEventListener("resize", updateCardCount);
    }, []);
  return (
    <>
    <div className='py-[116px] md:px-[24px] px-[16px]'>
        <div className='container mx-auto'>
        <div className="flex justify-between pb-[16px]">
          <div className=" flex items-center gap-[8px]">
          <Skeleton width={30} borderRadius={30} height={30} />
          <span className='md:w-[300px] w-[150px]'>
        <Skeleton  borderRadius={30} height={25} />
        </span>
        </div>
        <Skeleton width={100} borderRadius={30} height={25} />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[20px]">
        {[...Array(cardCount)].map((_, index) => (
    <div className="  w-full rounded-[12px] border-[1px]    h-[560px]">
      <div className="h-[300px] animate-pulse rounded-t-[12px] bg-[#E6E6E6] "></div>
      <div className="px-[20px] py-[16px]">
        <div className="flex justify-between items-center">
            <div className='flex items-center'> 
          <Skeleton width={50} borderRadius={50} height={50} />
          <div className=''>
            
          <Skeleton width={180}  borderRadius={26} height={15} />
         
          <Skeleton width={120}  borderRadius={26} height={15} />
          </div>
          </div>
          <Skeleton width={40} borderRadius={26} height={40} />
        </div>

       

        <div className="pt-[20px]  xl:w-[424px] gap-[10px]">
          <Skeleton count={2} borderRadius={26} height={26} />
        </div>

        <div className="pt-[20px]   ">
          <Skeleton count={3} borderRadius={26} height={10} />
        </div>
      </div>
    </div>
      ))}
        </div>
    </div>
    </div>
  </>
  )
}

export default BlogLandingSkeleton