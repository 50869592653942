import React, { useEffect, useRef, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { play_icon } from "../../Constants/Data";
import Sharebutton from "../../Buttons/Sharebutton";
import { GoShareAndroid } from "react-icons/go";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { IoClose } from "react-icons/io5";
// import Whitebutton from "../../Buttons/Whitebutton";
import GalleryOtherAlbum from "../../Components/SportsGallery/GalleryOtherAlbum";
import GalleryIndividualSkeleton from "../../Components/Skeleton/Gallery/GalleryIndividualSkeleton";
import SocialShareButton from "../../Buttons/SocialShareButton";
import { Helmet } from "react-helmet";

function GalleryIndivualPage() {
  const { id } = useParams();
  const [galleryItem, setGalleryItem] = useState(null);
  const [ error,setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [countText, setCountText] = useState("1 of 0");
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [feedbackVisible, ] = useState(false);

  
  const closeShareButtons = () => {
    setShowShareButtons(false);
  };
  const componentRef = useRef(null);
  
  const toggleShareButtons = (index) => {
    setCurrentItemIndex(index);
    setShowShareButtons((prevShowShareButtons) => !prevShowShareButtons);
  };
  useEffect(() => {
     // Log galleryItem to check its structure
  console.log('Gallery itemddddd:', galleryItem);
    const fetchShopProduct = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_GALLERYS}/${id}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch shop product");
        }
        const data = await response.json();
        if (data && data.success && data.data) {
          setGalleryItem(data.data);
          console.log(data.data);
        } else {
          throw new Error("Invalid data format");
        }
      } catch (error) {
        setError(error.message);

      } finally {
      }
    };

    fetchShopProduct();
  }, [id]);

  const openModal = (index) => {
    setCurrentItemIndex(index);
    setModalOpen(true);
    updateCountNumber(index);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === mergedItems.length - 1 ? 0 : prevIndex + 1
    );
    updateCountNumber(
      currentItemIndex === mergedItems.length - 1 ? 0 : currentItemIndex + 1
    );
  };
  const prevItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === 0 ? mergedItems.length - 1 : prevIndex - 1
    );
    updateCountNumber(
      currentItemIndex === 0 ? mergedItems.length - 1 : currentItemIndex - 1
    );
  };

  if (!galleryItem) {
    return (
      <div>
        <GalleryIndividualSkeleton />
      </div>
    );
  }

  const truncatedTitle = galleryItem.title.split(" ").slice(0, 4).join(" ");

  const mergedItems = galleryItem.medias.reduce((acc, media) => {
    if (media.images && media.images.length > 0) {
      const images = JSON.parse(media.images);
      acc.push(
        ...images.map((image) => ({
          type: "image",
          src: `${process.env.REACT_APP_API_URL}/storage/${image}`,
        }))
      );
    }
    if (media.youtube_link) {
      acc.push({
        type: "video",
        youtube_link: media.youtube_link,
        youtube_thumbnail: `${process.env.REACT_APP_API_URL}/storage/youtubethumbnails/${media.youtube_thumbnail}`,
      });
    }
    return acc;
  }, []);

  const shuffledItems = mergedItems.sort(() => Math.random() - 0);
  
  // const handleDownload = (src) => {
  //   const link = document.createElement("a");
  //   link.href = src;
  //   link.download = "image.jpg";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };


//   const handleDownload = e => {
//     console.log(e.target.href);
//     fetch(e.target.href, {
//       method: "GET",
//       headers: {}
//     })

//     .then(response => {
//       response.arrayBuffer().then(function(buffer) {
//         const href = window.URL.createObjectURL(new Blob([buffer]));
//         const link = document.createElement("a");
//         link.href =  `${process.env.REACT_APP_API_URL}/storage/${image}`;
//         link.setAttribute("download", "image.png"); 
//         document.body.appendChild(link);
//         link.click();
//       });
//     })
//     .catch(err => {
//       console.log(err);
//     });
// };

// const handleDownload = (src) => {
//   fetch(src, {
//     method: "GET",
//     headers: {},
//   })
//     .then((response) => response.blob())
//     .then((blob) => {
//       const url = window.URL.createObjectURL(new Blob([blob]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "image.png");
//       document.body.appendChild(link);
//       link.click();
//       link.parentNode.removeChild(link);
//       setFeedbackVisible(true);
//       setTimeout(() => setFeedbackVisible(false), 3000);
//     })
//     .catch((err) => console.log(err));
// };

// function handleDownload(imageUrl) {
//   const link = document.createElement("a");
//   link.href = `${process.env.REACT_APP_API_URL}/storage/gallery_thumbnail/${imageUrl}`;
//   link.download = "image.jpg";
//   link.click();
//   link.parentNode.removeChild(link);
// }

  
  const updateCountNumber = (index) => {
    const currentCount = index + 1;
    const totalCount = mergedItems.length;
    setCountText(`${currentCount} of ${totalCount}`);
  };

  const imagesCount = galleryItem.medias.reduce((count, media) => {
    return count + (media.images ? JSON.parse(media.images).length : 0);
  }, 0);
  const videosCount = galleryItem.medias.reduce((count, media) => {
    return count + (media.youtube_link ? 1 : 0);
  }, 0);
  const firstYouTubeLink = galleryItem.medias.find(
    (media) => media.youtube_link
  )?.youtube_link;

  const getEmbedUrl = (videoUrl) => {
    if (!videoUrl) return "";
    const url = new URL(videoUrl);
    const videoId = url.searchParams.get("v") || url.pathname.split("/").pop();
    return `https://www.youtube.com/embed/${videoId}`;
  };

  // const embedUrl = galleryItem.firstYouTubeLink ? getEmbedUrl(galleryItem.firstYouTubeLink) : "";
  if (error) {
    return <div><GalleryIndividualSkeleton/></div>;
  }
  return (
    <>

<Helmet>
        <title>{galleryItem.title}</title>
        <meta name="description" content={`View details and media for ${galleryItem.title}`} />
        <meta property="og:title" content={galleryItem.title} />
        <meta property="og:description" content={`View details and media for ${galleryItem.title}`} />
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/storage/${galleryItem.main_image}`} />
        <meta property="og:url" content={`${window.location.origin}/galleryindividualpage/${galleryItem.id}-${galleryItem.title.replace(/\s+/g, "")}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={galleryItem.title} />
        <meta name="twitter:description" content={`View details and media for ${galleryItem.title}`} />
        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/storage/${galleryItem.src}`} />
      </Helmet>


      
      <div className="footer_bottom_box h-[144px]"></div>

      <div className="pt-[48px] relative pb-[96px] md:px-[24px] px-[16px] font-Rubik">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[32px]">
            {/* gallery individual page headings start */}
            <div className="flex flex-col  gap-[12px]">
              <div className="flex   gap-[8px] items-center text-[18px]">
                <Link to="/">
                <div className="flex  gap-[4px] items-center text-[#4D4D4D]">
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                </Link>
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  <h3>GALLERY</h3>
                  <FiArrowUpRight />
                </div>
                <h3 className="text-[#9E120E] md:block hidden uppercase underline  leading-[20px]">
                  <h3>{truncatedTitle}...</h3>
                </h3>
              </div>

              <h2 className="uppercase italic font-medium text-[36px] leading-[120%] text-[#A50808]">
                {galleryItem.title}
              </h2>
            </div>
            {/* gallery individual page headings end */}
            <div className="flex flex-col gap-[24px]">
              <div className="gallery-buttons flex justify-between text-[16px]">
                <div className="flex gap-[8px] ">
                  {videosCount > 0 && (
                    <Sharebutton>
                      <div className="flex items-center gap-[4px]">
                        <h4> {videosCount} VIDEOS</h4>
                      </div>
                    </Sharebutton>
                  )}
                  {imagesCount > 0 && (
                    <Sharebutton>
                      <div className="flex items-center gap-[4px]">
                        <h4> {imagesCount} PHOTOS</h4>
                      </div>
                    </Sharebutton>
                  )}
                </div>

                <div className="share-buttons flex gap-[8px] ">
                  <SocialShareButton
                    pageUrl={`${window.location.origin}/galleryindividualpage/${
                      galleryItem.id
                    }-${
                      galleryItem.title
                        ? galleryItem.title.replace(/\s+/g, "")
                        : "-"
                    }`}
                    pageTitle={galleryItem.title}
                    className="hidden"
                  />
                </div>
              </div>

              {/* gallery grid start  */}
              <div>
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                  {/* Displaying videos and photos */}
                  {shuffledItems.map((item, index) => (
                    <div
                      key={index}
                      className={`relative ${
                        index === 0
                          ? "md:row-span-2 h-[620px]"
                          : index === 1
                          ? "md:col-span-2"
                          : index === 4
                          ? "md:row-span-1 "
                          : index === 5
                          ? "md:col-span-2 h-[300px] "
                          : index === 6
                          ? "md:col-span-2 h-[300px]"
                          : ""
                      }  object-cover h-[300px] `}
                    >
                      {item.youtube_link ? (
                        <>
                          <div
                            className="rounded-[12px] relative   h-[500px]  flex cursor-pointer  "
                            style={{
                              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${item.youtube_thumbnail})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "100%",
                            }}
                          >
                            <div className="absolute flex   right-2 items-start     h-full justify-end">
                              <div className="w-full absolute flex  p-[24px] justify-end">
                                <div
                                  className=" bg-[#FFFFFF] cursor-pointer lg:w-[60px] p-[8px] md:w-[40px] md:h-[30px]  lg:h-[40px] w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                                  onClick={() => toggleShareButtons(index)}
                                >
                                  <GoShareAndroid />
                                </div>
                              </div>

                              {showShareButtons &&
                                currentItemIndex === index && (
                                  <div
                                    className={` mt-[15px]  right-4 social-share ${
                                      showShareButtons ? "visible" : "hidden"
                                    }`}
                                    ref={componentRef}
                                  >
                                    <SocialShareButton
                                      close={closeShareButtons}
                                      pageUrl={`${window.location.origin}/galleryindividualpage/${
                                        galleryItem.id
                                      }-${
                                        galleryItem.title
                                          ? galleryItem.title.replace(/\s+/g, "")
                                          : "-"
                                      }`}
                                      pageTitle={galleryItem.title}
                                    />
                                  </div>
                                )}
                            </div>

                            <div
                              className="h-full flex justify-center items-center w-full"
                              onClick={() => openModal(index)}
                            >
                              <img
                                src={play_icon}
                                alt=""
                                className="w-[54px]"
                              />
                            </div>

                            <div className="absolute mt-7  -z-10 hidden">
                              <iframe
                                width="100%"
                                height="100%"
                                src={`${item.youtube_link}&modestbranding=0&controls=0`}
                                frameBorder="8"
                                allowFullScreen
                                title="video"
                              ></iframe>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          className="rounded-[12px]  h-full  cursor-pointer flex  p-[24px] items-start justify-end"
                          style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url(${item.src})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          <div
                            className="w-full h-full"
                            onClick={() => openModal(index)}
                          ></div>
                          <div className="relative  flex   items-start     h-full justify-end">
                            <div className="w-full absolute flex   justify-end">
                              <div
                                className=" bg-[#FFFFFF] p-[8px] lg:w-[40px] md:w-[30px] md:h-[30px]  lg:h-[40px] w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                                onClick={() => toggleShareButtons(index)}
                              >
                                <GoShareAndroid />
                              </div>
                            </div>
                            {showShareButtons && currentItemIndex === index && (
                              <div
                                className={`   absolute -mt-[10px] social-share ${
                                  showShareButtons ? "visible" : "hidden"
                                }`}
                                ref={componentRef}
                              >
                                <SocialShareButton
                                  close={closeShareButtons}
                                  pageUrl={`${window.location.origin}/galleryindividualpage/${
                                    galleryItem.id
                                  }-${
                                    galleryItem.title
                                      ? galleryItem.title.replace(/\s+/g, "")
                                      : "-"
                                  }`}
                                  pageTitle={galleryItem.title}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* gallery grid end  */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying full-size image or video */}
      {modalOpen && (
        <div className="fixed scrollbar top-0 left-0 w-full h-screen  font-Rubik bg-[#050505] bg-opacity-100 flex justify-center items-center z-50 px-[16px] md:px-[24px] overflow-x-scroll " >
          <div className="container mx-auto  w-full h-full ">
            <div className=" flex flex-col md:gap-[64px] gap-[48px] w-full">
              <button
                className="flex justify-end items-end w-full   p-[10px]  text-white text-2xl"
                onClick={closeModal}
              >
                <div className="flex gap-[12px] text-[18px] items-center border-[1px] border-solid border-[#ffffff19] px-[16px] py-[12px]">
                  <h4>Close</h4>

                  <IoClose />
                </div>
              </button>

              <div className="flex flex-col gap-[16px] justify-center items-center">
                <div className="flex gap-[32px] w-full   ">
                  <button
                    className="  text-[#969595] text-[60px] hidden lg:block cursor-pointer"
                    onClick={prevItem}
                  >
                    <IoIosArrowDropleft />
                  </button>
                  {mergedItems[currentItemIndex].type === "image" ? (
                    <img
                      src={mergedItems[currentItemIndex].src}
                      alt={`Gallery item ${currentItemIndex}`}
                      className="w-full md:h-[620px] object-cover"
                    />
                  ) : (
                    <div className="w-full h-full ">
                      {firstYouTubeLink && (
                        <iframe
                          className="w-full md:h-[620px]"
                          src={getEmbedUrl(mergedItems[currentItemIndex].youtube_link)}
                          frameBorder="0"
                          allowFullScreen
                          title="video"
                        ></iframe>
                      )}
                    </div>
                  )}

                  <button
                    className="   text-[#969595] text-[60px] hidden lg:block  "
                    onClick={nextItem}
                  >
                    <IoIosArrowDropright />
                  </button>
                </div>
                <div className="flex justify-between w-full text-white">
                  <div className="lg:hidden flex  gap-[8px] text-[20px] items-center  font-medium">
                    <h3>{currentItemIndex + 1}</h3>
                    <h3 className="text-[#817F7F] text-[16px] font-normal">
                      of
                    </h3>
                    <h3>{mergedItems.length}</h3>
                  </div>

                  <div className="flex">
                    <button
                      className="  text-[#969595] md:text-[60px] text-[36px] block lg:hidden cursor-pointer"
                      onClick={prevItem}
                    >
                      <IoIosArrowDropleft />
                    </button>
                    <p className="text-sm text-black hi">{countText}</p>
                    <button
                      className="   text-[#969595] md:text-[60px] text-[36px] block lg:hidden  "
                      onClick={nextItem}
                    >
                      <IoIosArrowDropright />
                    </button>
                  </div>
                </div>

                <div className="text-white  pb-[30px] lg:px-[100px] w-full flex justify-between">
                  {/* share icons */}
                  <div className="flex gap-[8px] items-center ">

                  <SocialShareButton
                    pageUrl={`${window.location.origin}/galleryindividualpage/${
                      galleryItem.id
                    }-${
                      galleryItem.title
                        ? galleryItem.title.replace(/\s+/g, "")
                        : "-"
                    }`}
                    pageTitle={galleryItem.title}
                    className="hidden"
                  />
                    
                  </div>

                  {/* number counts */}

                  <div
                    className="lg:flex hidden gap-[8px] text-[24px] items-center  font-medium"
                    onClick={updateCountNumber}
                  >
                    <h3>{currentItemIndex + 1}</h3>
                    <h3 className="text-[#817F7F] text-[20px] font-normal">
                      of
                    </h3>
                    <h3>{mergedItems.length}</h3>
                  </div>

                  {/* download button */}

                  {/* {mergedItems[currentItemIndex].type === "image" && (
    <Whitebutton onClick={() => handleDownload(galleryItem.src)}>DOWNLOAD</Whitebutton>
  )}
                 */}


{feedbackVisible && (
        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white text-center py-2">
          Download Started
        </div>
      )}

                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <GalleryOtherAlbum currentItemId={galleryItem.id} />
    </>
  );
}

export default GalleryIndivualPage;
