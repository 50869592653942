import React from 'react'
import Skeleton from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";
function NewsSubSkelton() {
  return (
    <div>
         <div className=" lg:pl-[20px] w-full grid grid-cols-1 gap-4">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="w-full rounded-[12px] border-[1px] h-[190px] flex md:justify-between">
          <div className="h-[190px] w-[238px] animate-pulse rounded-l-[12px] bg-[#E6E6E6]"></div>
          <div className="px-[20px] w-full md:w-[284px] lg:w-full py-[16px]">
            <div className="flex  justify-between items-center">
              <Skeleton width={70} height={25} />
              <Skeleton width={40} borderRadius={26} height={40} />
            </div>

            <div className="pt-[20px] flex items-center gap-[10px]">
              <Skeleton width={25} height={25} />
              <Skeleton width={83} borderRadius={26} height={15} />
            </div>

            <div className="pt-[20px] xl:w-[424px] gap-[10px]">
              <Skeleton count={2} borderRadius={26} height={15} />
            </div>
          </div>
        </div>
      ))}
    </div> 
    </div>
  )
}

export default NewsSubSkelton