import React from "react";
import { fb_icon, get_connected_image, mail_icon,  phone_icon_icon, side_pattern_pattern, twitter_icon, youtube_icon } from "../Constants/Data";
import { Link } from "react-router-dom";


function Aboutgetconnected() {
  return (
    <>
      <div className="py-[64px] md:px-[24px] px-0">
        <div className="container mx-auto">
          <div
            className="committee-card flex relative lg:rounded-[12px] justify-center px-[16px] py-[95px] lg:py-0   md:py-[45px]  items-center lg:h-[380px] h-full text-start text-white font-Rubik object-cover "
            style={{
              backgroundImage: `linear-gradient(-270deg, rgba(0,0,0,0.70), rgba(101,13,10,0.8) ), url(${get_connected_image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className=" absolute flex justify-end items-start  ml-[310px] md:-mt-[560px] -mt-[850px]  opacity-[55%] w-full ">
              <img src={side_pattern_pattern} alt="" className=" absolute" />
            </div>

            <div className=" absolute flex justify-start items-end md:mt-[560px] mt-[940px]  mr-[310px] opacity-[55%] w-full ">
              <img src={side_pattern_pattern} alt="" className=" absolute  " />
            </div>

            <div className="flex flex-col md:gap-[48px] gap-[20px] md:items-center items-start">
                <div className="md:text-[44px] leading-[42px] md:leading-normal text-[40px] font-medium italic">
                    <h2>Let’s Get Connected!</h2>
                </div>
             
                    <div className="flex text-[16px] lg:flex-row flex-col  md:items-center md:gap-[32px] gap-[20px]">
                        <div className="flex md:flex-row flex-col md:gap-[32px] gap-[20px]">

                          <Link to="https://www.facebook.com/p/Jaffna-Johnians-Sports-Club-100054252770222/">
                            <div className="flex md:flex-col  items-center md:gap-[6px] gap-[16px] ">
                                <img src={fb_icon} alt=""className="md:w-[64px] w-[52px]" />
                                <h3 className="">Jaffna Johnians Sports Club </h3>
                            </div>
                            </Link>
                         

                         <Link to='https://www.youtube.com/@JaffnaJohniansSportsClub'>
                            <div className="flex md:flex-col items-center md:px-[28px] md:gap-[6px] gap-[16px] ">
                                <img src={youtube_icon} alt=""className="md:w-[64px] w-[52px]" />
                                <h3>Jaffna Johnians Sports Club</h3>
                            </div>
                            </Link>

                            <Link to="https://x.com/johninansClub">
                            <div className="flex md:flex-col items-center md:gap-[6px] gap-[16px] ">
                                <img src={twitter_icon} alt=""className="md:w-[64px] w-[52px]" />
                                <h3>Jaffna Johnians Sports Club </h3>
                            </div>
                            </Link>
                        </div>

                      
                        <div className="flex md:flex-row flex-col md:gap-[32px] gap-[20px]">
                        <Link>
                    <div className="flex md:flex-col items-center gap-[6px] md:px-[28px] ">
                                <img src={phone_icon_icon} alt=""className="md:w-[64px] w-[52px]" />
                                <h3>077 845 8834 </h3>
                            </div>
                            </Link>

                            <Link to="mailto:sports@jaffnajohnians.com">
                            <div className="flex md:flex-col items-center md:gap-[6px] gap-[16px] ">
                                <img src={mail_icon} alt=""className="md:w-[64px] w-[52px]" />
                                <h3>
                                sports@jaffnajohnians.com </h3>
                            </div>
                            </Link>
                    </div>
                 
                   
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Aboutgetconnected;
