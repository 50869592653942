import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { match_pattern_pattern, playground_icon } from "../../Constants/Data";
import CricketIndividualSkeleton from "../../Components/Skeleton/Cricket/CricketIndividualSkeleton";

function MatchSummaryPage() {
  const { id } = useParams();
  const [matchData, setMatchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL_ONE_MATCH}/${id}`;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        if (data && data.success && data.data) {
          const matchData = data.data;
          setMatchData(matchData);
          setLoading(false);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div>
        <CricketIndividualSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-message">
        <p><CricketIndividualSkeleton /> </p>
      </div>
    );
  }

  const formatTime = (timeString, timeZone) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);

    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone,
    };
    return date.toLocaleTimeString("en-US", options);
  };

  const istTime = formatTime(matchData.time, "Asia/Kolkata");
  // console.log("fsdfsfdsfis time"+istTime);

  const gmtTime = formatTime(matchData.time, "GMT");

  const getShortenedName = (schoolName) => {
    const words = schoolName.split(" ");

    if (words.length > 1) {
      return words.map((word) => word[0].toUpperCase()).join("");
    } else {
      return schoolName;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month}  ${year}`;
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/team1_logo/";
  const imagePath1 = "/storage/team2_logo/";

  let statusClassName = "";
  let viewMatchSummaryClassName = "";
  let statusText = "";

  switch (matchData.status) {
    case "Recent":
      statusClassName = "bg-[#FFD4D2] text-[#9E120E]";
      statusText = "Match Ended";
      viewMatchSummaryClassName =
        "flex justify-center bg-red-gradient rounded-[8px] py-[12px] text-white uppercase text-[14px] font-medium";
      break;
    case "Upcoming":
      statusClassName = "bg-[#D4F1D2] text-[#006B2B]";
      statusText = "Match Yet to Begin";
      viewMatchSummaryClassName =
        "flex justify-center bg-green-gradient rounded-[8px] py-[12px] text-white uppercase text-[14px] font-medium";
      break;

    case "Live":
      statusClassName = "bg-[#F1F1F1] text-[#9E120E]";
      statusText = "LIVE";
      viewMatchSummaryClassName =
        "flex justify-center bg-[#B82222] rounded-[8px] py-[12px] text-white uppercase text-[14px] font-medium";
      break;
    default:
      break;
  }

  return (
    <>
      <div className="bg-header-gradient h-[144px] "></div>
      {/* <CricketIndividualSkeleton/> */}

      <div className="flex flex-col gap-[32px] font-Rubik md:pb-[90px] pb-[32px] ">
        {/* box content start  */}
        <div className="bg-[#EFEFEF] relative flex flex-col gap-[12px] md:px-[24px] px-[16px] py-[24px]">
          <div className="container mx-auto   ">
            <div className=" flex flex-col  gap-[12px]">
              <div className="flex   gap-[8px] items-center md:text-[18px] text-[14px]">
                <Link to='/'>
                <div className="flex  gap-[4px] items-center text-[#4D4D4D]">
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                </Link>
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  <h3>CRICKET</h3>
                  <FiArrowUpRight />
                </div>
                <h3 className="text-[#9E120E]  uppercase underline  leading-[20px]">
                  <h3 className="truncate md:w-[280px] w-[150px]">
                    {" "}
                    {matchData.match_name}
                  </h3>
                </h3>
              </div>
            </div>

            <h2 className="text-[#9E120E] uppercase md:text-[40px] text-[24px] font-medium italic">
              {matchData.match_name}
            </h2>
          </div>

          {/* box content end  */}
        </div>

        <div className="container mx-auto md:px-0 px-[16px]">
          <div className="bg-custom-gradient relative  border-[1px]  rounded-[12px]">
            <img
              src={match_pattern_pattern}
              alt=""
              className="absolute  opacity-80 rounded-t-[12px]"
            />
            <div className="relative flex flex-col gap-[24px] px-[20px] pt-[16px] pb-[32px] ">
              {/* match heading start */}
              <div className=" flex md:flex-row flex-col items-center md:items-start justify-between md:gap-0 gap-[12px] py-[12px] border-b-[1px]">
                <div className="flex flex-col gap-[8px]">
                  <h3 className="uppercase text-[#4D4D4D] font-medium md:text-[20px] text-[16px] tracking-[0.2px]">
                    {matchData.match_name}
                  </h3>

                  <span className="flex items-center text-[#2F2F2F] gap-[6px] capitalize md:text-[16px] text-[14px] ">
                    <img src={playground_icon} alt="" className="h-[16px]" />
                    <h4>{matchData.location}</h4>
                  </span>
                </div>

                <div className="flex md:flex-col flex-col-reverse gap-[16px] md:items-end w-full  md:w-auto ">
                  <h3
                    className={`${statusClassName} py-[8px] rounded-[8px] italic uppercase px-[20px] flex justify-center items-center gap-[8px] `}
                  >

{matchData.status === "Live" && (
            <div class="blobs-container">
              <div class="blob red"></div>
            </div>
)}
                    {statusText}
                  </h3>

                  {(matchData.status !== "Live") && (
                  <div className="flex  justify-center gap-[14px] text-[#2F2F2F] text-[14px] tracking-[0.2px]">
                    <div className="flex gap-[4px]">
                      <h2>
                        {new Date(matchData.date).toLocaleDateString("en-US", {
                          weekday: "long",
                        })}
                        ,{" "}
                      </h2>
                      <h2> {formatDate(matchData.date)}</h2>
                    </div>
                    <h2 className="text-[#3A225D]">{matchData.time}</h2>
                  </div>
                   )}
                </div>
                
              </div>
              {/* match heading end */}

              {/* match details start */}

              <div className="flex flex-col gap-[12px] items-center w-full">
                {matchData.status === "Recent" && (
                  <h2 className="text-[#232020] capitalize font-medium">
                    {matchData.winner_title}
                  </h2>
                )}

                <div className="bg-[#FFFFFF] md:h-[132px] h-[72px]  md:w-[726px] flex flex-col justify-center md:px-[16px] px-[4px]  py-[16px] rounded-[12px] border-[#0000000f] border-[1px] border-solid">
                  <div className="flex w-full uppercase text-[#4D4D4D] md:gap-[20px] text-[18px] items-center justify-center">
                    <div className="flex  gap-[10px] items-center">
                      <div className="justify-end flex flex-col items-end">
                        <h2
                          className={`${
                            matchData.status === "Upcoming"
                              ? "md:text-[48px] text-[24px] pl-[4px]"
                              : "md:text-[32px] text-[16px] font-medium"
                          }`}
                        >
                          {getShortenedName(matchData.team1_name)}
                        </h2>
                        {matchData.status === "Recent" && (
                          <div className="flex  font-Urbanist items-center  gap-[4px]">
                            <p className="text-[#9E120E]  flex   items-center lg:text-[36px] text-[20px] font-bold">
                              <span className="score">
                                {matchData.team1_score}
                              </span>
                              /
                              <span className="wickets">
                                {matchData.team1_wicket}
                              </span>
                            </p>
                            <span className="md:text-[20px] text-[12px] font-normal text-[#9E120E] overs">
                              ({matchData.team1_over})
                            </span>
                          </div>
                        )}
                      </div>
                      <div className="bg-white md:h-[100px] md:w-[100px] h-[40px] w-[40px] shadow-[#ed1c2332]  shadow-md rounded-full flex items-center justify-center border-solid border-[1px] border-[#DADADA]">
                        <img
                          src={
                            matchData.team1_logo
                              ? `${apiUrl}${imagePath}${matchData.team1_logo}`
                              : ""
                          }
                          alt=""
                          className="md:h-[84px] md:w-[84px]  rounded-full  object-cover"
                        />
                      </div>
                    </div>
                    <span className="text-[#A82929] md:text-[24px] text-[20px] md:bg-[#F3F3F3] rounded-full p-[12px] italic">
                      VS
                    </span>

                    <div className="flex gap-[10px] items-center">
                      <div className="bg-white md:h-[100px] md:w-[100px] h-[40px] w-[40px] shadow-[#ed1c2332]  shadow-md rounded-full flex items-center justify-center border-solid border-[1px] border-[#DADADA]">
                        <img
                          src={
                            matchData.team2_logo
                              ? `${apiUrl}${imagePath1}${matchData.team2_logo}`
                              : ""
                          }
                          alt=""
                          className="md:h-[84px] md:w-[84px]  rounded-full object-cover"
                        />
                      </div>
                      <div>
                        <h2
                          className={`${
                            matchData.status === "Upcoming"
                              ? "md:text-[48px] text-[24px] pr-[4px]"
                              : "md:text-[32px] text-[16px] font-medium"
                          }`}
                        >
                          {getShortenedName(matchData.team2_name)}
                        </h2>
                        {matchData.status === "Recent" && (
                          <div className="flex  gap-[4px] font-Urbanist items-center">
                            <p className="text-[#4D4D4D] flex  items-center md:text-[36px] text-[18px]  font-bold">
                              <span className="score">
                                {matchData.team2_score}
                              </span>
                              /
                              <span className="wickets">
                                {matchData.team2_wicket}
                              </span>
                            </p>
                            <span className="md:text-[20px] text-[12px] font-normal text-[#7E7E7E] overs">
                              ({matchData.team2_over})
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* match details end */}
              {/*top performers details start */}

              <div className="flex flex-col gap-[24px] uppercase">
                <div className={viewMatchSummaryClassName}>
                  {matchData.status === "Recent" && (
                    <h3 className="md:text-[14px] text-[12px]">
                      Player of the match : {matchData.player_of_match}
                    </h3>
                  )}
                  {matchData.status === "Upcoming" && (
                    <h3 className="md:text-[14px] text-[12px]">
                      {" "}
                      Match begins at {istTime} IST ({gmtTime} GMT){" "}
                    </h3>
                  )}

                  {matchData.status === "Live" && (
                    <h3 className="md:text-[14px] text-[12px]">
                     LIVE VIEW SCORE
                    </h3>
                  )}
                </div>
              </div>

              {/*top performers details end */}
            </div>
          </div>
        </div>
      </div>
      {(matchData.status === "Live" || matchData.status === "Recent") && (
        <div className="container mx-auto">
          <div className="   overflow-hidden flex items-center justify-center pb-[30px] ">
            <div className="lg:-mt-[215px] md:-mt-[120px] -mt-[215px] w-[800px] relative overflow-hidden  ">
              <iframe
                src="https://cricheroes.com/scorecard/11233270/allahabad-district-league-t10-season-2/marol-cc-vs-tunga-sports/summary"
                title="Match Summary: Marol CC vs Tunga Sports"
                frameborder="0"
                width="100%"
                scrolling="no"
                className="overflow-hidden  lg:h-[1750px]  h-[2800px]"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MatchSummaryPage;
