import React from "react";
import {
  committe_background,
  committe_image,
  pattern_bg,
} from "../Constants/Data";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
const committeeMembers = [
  { name: "Dr.G.Sritharan", role: "President", image: committe_image },
  { name: "Mr.R.Ratnam", role: "Secretary", image: committe_image },
  { name: "Dr.A.T.Gowribahan", role: "Vice Presidents", image: committe_image },
  { name: "Dr.S.Mathievaanan", role: "Vice Presidents", image: committe_image },
  { name: "Mr.P.Thomas", role: "Vice Presidents", image: committe_image },
  { name: "Mr.M.Ajanthan", role: "Treasurer", image: committe_image },
];

function CommitteeMembers() {
  return (
    <>
      {/* background patter */}
      <div className="w-full flex justify-end ">
        <img
          src={pattern_bg}
          alt=""
          className="absolute -z-10 md:h-[553px] lg:h-auto "
        />
      </div>

      <div className="py-[80px] md:px-[24px] px-[16px] h-full font-Rubik border-t-[#E5DADA]  border-[1px]  overflow-hidden relative">
        <div className="container mx-auto">
          <div className="flex flex-col ga[32px]">
        <div className="flex flex-col gap-[12px]">
              <div className=" flex flex-col gap-[12px] italic ">
                <h4 className="md:text-[32px] text-[20px]  leading-[24px] text-[#B72828]">
                  MEET OUR
                </h4>
                <h2 className="md:text-[48px] text-[28px]  font-medium text-[#A50808] ">
                  ADMINISTRATIVE COMMITTEE MEMBERS
                </h2>
              </div>
              <p className="text-[16px] text-[#525252]">
                It seems you're referring to the Johnians Sports Club. However,
                there isn't specific information available about a sports club
                with that name as of my last update in January 2022. It's
                possible that it's a local or regional sports club associated
                with a school, university, or community named Johnians. If you
                could provide more context or details, I'd be happy to assist
                further!
              </p>
            </div>
          <div className="committeemembers-contents flex flex-col md:gap-[32px] gap-[10px] relative overflow-x-auto highlights-scroll ">
            
            {/* mobile swiper */}
            <div className="md:hidden">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper flex justify-end l w-full h-[350px]   object-cover"
              >
                {committeeMembers.map((member, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="committee-card flex relative rounded-[8px] justify-end  items-end h-[310px] text-start text-white font-Rubik object-cover "
                      style={{
                        backgroundImage: `url(${committe_background})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    >
                      <img
                        src={member.image}
                        alt=""
                        className="committee-image h-[300px] rounded-br-[8px]"
                      />

                      <div className="committee-box z-10 w-full px-[16px] py-[8px] rounded-b-[8px] absolute ">
                        <h4 className="text-[#DBDBDB] italic font-light">
                          {member.role}
                        </h4>
                        <h3 className="font-medium text-[22px]">
                          {member.name}
                        </h3>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
{/* desktop grid */}
            <div className=" hidden md:flex  w-[1900px]   py-[32px]    gap-[28px]">
              {committeeMembers.map((member, index) => (
                <div
                  key={index}
                  className="committee-card flex relative w-[336px] rounded-[8px] md:justify-end justify-start items-end h-[310px]  text-start text-white font-Rubik object-cover "
                  style={{
                    backgroundImage: `url(${committe_background})`,

                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img
                    src={member.image}
                    alt=""
                    className=" committee-image h-[300px]  rounded-br-[8px] "
                  />

                  <div className="committee-box z-10 w-full px-[16px] py-[8px] rounded-b-[8px]  absolute ">
                    <h4 className="text-[#DBDBDB] italic font-light">
                      {" "}
                      {member.role}
                    </h4>
                    <h3 className="font-medium text-[22px] uppercase">{member.name}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default CommitteeMembers;
