import React from 'react'
import Contactus from '../Components/Contactus'

function Contactuspage() {
  return (
    <div className='h-full pb-[154px] '>
      <Contactus/>
    </div>
  )
}

export default Contactuspage