import React from "react";
import { IoPlayCircle } from "react-icons/io5";
import { player_card_bg } from "../../Constants/Data";
import Sharebutton from "../../Buttons/Sharebutton";
import { Link } from "react-router-dom";

function PlayerProfile({ player }) {
  if (!player) return null;
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/player/";
  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="flex flex-col  font-Rubik">
        <div className="flex text-[#B72828] italic font-medium  gap-[12px] items-center justify-center md:justify-start sports-news-heading">
          <IoPlayCircle className=" text-[26px] " />
          <h3 className="text-[24px]">PLAYERS</h3>
        </div>
        <div className="flex flex-col gap-[24px]">
          <div className="flex md:flex-row flex-col  gap-[22px] ">
            <div className="overflow-hidden md:w-[420px] flex flex-col justify-end md:h-[320px] h-[300px]">
              <div
                className=" md:w-[420px] h-[232px] flex justify-end items-end z-10 rounded-[12px] font-Rubik"
                style={{
                  backgroundImage: ` url(${player_card_bg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <img
                  src={player.image ? `${apiUrl}${imagePath}${player.image}` : ""}
                  alt={player.name}
                  className="md:-mr-[100px] md:-mb-[60px] h-[300px] md:h-[350px] relative rounded-b-[55px]"
                />
              </div>
            </div>
            <div className="flex flex-col md:justify-end md:items-start gap-[16px]">
              <div className="flex flex-col gap-[12px] items-start">
                <h3 className="bg-[#EFEFEF] flex text-[12px] justify-center text-[#980C0C] px-[16px] py-[8px] uppercase font-medium">
                  {player.position}
                </h3>
                <div className="text-[#212121] flex flex-col gap-[12px]">
                  <h3 className="uppercase flex gap-[8px] font-medium md:text-[28px] text-[20px]">
                    {player.lastname}
                  </h3>
                  <p className="text-[14px] md:w-[334px] font-light">
                    {truncateText(player.detail, 190)}
                  </p>
                </div>
              </div>
              <Link to={`/playerindividualpage/${player.id}-${player.lastname ? player.lastname.replace(/\s+/g, '') : '-'}`} onClick={handleClick}>
                <Sharebutton>
                  <div className="flex text-[14px] font-light md:items-start justify-center gap-[4px]">
                    <h4>VIEW PROFILE</h4>
                  </div>
                </Sharebutton>
              </Link>
            </div>
          </div>
          <div className="md:hidden flex border-[1px] rounded-[12px] justify-between py-[12px] bg-white text-[#3D3D3D] text-[18px] font-bold">
            <div className="flex flex-col gap-[10px] px-[8px] items-center">
              <h3 className="">{player.total_match}</h3>
              <h3 className="text-[10px] font-normal text-[#616161]">MATCHES</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[8px] items-center">
              <h3 className="">{player.total_run}</h3>
              <h3 className="text-[10px] font-normal text-[#616161]">RUNS</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[8px] items-center">
              <h3 className="">{player.total_wicket}</h3>
              <h3 className="text-[10px] font-normal text-[#616161]">WICKETS</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[8px] items-center">
              <h3 className="">{player.highest_score}</h3>
              <h3 className="text-[10px] font-normal text-[#616161]">HIGHEST SCORE</h3>
            </div>
          </div>
          <div className="md:flex hidden border-[1px] rounded-[12px] justify-between py-[12px] bg-white w-[420px] text-[#3D3D3D] text-[22px] font-bold">
            <div className="flex flex-col gap-[10px] px-[16px] items-center">
              <h3 className="">{player.total_match}</h3>
              <h3 className="text-[12px] font-normal text-[#616161]">MATCHES</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[16px] items-center">
              <h3 className="">{player.total_run}</h3>
              <h3 className="text-[12px] font-normal text-[#616161]">RUNS</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[16px] items-center">
              <h3 className="">{player.total_wicket}</h3>
              <h3 className="text-[12px] font-normal text-[#616161]">WICKETS</h3>
            </div>
            <div className="flex flex-col gap-[10px] px-[16px] items-center">
              <h3 className="">{player.highest_score}</h3>
              <h3 className="text-[12px] font-normal text-[#616161]">HIGHEST SCORE</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerProfile;
