import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import HighlightsCard from "../Cards/HighlightsCard";
import HighlightsModel from "./HighlightsModel";
import { white_calender } from "../../Constants/Data";

function truncateString(str, maxWords) {
  const words = str.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "";
  }
  return str;
}

function HighlightsMobileCarosel({ highlightsData,handleHighlightClick,modalOpen,selectedHighlightId, handleClose, nextItem, prevItem, updateCountNumber , currentItemIndex }) {

  const [selectedHighlight, setSelectedHighlight] = useState(null);

useEffect(() => {
  if (selectedHighlightId) {

    fetchHighlightDetails(selectedHighlightId);
  }
}, [selectedHighlightId]);

const fetchHighlightDetails = (highlightId) => {
 
  fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}${highlightId}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      setSelectedHighlight(data);
    })
    .catch((error) => {
      console.error("There was a problem with fetching highlight details:", error);
    });
};


  return (
    <>
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className=" flex justify-end l w-full h-[500px]  object-cover"
    >
       {highlightsData.map((highlight, index) => (
        <SwiperSlide key={highlight.id}>
          <HighlightsCard
            image={highlight.image}
            video_link={highlight.video_link}
            title={truncateString(highlight.title, 11)}
            heading={highlight.heading}
            date={highlight.date}
            handleHighlightClick={() => handleHighlightClick(highlight.id)}
            color="white"
          bgColor="#791817"
          dateColor="white"
          calenderIcon={white_calender}
          />
        </SwiperSlide>
      ))}
    </Swiper>

{modalOpen && selectedHighlight && (
  <HighlightsModel
    modalOpen={modalOpen}
    handleClose={handleClose}
    selectedHighlight={selectedHighlight}
    nextItem={nextItem}
    prevItem={prevItem}
    updateCountNumber={updateCountNumber}
    highlightsData={highlightsData}
    currentItemIndex={currentItemIndex}
    
  />
)}
</>
  );
}

export default HighlightsMobileCarosel;
