import React, { useEffect, useState } from "react";
import ShopCard from "../Cards/ShopCard";
import LargeButton from "../../Buttons/Largebutton";
import ShopViewAllSkeleton from "../Skeleton/Shop/ShopViewAllSkeleton";



function ShopViewAll({shopProducts}) {
  const [showAllCards, setShowAllCards] = useState(false);
  const [displayCount, setDisplayCount] = useState(2);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(8);
      } else if (windowWidth >= 1280 && windowWidth < 1540) {
        setDisplayCount(6);
      } else if (windowWidth >= 464 && windowWidth < 1280) {
        setDisplayCount(4);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (shopProducts && shopProducts.length ) {
      setLoading(false);
    }
  }, [shopProducts]);
  
  if (loading) {
    return <ShopViewAllSkeleton />;
  }


  const reversedshop = [...shopProducts].reverse();
  return (
    <>
  
      <div className="py-[108px] md:px-[24px] px-[16px] ">
        <div className="container mx-auto flex flex-col gap-[24px]">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]">
            {reversedshop.slice(0, displayCount).map((shop) => (
              <ShopCard
                key={shop.id}
                new_tag={shop.new}
                thumbnail_image={shop.thumbnail_image}
                product_category={shop.product_category}
                final_price={shop.final_price}
                price={shop.price}
                discount={shop.discount}
                name={shop.name}
                // sub_title={shop.subtitle}
                discount_status={shop.discount_status}
                handleshops={`/shopindividualpage/${shop.id}-${shop.subtitle.replace(/\s+/g, '')}`}
              />
            ))}
          </div>
         
          <div className="w-full flex md:flex-row flex-col  justify-center">
          {displayCount < shopProducts.length && (
            <LargeButton onClick={() => setShowAllCards(!showAllCards)}>
              <h3>{showAllCards ? "VIEW LESS" : "VIEW MORE"}</h3>
            </LargeButton>
          )}
          </div>
       
        </div>
      </div>
    </>
  );
}

export default ShopViewAll;
