import React from "react";
import { player_bg } from "../../../Constants/Data";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function PlayersLandingSkeleton() {
  return (
    <>
      <div
        className="w-full md:px-[24px] z-0 px-[16px]  flex flex-col rounded-[12px] text-white font-Rubik object-contain"
        style={{
          backgroundImage: `url(${player_bg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container mx-auto pt-[96px]">
          <div className=" flex items-center gap-[8px] ">
            <Skeleton width={30} borderRadius={30} height={30} />
            <Skeleton width={100} borderRadius={30} height={25} />
          </div>
          <div className="flex lg:flex-row flex-col gap-[22px]  pt-[100px]">
            <div className="flex md:flex-row flex-col gap-[20px]">
            <div className="flex  pb-[34px] flex-col">
              <span className="bg-[#dedede] rounded-[12px] md:w-[420px] h-[231px] mb-[24px]"></span>
              <span className="md:w-[420px] h-[75px] bg-white rounded-[12px] border-[1px] "></span>
            </div>
            <div>
              <div className="mb-[30px]">
                <Skeleton width={100} height={30} />
              </div>
              <div className="mt-[10px] md:w-[250px]">
                {" "}
                <Skeleton borderRadius={30} height={30} />
              </div>

              <div className="mt-[10px] lg:w-[300px]">
                {" "}
                <Skeleton count={3} borderRadius={30} height={10} />
              </div>
              <div className="mt-[10px] md:w-[200px]">
                {" "}
                <Skeleton borderRadius={30} height={30} />
              </div>
            </div>
            </div>
            
            <div className="flex flex-col  md:items-end w-full">
              <Skeleton width={100} borderRadius={30} height={30} />
              <div className="flex justify-end w-full md:gap-[12px] py-[34px]">
              <span className=" w-full md:flex rounded-[12px] h-[164px] bg-[#dedede]"></span>
              <span className="w-full hidden md:flex rounded-[12px] h-[164px] bg-[#dedede]"></span>
              <span className="w-full lg:hidden md:block hidden rounded-[12px] h-[164px] bg-[#dedede]"></span>
              </div>
              <div className="md:flex hidden gap-[16px] pt-[10px]">
              <Skeleton width={30} borderRadius={30} height={30} />
              <Skeleton width={30} borderRadius={30} height={30} />
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayersLandingSkeleton;
