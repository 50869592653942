import React, { useEffect, useRef, useState } from "react";
import FAQ from "../Components/FAQ";
import AboutWhychooseus from "../Components/AboutWhychooseus";
import Aboutherobanner from "../Components/Aboutherobanner";
import CommitteeMembers from "../Components/CommitteeMembers";
import AboutHistory from "../Components/AboutHistory";
import Aboutgetconnected from "../Components/Aboutgetconnected";
import AboutsPageSkeleton from "../Components/Skeleton/AboutsPageSkeleton";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function Aboutuspage() {
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();

  
  const historyRef = useRef(null);
  const committeeMembersRef = useRef(null);
  const whyChooseUsRef = useRef(null);
  const getConnectedRef = useRef(null);
  const faqRef = useRef(null);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    if (!isLoading) {
      const hash = location.hash;
      if (hash) {
        setTimeout(() => {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0); 
      }
    }
  }, [location, isLoading]);

  if (isLoading) {
    return <AboutsPageSkeleton />;
  }
  return (
    <>
      <Helmet>
        <title>Abouts</title>

        <meta charSet="utf-8" />

        <link rel="canonical" href="https://www.npmjs.com/package/react-helmet" />
      </Helmet>

      {/* <div  >
        <Aboutherobanner />
      </div> */}

      <div ref={historyRef} id="history">
        <AboutHistory  />
      </div>

      <div ref={committeeMembersRef} id="committeeMembers">
        <CommitteeMembers />
      </div>

      <div ref={whyChooseUsRef} id="whyChooseUs">
        <AboutWhychooseus  />
      </div>
      <div ref={getConnectedRef} id="getConnected">
        <Aboutgetconnected />
      </div>
      <div ref={faqRef} id="faq" className="h-full pb-[150px]">
        <FAQ />
      </div>
    </>
  );
}

export default Aboutuspage;
