import React, { useEffect, useState, useRef } from "react";
import { IoPlayCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import Smallbutton from "../../Buttons/Smallbutton";
import SportsNewsCard from "../Cards/SportsNewsCard";

function SportnewsRecommended({ idIndivitual }) {
  const [allSportsNews, setAllSportsNews] = useState([]);
  const [displayCount, setDisplayCount] = useState(2);
  const componentRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_NEWS;
      console.log(`API URL: ${apiUrl}`);

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched data:", data);

        const publishedNews = data.filter((news) => news.status === "published");
        console.log("Filtered published news:", publishedNews);

        setAllSportsNews(publishedNews);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));
    };

    fetchData();
  }, []);

  const filteredPlayers = allSportsNews.filter((player) => player.id !== idIndivitual);
  const sortedNews = filteredPlayers.slice().sort((a, b) => a.id - b.id);
  const reversedNews = sortedNews.slice().reverse();

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1280) {
        setDisplayCount(3);
      } else if (windowWidth >= 468 && windowWidth < 1280) {
        setDisplayCount(2);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (componentRef.current) {
      componentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  console.log("All sports news:", allSportsNews);

  return (
    <div className="md:py-[96px] py-[64px] md:px-[24px] px-[16px]">
      <div className="container mx-auto">
        <div className="flex flex-col gap-[16px] ">
          <div className="flex md:justify-between justify-center">
            <div className="text-[#A50808] text-[24px] italic font-medium flex gap-[12px] items-center">
              <IoPlayCircle className="text-[#A50808]" />
              <h3>LATEST NEWS</h3>
            </div>
            <Link to="/sportsnewsviewall" className="contact-component" onClick={handleClick}>
              <div className="md:pr-[30px] pr-0 md:flex hidden">
                <Smallbutton>
                  <h2>VIEW ALL</h2>
                </Smallbutton>
              </div>
            </Link>
          </div>
          <div></div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[24px]">
            {reversedNews.slice(0, displayCount).map((newsItem) => (
              <SportsNewsCard
                key={newsItem.id}
                youtubeThumbnail={newsItem.youtube_thumbnail}
                youtubeLink={newsItem.youtube_link}
                image={newsItem.image}
                title={newsItem.title}
                heading={newsItem.heading}
                publishedDate={newsItem.published_date}
                handleNewsClick={`/sportsnewsviewpage/${newsItem.id}-${newsItem.title ? newsItem.title.replace(/\s+/g, '') : '-'}`}
                onClick={handleClick}
              />
            ))}
          </div>
          <Link to="/sportsnewsviewall" onClick={handleClick}>
            <div className="md:pr-[30px] pr-0 md:hidden">
              <Smallbutton>
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SportnewsRecommended;
