import React, { useState, useEffect } from 'react';
import { IoPlayCircle } from 'react-icons/io5';
import PlayerCard from '../Cards/PlayerCard';
import { Link } from 'react-router-dom';
import LargeButton from '../../Buttons/Largebutton';

function OtherPlayers({ idIndivitual }) {
  const [displayCount, setDisplayCount] = useState(4);
  const [players, setAllSportsPlayers] = useState([]);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(4);
      } else if (windowWidth >= 1280 && windowWidth < 1540) {
        setDisplayCount(3);
      } else if (windowWidth >= 768 && windowWidth < 1280) {
        setDisplayCount(2);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_PLAYERS;

      try {
        console.log("Fetching data from:", apiUrl);
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data);

        if (data.success && Array.isArray(data.data)) {
          const playerData = data.data.map((item) => item.player);
          console.log("Parsed player data:", playerData);

          const publishedPlayers = playerData.filter((player) => player.status === "Published");
          console.log("Filtered published players:", publishedPlayers);

          setAllSportsPlayers(publishedPlayers);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredPlayers = players.filter((player) => player.id !== idIndivitual);
  const reversedPlayers = [...filteredPlayers].reverse();

  return (
    <>
      <div className='py-[64px] md:px-[24px] px-[16px]'>
        <div className='container mx-auto'>
          <div className='flex flex-col gap-[16px]'>
            <div className='flex justify-between'>
              <div className="text-[#A50808] text-[24px] italic font-medium flex gap-[12px] items-center">
                <IoPlayCircle className="text-[#A50808]" />
                <h3>OTHER PLAYERS</h3>
              </div>

              <Link to="/playerviewall" className='contact-component' onClick={handleClick}>
                <div className="md:flex hidden justify-end px-[40px]">
                  <LargeButton>
                    <h3>ALL PLAYERS</h3>
                  </LargeButton>
                </div>
              </Link>
            </div>
            {/* ----//---- card ----- // -----p-l-a-y-e-r */}
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
              {reversedPlayers.slice(0, displayCount).map((player) => (
                <PlayerCard
                  key={player.id}
                  name={player.lastname}
                  position={player.position}
                  image={player.image}
                  totalmatch={player.total_match}
                  run={player.total_run}
                  wicket={player.total_wicket}
                  highestscore={player.highest_score}
                  handleplayers={`/playerindividualpage/${player.id}-${player.lastname ? player.lastname.replace(/\s+/g, '') : '-'}`}
                />
              ))}
            </div>

            <Link to="/playerviewall">
              <div className="md:hidden px-[40px]">
                <LargeButton>
                  <h3>ALL PLAYERS</h3>
                </LargeButton>
              </div>
            </Link>
            {/* ----//---- player ----- // -----c-a-r-d */}
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherPlayers;
