import React from 'react'
import { GoArrowUp } from "react-icons/go";
function Smallbutton({ onClick, disabled, children, bgColor = '#9E120E', textColor = '#FFFFFF' }) {
  return (
    <div className=" group hover:rounded-[20px]   flex items-center justify-start ">
      <button
        className={`bg-[${bgColor}] text-[${textColor}]  bg-[#9E120E]  group-hover:bg-[#414040] md:relative flex md:flex-row  flex-row-reverse items-center justify-center md:w-[102px] w-full  h-[32px] rounded-[20px] px-6 md:px-0 text-white font-Rubik italic text-[14px] leading-[16px]`}
        onClick={onClick}
        disabled={disabled}
        style={{  color: textColor }}>
        <div className={`bg-[${bgColor}]  md:flex flex-col group-hover:bg-[#414040]  hidden overflow-hidden md:absolute relative md:ml-[130px] md:items-center items-end md:justify-center justify-end text-[24px] h-[32px] w-[32px] text-[${textColor}] text-white rounded-full bg-[#9E120E] -ml-[30px]`}  style={{  color: textColor }}>
          <ul className="menu rotate-45">
            <li>
              <a>
                <span>
                  <GoArrowUp className="hi-icon hi-icon-mobile" />
                </span>
                <span>
                  <GoArrowUp className="hi-icon hi-icon-mobile" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        {children}
      </button>

    </div>
  )
}

export default Smallbutton