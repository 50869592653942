import React from "react";
import { view_card_bg } from "../../Constants/Data";
import { Link } from "react-router-dom";

function PlayerCard({
  name,
  image,
  position,
  totalmatch,
  run,
  wicket,
  highestscore,
  roll,
  handleplayers
}) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/player/";
  return (
    <Link to={handleplayers}  style={{ textDecoration: "none" }} onClick={handleClick}>
    <div
      className="w-full card  relative flex justify-end  h-[340px]  z-10 rounded-[12px] overflow-hidden font-Rubik cursor-pointer"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),url(${view_card_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" flex flex-col group   justify-between w-full ">
        <div className="flex justify-start items-start w-full p-[12px]  ">
          {position && (
            <h3 className="uppercase bg-white text-[#980C0C] px-[16px] py-[8px] text-[12px] rounded-[8px] font-medium">
              {position}
            </h3>
          )}

          {roll && (
            <h3 className="uppercase bg-white text-[#980C0C] px-[16px] py-[8px] text-[12px] rounded-[8px] font-medium">
              {roll}
            </h3>
          )}
        </div>
        <div className="playercard   h-[205px] group-hover:h-[300px] flex slide slide2     pb-[12px] w-full  text-center    items-end md:justify-center ">
          <div className="flex flex-col gap-[8px] w-full px-[16px]">
            <h3 className=" w-full font-medium  text-white uppercase text-[16px] ">
              {name}
            </h3>
            {/* ---score --- details ---- */}
            <div className="w-full justify-between   flex border-[1px] border-[#ffffff47] text-white text-[22px] rounded-[8px] font-bold">
              <div className="flex playercard w-full  justify-center rounded-[12px]">
                <div className="flex flex-col gap-[10px]  px-[8px] items-center">
                  <h3 className="">{totalmatch}</h3>
                  <h3 className="text-[12px] font-normal ">MATCHES</h3>
                </div>

                <div className="flex flex-col gap-[10px] lg:px-[16px] md:px-[8px] px-[16px] items-center">
                  <h3 className="">{run}</h3>
                  <h3 className="text-[12px] font-normal ">RUNS</h3>
                </div>

                <div className="flex flex-col gap-[10px] px-[16px] items-center">
                  <h3 className="">{wicket}</h3>
                  <h3 className="text-[12px] font-normal ">WICKETS</h3>
                </div>

                <div className="flex flex-col gap-[10px]  px-[8px] items-center">
                  <h3 className="">{highestscore}</h3>
                  <h3 className="text-[12px] font-normal ">HIGH SCORE</h3>
                </div>
              </div>
            </div>
            {/* --- profile --- button */}

            <div className="bg-[#9E120E] slide slide2   rounded-[20px] text-[14px]  leading-[16px] px-[20px] py-[8px]">
              <button>
                <h5 className="italic">VIEW PROFILE</h5>
              </button>
            </div>
          
            {/* ---- button ----- profile ---- */}
          </div>

          {/* ----details ---- score ---- */}
        </div>
      </div>

      <img
            src={image ? `${apiUrl}${imagePath}${image}` : ""}
      alt="players_image"
        className="object-cover   player absolute h-[300px] hover-[200px] -z-20  mt-[10px] mr-[30px]  rounded-[12px] "
      />
    </div>
    </Link>
  );
}

export default PlayerCard;
