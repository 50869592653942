import React, { useEffect, useState } from 'react'
import { IoPlayCircle } from 'react-icons/io5'
import Smallbutton from '../../Buttons/Smallbutton'
import { Link } from 'react-router-dom'
import ShopCard from '../Cards/ShopCard';
import ShopCardCarosel from './ShopCardCarosel';
import BlogLandingSkeleton from '../Skeleton/Blog/BlogLandingSkeleton';

function ShopMain() {
  const [displayCount, setDisplayCount] = useState(2);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const [shopProducts, setShopProducts] = useState([]);
 
  useEffect(() => {
    const fetchShopProducts = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL_SHOPS);
        if (!response.ok) {
          throw new Error('Failed to fetch shop products');
        }
        const data = await response.json();
        if (data.success && Array.isArray(data.data)) {
          const publishedProducts = data.data.filter(product => product.status === "Published");
          setShopProducts(publishedProducts);
        } else {
          throw new Error('API returned success false or invalid data format');
        }
      } catch (error) {
        console.error('Error fetching shop products:', error);
      }
    };

    fetchShopProducts();
  }, []);
 

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(4);
      } else if (windowWidth >= 1280 && windowWidth < 1540) {
        setDisplayCount(3);
      } else if (windowWidth >= 464 && windowWidth < 1280) {
        setDisplayCount(2);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const reversedshop = [...shopProducts].reverse().slice(0,4);

  if (shopProducts.length === 0) {
    return (
      <div>
        <BlogLandingSkeleton />
      </div>
    );
  }
  return (
    <div className='md:py-[116px] py-[64px] md:px-[24px] px-[16px]'>
    <div className='container mx-auto  flex flex-col gap-[16px]'>
      <div className=''>
        <div className='flex justify-between pr-[30px]'>
          <div className="flex text-primary italic font-medium  gap-[12px] items-center sports-news-heading">
            <IoPlayCircle className=" text-[26px] " />
            <h3 className="md:text-[24px] text-[18px] capitalize">LATEST PRODUCT COLLECTION</h3>
          </div>

          <Link to ="/shopviewall" className='md:block hidden contact-component' onClick={scrollToTop}>
          <Smallbutton>
            <h2 className=''>VIEW ALL</h2>
          </Smallbutton>
          </Link>
        </div>
      </div>
      <div className='md:block hidden'>
      <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]">
      {reversedshop.slice(0,displayCount ).map((shop) => (
      <ShopCard
                key={shop.id}
                new_tag={shop.new}
                thumbnail_image={shop.thumbnail_image}
                product_category={shop.product_category}
                final_price={shop.final_price}
                price={shop.price}
                discount={shop.discount}
                name={shop.name}
                // sub_title={shop.subtitle}
                discount_status={shop.discount_status}
                handleshops={`/shopindividualpage/${shop.id}-${shop.subtitle.replace(/\s+/g, '')}`}
              />
            ))}
            </div>
      </div>

     
      <div className='md:hidden block'>

      <ShopCardCarosel/>
      </div>
    <Link to ="/shopviewall" className='md:hidden block ' onClick={scrollToTop}>
          <Smallbutton>
            <h2>VIEW ALL</h2>
          </Smallbutton>
          </Link>
          </div>
  
   

  </div>
  )
}

export default ShopMain