import React from 'react'

function Sharebutton({ onClick, disabled, children }) {
  return (
    <div className=" md:peer-hover:bg-[#414040]     flex items-center justify-start ">
      <button
        className="bg-[#959595] font-Rubik  w-full md:px-[20px] px-[12px] py-[8px] rounded-[20px] italic text-white md:text-[16px] text-[10px] "
        onClick={onClick}
        disabled={disabled}
      >
        
        {children}
      </button>

    </div>
  )
}

export default Sharebutton