import React, { useEffect, useRef, useState } from "react";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import { calender_icon, play_icon } from "../../Constants/Data";
import NewsSubSkelton from "../Skeleton/NewsSubSkelton";
import SocialShareButton from "../../Buttons/SocialShareButton";

function Sportsnewssub({ allSportsNews }) {
  const sortedNews = allSportsNews.slice().reverse();
  const limitedNews = sortedNews.slice(1, 4);
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/news/";
  const thumbnailPath = "/storage/youtube_thumbnail/";

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); 
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [openShareIndex, setOpenShareIndex] = useState(null);
  const componentRef = useRef(null);

  const toggleShareButtons = (index) => {
    setOpenShareIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const closeShareButtons = () => {
    setOpenShareIndex(null);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        closeShareButtons();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  if (sortedNews.length < 2) {
    return <NewsSubSkelton />;
  }

  return (
    <div className="flex flex-col font-Rubik gap-[24px]">
      {limitedNews.map((news, index) => {
        const pageUrl = `${window.location.origin}/sportsnewsviewpage/${news.id}-${news.title ? news.title.replace(/\s+/g, '') : '-'}`;

        return (
          <div key={index} className="flex flex-row">
            <div className="lg:w-[268px] md:w-[220px] w-full">
              {news.heading === "video" && news.youtube_link ? (
                <div
                  className="rounded-l-[12px] h-[184px] object-cover flex items-center justify-center"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${apiUrl}${thumbnailPath}${news.youtube_thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <img src={play_icon} alt="" className="w-[54px]" />
                </div>
              ) : (
                <img
                  src={news ? `${apiUrl}${imagePath}${news.image}` : ""}
                  alt=""
                  className="rounded-l-[12px] w-full lg:h-[184px] h-full object-cover"
                />
              )}
            </div>

            <div className="bg-white md:p-[20px] p-[12px] border-[1px] border-solid rounded-r-[12px] w-full flex flex-col">
              <div className="flex items-center text-[#D20000] w-full justify-between">
                <h3 className="px-[12px] py-[4px] md:w-[71px] w-[41px] md:text-[14px] text-[8px] flex justify-center bg-[#0000000f]">
                  {news.heading === "video" ? "Videos" : news.heading === "image" ? "Photos" : news.heading}
                </h3>
                <div className="relative">
                  <div
                    className="bg-[#F2F2F2] w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                    onClick={() => toggleShareButtons(index)}
                  >
                    <GoShareAndroid />
                  </div>
                  {openShareIndex === index && (
                    <div
                      className="absolute -top-1 -right-2 social-share visible"
                      ref={componentRef}
                    >
                      <SocialShareButton close={closeShareButtons} pageUrl={pageUrl} />
                    </div>
                  )}
                </div>
              </div>
              <Link to={`/sportsnewsviewpage/${news.id}-${news.title ? news.title.replace(/\s+/g, '') : '-'} `} style={{ textDecoration: "none" }} onClick={handleClick}>
                <div className="flex flex-col gap-[12px]">
                  <div className="flex gap-[10px] items-center">
                    <img src={calender_icon} alt="" />
                    <h3 className="md:text-[14px] text-[10px]">{formatDate(news.published_date)}</h3>
                  </div>
                  <h2 className="md:text-[16px] text-[12px] leading-[22px] text-[#212121] font-medium">
                    {news.title}
                  </h2>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Sportsnewssub;
