import React, { useEffect, useState } from "react";
// import { FaLink } from "react-icons/fa6";
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
  // IoIosShareAlt,
} from "react-icons/io";
import { IoClose } from "react-icons/io5";
import SocialShareButton from "../../Buttons/SocialShareButton";
// import { RiFacebookFill } from "react-icons/ri";

export default function HighlightsModel({
  prevItem,
  nextItem,
  highlightsData,
  handleClose,
  modalOpen,
  selectedHighlight,
  currentItemIndex,
  updateCountNumber,
}) {
  const closeModal = () => {
    handleClose();
  };

  const [, setHighlightDetails] = useState(null);

  useEffect(() => {
    if (modalOpen && selectedHighlight) {
      fetchHighlightDetails(selectedHighlight.id);
    }
  }, [modalOpen, selectedHighlight]);

  const fetchHighlightDetails = (highlightId) => {
    fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}${highlightId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setHighlightDetails(data);
      })
      .catch((error) => {
        console.error(
          "There was a problem with fetching highlight details:",
          error
        );
      });
  };

  if (!modalOpen || !selectedHighlight) {
    return null;
  }

  const getEmbedUrl = (videoUrl) => {
    if (!videoUrl) {
      console.error("videoUrl is undefined");
      return "";
    }

    let videoId = "";
    if (videoUrl.includes("youtube.com")) {
      const urlParams = new URLSearchParams(new URL(videoUrl).search);
      videoId = urlParams.get("v");
    } else if (videoUrl.includes("youtu.be")) {
      videoId = videoUrl.split("/").pop();
    }

    if (!videoId) {
      console.error("videoId is undefined");
      return "";
    }

    return `https://www.youtube.com/embed/${videoId}`;
  };

  const highlight = highlightsData[currentItemIndex];
  const embedUrl = highlight ? getEmbedUrl(highlight.video_link) : "";
  return (
    <div className="fixed scrollbar top-0 left-0 w-full h-screen font-Rubik bg-[#050505] bg-opacity-100 flex justify-center items-center z-50 px-[16px] overflow-x-scroll md:px-[24px]">
      <div className="container mx-auto w-full h-full ">
        <div className=" flex flex-col md:gap-[64px] gap-[48px] w-full">
          <button
            className="flex justify-end items-end w-full   p-[10px]  text-white text-2xl"
            onClick={closeModal}
          >
            <div className="flex gap-[12px] text-[18px] items-center border-[1px] border-solid border-[#ffffff19] px-[16px] py-[12px]">
              <h4>Close</h4>

              <IoClose />
            </div>
          </button>
          <div className="flex flex-col gap-[16px] justify-center items-center w-full">
            <div className="flex gap-[32px] w-full   ">
              <button
                className="  text-[#969595] text-[60px] hidden lg:block cursor-pointer"
                onClick={prevItem}
              >
                <IoIosArrowDropleft />
              </button>

              {highlight ? (
                <div className="w-full h-full  ">
                  <div className="flex flex-col gap-[16px] text-white font-medium md:text-[32px] text-[16px] leading-[26px  ">
                    {highlight.title}

                    <iframe
                      className=" w-full   md:h-[620px] h-[300px]"
                      src={`${embedUrl}?modestbranding=1&controls=1`}
                      frameBorder="8"
                      allowFullScreen
                      title="video"
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div>
              ) : (
                <p>Loading highlight details...</p>
              )}

              <button
                className="   text-[#969595] text-[60px] hidden lg:block  "
                onClick={nextItem}
              >
                <IoIosArrowDropright />
              </button>
            </div>

            <div className="flex justify-between w-full text-white">
              <div className="lg:hidden flex  gap-[8px] text-[20px] items-center  font-medium">
                <h3>{currentItemIndex + 1}</h3>
                <h3 className="text-[#817F7F] text-[16px] font-normal">of</h3>
                {/* <h3>{highlightDetails.length}</h3> */}
              </div>

              <div className="flex">
                <button
                  className="  text-[#969595] md:text-[60px] text-[36px] block lg:hidden cursor-pointer"
                  onClick={prevItem}
                >
                  <IoIosArrowDropleft />
                </button>

                <button
                  className="   text-[#969595] md:text-[60px] text-[36px] block lg:hidden  "
                  onClick={nextItem}
                >
                  <IoIosArrowDropright />
                </button>
              </div>
            </div>

            <div className="text-white pb-[30px]  lg:px-[100px] w-full flex justify-between">
              {/* share icons */}
              <div className="flex gap-[8px]  items-center ">
                {/* <div className="border-[1px] border-[#4D4D4D] rounded-full  p-[8px]">
                  <IoIosShareAlt />
                </div>
                <div className="border-[1px] border-[#4D4D4D] rounded-full  p-[8px]">
                  <FaLink />
                </div>
                <div className="border-[1px] border-[#4D4D4D] rounded-full  p-[8px]">
                  <RiFacebookFill />
                </div> */}

                <SocialShareButton className="hidden" bgColor="black" pageUrl={highlight.video_link} />
              </div>

              <div
                className="lg:flex hidden gap-[8px] text-[24px] items-center  font-medium"
                onClick={updateCountNumber}
              >
                <h3>{currentItemIndex + 1}</h3>
                <h3 className="text-[#817F7F] text-[20px] font-normal">of</h3>
                <h3>{highlightsData.length}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
