import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function BlogViewAllSkeleton() {
  const [cardCount, setCardCount] = useState(1);
  useEffect(() => {
    const updateCardCount = () => {
      const width = window.innerWidth;
      if (width >= 1280) {
        setCardCount(6);
      } else if (width >= 768) {
        setCardCount(4);
      } else {
        setCardCount(4);
      }
    };

    updateCardCount();
    window.addEventListener("resize", updateCardCount);

    return () => window.removeEventListener("resize", updateCardCount);
  }, []);
  return (
    <>
      <div className="h-[574px] md:px-[24px] px-[16px] flex  animate-pulse items-center relative z-10 bg-[#BDBDBD]">
        <div className="container mx-auto">
          <div className="md:w-[600px]">
            <Skeleton borderRadius={30} height={30} />
            <div className="md:w-[500px]">
              <Skeleton borderRadius={30} height={30} />
            </div>
          </div>
          <div className="lg:w-[800px] w-full mt-[10px]">
            <Skeleton count={2} borderRadius={30} height={15} />
          </div>

          <div className="w-[170px] mt-[10px]">
            <Skeleton borderRadius={30} height={40} />
          </div>
        </div>
      </div>

      <div className="py-[116px] md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex justify-between pb-[16px]">
            <div className=" flex items-center gap-[8px]">
              <Skeleton width={30} borderRadius={30} height={30} />
              <span className="md:w-[300px] w-[150px]">
                <Skeleton borderRadius={30} height={25} />
              </span>
            </div>
            <Skeleton width={100} borderRadius={30} height={25} />
          </div>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[20px]">
            {[...Array(cardCount)].map((_, index) => (
              <div className="  w-full rounded-[12px] border-[1px]    h-[560px]">
                <div className="h-[300px] animate-pulse rounded-t-[12px] bg-[#E6E6E6] "></div>
                <div className="px-[20px] py-[16px]">
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <Skeleton width={50} borderRadius={50} height={50} />
                      <div className="">
                        <Skeleton width={180} borderRadius={26} height={15} />

                        <Skeleton width={120} borderRadius={26} height={15} />
                      </div>
                    </div>
                    <Skeleton width={40} borderRadius={26} height={40} />
                  </div>

                  <div className="pt-[20px]  xl:w-[424px] gap-[10px]">
                    <Skeleton count={2} borderRadius={26} height={26} />
                  </div>

                  <div className="pt-[20px]   ">
                    <Skeleton count={3} borderRadius={26} height={10} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="py-[64px] md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex lg:flex-row flex-col w-full gap-[64px] ">
            <div className="flex flex-col w-full ">
              <span className="md:w-[400px]">
                <Skeleton borderRadius={50} height={30} />
              </span>
              <span className="md:w-[300px]">
                <Skeleton borderRadius={50} height={30} />
              </span>
              <div className="bg-[#d1d1d1] flex flex-col gap-[40px] py-[50px] px-[24px] h-[680px] mt-[48px] rounded-[12px]  animate-pulse">
                <span className="">
                  <Skeleton borderRadius={12} height={55} />
                </span>

                <span className="">
                  <Skeleton borderRadius={12} height={55} />
                </span>

                <span className="">
                  <Skeleton borderRadius={12} height={55} />
                </span>

                <span className="">
                  <Skeleton borderRadius={12} height={200} />
                </span>

                <span className="">
                  <Skeleton borderRadius={12} height={55} />
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-[24px] w-full justify-center">
                <div className="flex gap-[16px]">
                <span className="">
                <Skeleton width={25} borderRadius={30} height={25} />
              </span>
              <span className="md:w-[300px]  w-[150px]">
                <Skeleton borderRadius={30} height={25} />
              </span>
              
              </div>

              <div className="bg-[#F3F3F3] p-[16px] h-[180px] lg:w-[520px] rounded-[12px] mt-[16px]">
                
              <div className="flex justify-between items-center">
                    <div className="flex gap-[8px] items-center">
                      <Skeleton width={50} borderRadius={50} height={50} />
                      <div className="">
                        <Skeleton width={180} borderRadius={26} height={15} />

                        
                      </div>
                    </div>
                    <Skeleton width={40} borderRadius={26} height={40} />
                  </div>
                  <div className="mt-[16px]">
                <Skeleton count={2} borderRadius={30}/>
              </div>
              </div>

              <div className="bg-[#F3F3F3] p-[16px] h-[180px] lg:w-[520px] rounded-[12px] mt-[16px]">
                
                <div className="flex justify-between items-center">
                      <div className="flex gap-[8px] items-center">
                        <Skeleton width={50} borderRadius={50} height={50} />
                        <div className="">
                          <Skeleton width={180} borderRadius={26} height={15} />
  
                          
                        </div>
                      </div>
                      <Skeleton width={40} borderRadius={26} height={40} />
                    </div>
                    <div className="mt-[16px]">
                  <Skeleton count={2} borderRadius={30}/>
                </div>
                </div>

                <div className="bg-[#F3F3F3] p-[16px] h-[180px] lg:w-[520px] rounded-[12px] mt-[16px]">
                
                <div className="flex justify-between items-center">
                      <div className="flex gap-[8px] items-center">
                        <Skeleton width={50} borderRadius={50} height={50} />
                        <div className="">
                          <Skeleton width={180} borderRadius={26} height={15} />
  
                          
                        </div>
                      </div>
                      <Skeleton width={40} borderRadius={26} height={40} />
                    </div>
                    <div className="mt-[16px]">
                  <Skeleton count={2} borderRadius={30}/>
                </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogViewAllSkeleton;
