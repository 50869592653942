import React, { useEffect, useState } from "react";

function ShopIndividualMain({ shopProduct }) {
  const [thumbnail, setThumbnail] = useState(shopProduct.thumbnail_image);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setThumbnail(shopProduct.thumbnail_image);
    setActiveIndex(0); 
  }, [shopProduct]);

  const handleThumbnailClick = (image, index) => {
    setThumbnail(image);
    setActiveIndex(index);
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const thumbnailPath = "/storage/thumbnails/";
 
  const imagePath1 = "/storage/";
  const images = JSON.parse(shopProduct.images || "[]");

  return (
    <div className="flex flex-col gap-[24px]">
      <div className="flex p-[16px] justify-center rounded-[12px] lg:h-[548px] md:h-[692px] border-[1px]">
        {/*\\\\ product ---- details \\\ */}
        <div className="flex lg:flex-row flex-col-reverse items-center md:gap-[60px] gap-[32px]">
          <div className="flex lg:flex-col flex-row md:gap-[48px] gap-[16px]">
            <div
              className={`p-[12px] ${
                activeIndex === 0
                  ? "bg-[#E9E9E9] rounded-[12px] border-[1px] border-[#c700001f] border-r-[#4b4b4b24] "
                  : ""
              }`}
            >
              <img
                src={
                  shopProduct.thumbnail_image
                    ? `${apiUrl}${thumbnailPath}${shopProduct.thumbnail_image}`
                    : ""
                }
                alt="Product Thumbnail"
                className=" md:h-[116px] h-[80px] object-cover cursor-pointer"
                onClick={() =>
                  handleThumbnailClick(shopProduct.thumbnail_image, 0)
                }
              />
            </div>

            {images.length > 0 ? (
              images.slice(0, 2).map((image, index) => (
                <div
                  key={index + 1}
                  className={`p-[12px] ${
                    activeIndex === index + 1
                      ? "bg-[#E9E9E9] rounded-[12px] border-[1px] border-[#c700001f] border-r-[#4b4b4b24]"
                      : ""
                  }`}
                >
                  <img
                    src={image ? `${apiUrl}${imagePath1}${image}` : ""}
                    alt="Product"
                    className="w-full md:h-[116px] h-[80px] object-cover cursor-pointer"
                    onClick={() => handleThumbnailClick(image, index + 1)}
                  />
                </div>
              ))
            ) : (
             
              <p  className=" rounded-[12px] border-[1px] border-[#c700001f] border-r-[#4b4b4b24] bg-[#E9E9E9] md:h-[140px] h-[80px] object-cover cursor-pointer"></p>
            )}


          </div>
          <img
            src={
              thumbnail
                ? `${apiUrl}${activeIndex === 0 ? thumbnailPath : imagePath1}${thumbnail}`
                : ""
            }
            alt="Product Thumbnail"
            className="md:h-[460px] w-[400px] h-[390px] object-cover"
          />
        </div>
      </div>
      <div className="flex flex-col gap-[12px]">
        <div className="flex flex-col gap-[12px]">
          <div className="flex md:flex-row flex-col justify-between md:items-center items-start">
            <h4 className="text-[16px] font-medium uppercase">
              {shopProduct.type} {shopProduct.product_category}
            </h4>
            {shopProduct.discount_status !== "Unvisible" && (
            <div className="flex gap-[16px]">
           
              <div className="flex gap-[8px] items-center">
                <h5 className="text-[24px] font-medium leading-[150%]">
                  Rs.{shopProduct.final_price}
                </h5>
                <h5 className="text-[#2121216b] line-through">
                  Rs.{shopProduct.price}
                </h5>
              </div>
              <h5 className="text-[#006B2B] bg-[#C7FFDD] w-[99px] px-[16px] py-[8px] rounded-[8px]">
                {shopProduct.discount}% OFF
              </h5>
             
            </div>
               )}
{shopProduct.discount_status !== "Visible" && (
  <h5 className="text-[24px] font-medium leading-[150%]">
  Rs.{shopProduct.price}
</h5>

 )}
            
          </div>
          <p className="lg:w-[649px] md:text-[16px] text-[14px] font-light text-justify">
            {shopProduct.description}
          </p>
        </div>
        <h4 className="py-[8px] border-b-[1px] text-[16px] font-medium text-[#4D4D4D] border-[#C6C6C6]">
          AVAILABLE SIZES
        </h4>
        <div className="flex flex-wrap gap-[12px]">
        {shopProduct.size.split(",").map((size, index) => (
  <div
    key={index}
    className="px-[16px] py-[12px] border-[#00000077] rounded-[12px] uppercase text-[#9E120E] font-medium border-[1px] w-[80px] flex justify-center"
  >
    <h4>{size}</h4>
  </div>
))}
        </div>
      </div>
    </div>
  );
}

export default ShopIndividualMain;
