import React from 'react'
import { ceyloncetraders_logo, eagles_logo, foodlocate_logo, jaffna_stallions_logo, microwe_logo, shreediamond_logo, yazh_cheetahs_logo } from '../Constants/Data'

function OfficialPartners() {

 

  return (
    <div className='lg:h-[272px]  px-[16px] md:px-[24px] py-[65px] md:py-[92px] '>
        <div className='container mx-auto'>
<div className='  flex lg:flex-row flex-col items-center gap-[60px] w-full justify-between'> 
            <div className=' text-[#B72828] font-medium italic text-[32px]  '>
       <h2>OFFICIAL PARTNERS</h2>
            </div>
<div className='  lg:w-[1144px] w-full   '>
            <div className='marquee marquee--8   flex gap-[130px] justify-start    '>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b] md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={foodlocate_logo} alt="" className ="saturate-0" />
                </div>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={shreediamond_logo} alt=""  className ="md:h-[85px] h-[80px] saturate-0" />
                </div>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={ceyloncetraders_logo} alt="" className="md:h-[85px] h-[60px]  saturate-0" />
                </div>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={microwe_logo} alt="" className = "saturate-0" />
                </div>
                <div className= 'marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={eagles_logo} alt="" className = "saturate-0 md:h-[85px] h-[60px]  " />
                </div>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={yazh_cheetahs_logo} alt="" className ="md:h-[85px] h-[60px] saturate-0" />
                </div>
                <div className='marquee__item flex items-center justify-center border-[1px] border-[#b822223b]  md:w-[176px] w-[122px]  md:h-[88px] h-[70px] rounded-[8px]'>
                <img src={jaffna_stallions_logo} alt="" className ="saturate-0" />
                </div>

                


                </div>
            </div>
            </div>

        </div>

    </div>
  )
}

export default OfficialPartners