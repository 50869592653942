import React from "react";
import Skeleton from "react-loading-skeleton";

function NewsIndividualPageSkeleton() {
  return (
    <>

<div className="footer_bottom_box h-[144px]"></div>
      <div className="pt-[48px]  md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <span className="w-full">
            <Skeleton height={600} />
          </span>

          <div className="flex justify-end pt-[12px] gap-[12px]">
            <div className=" w-[167px]">
              <Skeleton height={36} borderRadius={45} />
            </div>
            <div className=" w-[167px]">
              <Skeleton height={36} borderRadius={45} />
            </div>
            <div className=" w-[167px]">
              <Skeleton height={36} borderRadius={45} />
            </div>
          </div>
          <div className="mt-[16px] w-full">
            <Skeleton height={36} borderRadius={45} />
            <div className="md:w-[584px] pt-[10px]">
              <Skeleton height={36} borderRadius={60} />
            </div>
          </div>

          <div className="mt-[24px]">
            <div className="md:w-[350px] pt-[10px]">
              <Skeleton borderRadius={60} height={30} />
            </div>
            <div className="pt-[10px] flex flex-col gap-[24px]">
                <div>
              <Skeleton count={2} borderRadius={20} />
              <div className="md:w-[300px]">
            <Skeleton  />
            </div>
            </div>
              <Skeleton count={2} borderRadius={20} />
              <div>
              <Skeleton count={2} borderRadius={20} />
              <div className="md:w-[400px]">
            <Skeleton  />
            </div>
            </div>
            </div>

            <div className="md:w-[350px] py-[10px]">
              <Skeleton borderRadius={60} height={30} />
            </div>

            <Skeleton count={2} />
            <div className="w-[300px]">
            <Skeleton  />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsIndividualPageSkeleton;
