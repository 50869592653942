import React, { useEffect, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import PlayerCard from "../../Components/Cards/PlayerCard";
import PlayersViewAllSkeleton from "../../Components/Skeleton/Players/PlayersViewAllSkeleton";

function PlayerViewAllPage() {
  const [players, setAllSportsPlayers] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_PLAYERS;

      try {
        console.log("Fetching data from:", apiUrl);
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data);
       

        if (data.success && Array.isArray(data.data)) {
          const playerData = data.data.map((item) => item.player);
          console.log("Parsed player data:", playerData);
          
          const publishedPlayers = playerData.filter((player) => player.status === "Published");
          console.log("Filtered published players:", publishedPlayers);
          

          setAllSportsPlayers(publishedPlayers);
        } else {
         

          throw new Error("Invalid response format");
          
        }
      
      } catch (error) {
       
        console.error("Error fetching data:", error);
        

      } finally {
        setLoading(false); 
       
      }
    };

    fetchData();
  }, []);

  if (players.status === "Unpublish") {
    return <div><PlayersViewAllSkeleton/></div>;
  }
  if (!players) {
    return <div>
           <PlayersViewAllSkeleton /> 
    </div>;
  }

  console.log("All sports players:", players);
  const batters = players.filter((player) => player.position === "Batter");
  const allRounders = players.filter((player) => player.position === "All Rounder");
  const bowlers = players.filter((player) => player.position === "Bowler");
  const wicketKeepers = players.filter((player) => player.position === "Wicket Keeper");
  const SupportStaff = players.filter((player) => player.position === "Support Staff");


  
  const renderPlayerSection = (title, playerList) => (
    playerList.length > 0 && (
    <div className="flex flex-col gap-[32px]">
      {loading ? (
        <PlayersViewAllSkeleton /> 
      ) : (
        <>
          <div>
            <h5 className="border-[1px] border-[#D7D7D7]"> </h5>
            <div className="flex items-center justify-center">
              <h2 className="font-medium px-[12px] py-[8px] uppercase -mt-[20px] rounded-[12px] text-[#9E120E] bg-[#FDE5E5]">
                {title.toUpperCase()}
              </h2>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {playerList.map((player) => (
              <PlayerCard
                key={player.id}
                name={player.lastname}
                roll={player.player_roll}
                image={player.image}
                totalmatch={player.total_match}
                run={player.total_run}
                wicket={player.total_wicket}
                highestscore={player.highest_score}
                handleplayers={`/playerindividualpage/${player.id}-${player.lastname ? player.lastname.replace(/\s+/g, '') : '-'}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
      )

  );

  return (
    <>
      <div className="bg-header-gradient h-[144px] z-10"></div>
      <div className="pt-[48px] pb-[108px] font-Rubik md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[48px]">
            <div className="flex flex-col gap-[12px]">
              <div className="flex gap-[8px] items-center text-[18px]">
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                <h3 className="text-[#9E120E] underline leading-[20px]">PLAYERS</h3>
              </div>
              <div className="text-[#A50808] italic lg:text-[36px] md:text-[28px] text-[16px] md:leading-[120%] leading-[180%]">
                <h3>FROM STARS TO RISING STARS</h3>
                <h2 className="font-medium lg:text-[48px] md:text-[40px] text-[28px]">MEET OUR ALL-STAR ROSTER</h2>
              </div>
            </div>
            
            {renderPlayerSection("Batters", batters)}
            {renderPlayerSection("All Rounders", allRounders)}
            {renderPlayerSection("Wicket Keepers", wicketKeepers)}
            {renderPlayerSection("Bowlers", bowlers)}
            {renderPlayerSection("Support Staff", SupportStaff)}
          </div>
        </div>
      </div>
    </>
  );
}

export default PlayerViewAllPage;
