import React, { useEffect, useState } from "react";
import { IoPlayCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import Sportsnewsposter from "./../SportsNews/Sportsnewsposter";
import Sportsnewsmain from "./../SportsNews/Sportsnewsmain";
import Sportsnewssub from "./../SportsNews/Sportsnewssub";
import Smallbutton from "../../Buttons/Smallbutton";
import { sport_pattern_bg } from "../../Constants/Data";


function Sportsnews() {
  const [allSportsNews, setAllSportsNews] = useState([]);

 

  useEffect(() => {
 
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_NEWS;
      console.log(`API URL: ${apiUrl}`);

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched data:", data);

        const publishedNews = data.filter(
          (news) => news.status === "published"
        );
        console.log("Filtered published news:", publishedNews);

        setAllSportsNews(publishedNews);
      } catch (error) {
        console.error("Error fetching data:", error);
    
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));
    };

    fetchData();
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
 
  

  console.log("All sports news:", allSportsNews);

  return (
    <>
      <div className="w-full flex justify-start -ml-[250px]">
        <img
          src={sport_pattern_bg}
          alt=""
          className="absolute -z-10 h-[553px]"
        />
      </div>
      <div className="pt-[75px] pb-[150px] px-[16px] md:px-[24px]">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[16px]">
            <div className="flex items-center justify-between">
              <div className="flex text-[#B72828] italic font-medium gap-[12px] items-center sports-news-heading">
                <IoPlayCircle className="text-[26px]" />
                <h3 className="text-[24px]">SPORTS NEWS</h3>
              </div>
              <Link to="/sportsnewsviewall" onClick={handleClick} className="contact-component">
                <div className="md:pr-[30px] pr-0 md:flex hidden" >
                  <Smallbutton>
                    <h2>VIEW ALL</h2>
                  </Smallbutton>
                </div>
              </Link>
            </div>
            <div className="flex lg:flex-row flex-col w-full  md:gap-[20px] gap-[12px]">
              
              <div>
                <Sportsnewsmain data={allSportsNews} />
              </div>

              <div className="flex w-full md:gap-[20px] gap-[12px] md:flex-row flex-col">
                <div className="w-full">
                  <Sportsnewssub allSportsNews={allSportsNews} />
                </div>
                <Link to="/sportsnewsviewall" onClick={handleClick}>
                  <div className="md:pr-[30px] pr-0 md:hidden">
                    <Smallbutton>
                      <h2>VIEW ALL</h2>
                    </Smallbutton>  
                  </div>
                </Link>
                <div>
                  <Sportsnewsposter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sportsnews;
