import React from "react";
import Whitebutton from "../../Buttons/Whitebutton";
import { Link } from "react-router-dom";
import { white_calender } from "../../Constants/Data";

function BlogHeroBanner({ sportsData }) {
  const reversedData = sportsData
    .slice()
    .sort((a, b) => a.id - b.id)
    .reverse();

  if (reversedData.length === 0) {
    return null;
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const blogDetails = reversedData[0].blog_details;

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath1 = "/storage/blogdetails/";

  const backgroundImageUrl = `${apiUrl}${imagePath1}${blogDetails.blog_bannerimage}`;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };
  return (
    <div
      className="flex px-[16px] md:px-[24px] justify-start pb-[64px] items-end text-white font-Rubik h-[560px]  w-full object-cover"
      style={{
        backgroundImage: `linear-gradient(-270deg, rgba(101,13,10,0.8), rgba(0,0,0,0.86) ),url(${backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container mx-auto md:px-[24px] px-[16px]">
        <div className="lg:w-[891px] flex flex-col gap-[16px]">
          <div className=" bg-[#e6e6e633] w-[141px] px-[12px] py-[4px]  md:relative flex md:flex-row flex-row-reverse items-center justify-center md:w-[141px] h-[32px] rounded-[8px] text-white font-Rubik italic font-medium border-[1px] border-solid border-[#ffffff41] text-[16px] leading-[16px]">
            <h3>LATEST BLOG</h3>
          </div>

          <div className="flex gap-[10px] items-center text-[#D6D6D6] font-light italic text-[14px]">
            <img src={white_calender} alt="Calendar Icon" />
            {formatDate(reversedData[0].date)}
          </div>

          <div>
            <h2 className="md:text-[36px] text-[24px] font-medium italic uppercase leading-[120%]">
              {blogDetails.blog_title}
            </h2>
            {/* <h3 className="font-light italic text-[18px] text-[#D6D6D6]">
              {blogDetails.blog_subtitles.length > 0 &&
                truncateText(
                  blogDetails.blog_subtitles[0].contents[0].description,
                  200
                )}
            </h3> */}
          </div>

          <div className="flex flex-col gap-[10px]">
            <div className="flex gap-[12px]">
              <Link
                to={`/blogindividualpage/${reversedData[0].id}-${
                  blogDetails && blogDetails.blog_title
                    ? blogDetails.blog_title.replace(/\s+/g, "")
                    : "-"
                }`}
                style={{ textDecoration: "none" }}
                onClick={scrollToTop}
              >
                <Whitebutton>READ MORE</Whitebutton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogHeroBanner;
