import React, { useEffect, useRef, useState } from "react";
import { FiArrowUpRight } from "react-icons/fi";
import { GoShareAndroid } from "react-icons/go";
import { white_calender } from "../../Constants/Data";
import { Link } from "react-router-dom";
import GalleryViewAllSkeleton from "../../Components/Skeleton/Gallery/GalleryViewAllSkeleton";
import SocialShareButton from "../../Buttons/SocialShareButton";

function GalleryViewAllPage() {
  const [galleryItems, setGalleryItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const componentRef = useRef(null);

  const closeShareButtons = () => {
    setShowShareButtons(false);
    setCurrentItem(null);
  };

  const toggleShareButtons = (item) => {
    if (currentItem && currentItem.id === item.id) {
      closeShareButtons();
    } else {
      setCurrentItem(item);
      setShowShareButtons(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetchGalleryData();
  }, []);

  const fetchGalleryData = () => {
    fetch(process.env.REACT_APP_API_URL_GALLERYS)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const publishedData = data.data.filter(item => item.status === "published");
        const modifiedItems = createGalleryLayout(publishedData);
        setGalleryItems(modifiedItems);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setLoading(true);
      });
  };

  if (loading) {
    return <GalleryViewAllSkeleton />;
  }

  const createGalleryLayout = (items) => {
    const reversedItems = items.slice().reverse();

    const modifiedLayout = [];
    for (let i = 0; i < reversedItems.length; i += 6) {
      const firstThreeItems = reversedItems.slice(i, i + 3);
      const nextThreeItems = reversedItems.slice(i + 3, i + 6);

      const modifiedFirstThreeItems = firstThreeItems.map((item, index) => ({
        ...item,
        gridLayout:
          index === 0
            ? "row-span-2 h-[500px] md:h-full"
            : "col-span-2 lg:h-[320px] md:h-[272px] h-[268px]",
      }));

      const modifiedNextThreeItems = nextThreeItems
        .map((item, index) => ({
          ...item,
          gridLayout:
            index === 1
              ? "row-span-2 h-[500px] md:h-full"
              : "col-span-2 lg:h-[320px] md:h-[272px] h-[268px]",
        }))
        .reverse();

      modifiedLayout.push(...modifiedFirstThreeItems, ...modifiedNextThreeItems);
    }
    return modifiedLayout;
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const thumbnailPath = "/storage/gallery_thumbnail/";

  return (
    <>
      <div className="footer_bottom_box h-[144px]"></div>
      <div className="pt-[48px]  relative  pb-[108px] md:px-[24px] px-[16px] font-Rubik">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[12px]">
              <div className="flex gap-[8px] items-center text-[18px]">
                <Link to="/">
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                </Link>
                <h3 className="text-[#9E120E] underline leading-[20px]">
                  GALLERY
                </h3>
              </div>

              <div className="italic text-[#A50808]">
                <h2 className="md:text-[36px] text-[24px] leading-[120%]">
                  SCOREBOARD MOMENTS
                </h2>
                <h2 className="md:text-[48px] text-[36px] font-medium italic leading-[120%]">
                  EXPLORE OUR ACTION-PACKED GALLERY
                </h2>
              </div>
            </div>
            <div className="flex">
              <div className="gallery grid md:grid-cols-3 grid-cols-1 w-full lg:gap-8 gap-4">
                {galleryItems.map((item, index) => {
                  const imagesCount = item.medias.reduce((count, media) => {
                    return count + (media.images ? JSON.parse(media.images).length : 0);
                  }, 0);
                  const videosCount = item.medias.reduce((count, media) => {
                    return count + (media.youtube_link ? 1 : 0);
                  }, 0);
                  return (
                    <div key={item.id} className={`gallery-item ${item.gridLayout}`}>
                                    <div
                className="h-full w-full  rounded-[12px] flex fle-col p-[24px] text-white font-Rubik object-cover"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),url(${apiUrl}${thumbnailPath}${item.gallery_thumbnail})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* gallery contents */}

                <Link
                  to={`/galleryindividualpage/${item.id}-${
                    item.title ? item.title.replace(/\s+/g, "") : "-"
                  }`}
                  onClick={scrollToTop}
                  className="w-full"
                >
                  <div className="gallery-contents w-full h-full flex flex-col justify-between">
                    <div className="flex items-center justify-between">
                      <div className="flex h-full gap-[4px] text-[14px]">
                        {videosCount > 0 && (
                          <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                            {videosCount} Videos
                          </h4>
                        )}

                        {imagesCount > 0 && (
                          <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                            {imagesCount} Photos
                          </h4>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center gap-[10px]">
                        <img src={white_calender} alt="" />
                        <h3>{item.published_date}</h3>
                      </div>
                      <h2 className="lg:w-[425px] capitalize lg:text-[20px] text-[16px] font-medium lg:leading-[28px] leading-[24px]">
                        {item.title}
                      </h2>
                    </div>
                  </div>
                </Link>
                <div className="flex   items-start w-fit justify-end">
                  <div
                    className="bg-[#FFFFFF]  cursor-pointer  absolute   w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                    onClick={() => toggleShareButtons(item)}
                  >
                    <GoShareAndroid />
                  </div>

                  {showShareButtons &&
                    currentItem &&
                    currentItem.id === item.id && (
                      <div
                        className={`absolute z-10 -mt-[10px] social-share visible`}
                        ref={componentRef}
                      >
                        <SocialShareButton
                          close={closeShareButtons}
                          pageUrl={`${
                            window.location.origin
                          }/galleryindividualpage/${currentItem.id}-${
                            currentItem.title
                              ? currentItem.title.replace(/\s+/g, "")
                              : "-"
                          }`}
                        />
                      </div>
                    )}
                </div>
              </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryViewAllPage;
