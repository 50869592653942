import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function ShopViewAllSkeleton() {
  const skeletonCards = Array(8).fill(0);
  return (
    <>
      <div className="py-[108px] md:px-[24px] px-[16px] ">
        <div className="container mx-auto flex flex-col gap-[24px]">
          <div className="grid mt-[16px] lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {skeletonCards.map((_, index) => (
              <>
                <div className="border-[1px] rounded-[12px]">
                  <div
                    key={index}
                    className=" h-[345px] rounded-t-[12px]  p-[20px] bg-[#c1c1c1] flex flex-col justify-end items-center animate-pulse"
                  ></div>
                  <div className="bg-white px-[16px] py-[12px] rounded-b-[12px] ">
                  <Skeleton width={63} height={25}  borderRadius={4} />
                  <div className="flex items-center my-[12px] w-full gap-[8px]">
                  <div className="w-full"><Skeleton   height={20}  borderRadius={4} /></div>
                  <div className="w-full"><Skeleton   height={20}  borderRadius={4} /></div>
                  <div className="w-full"><Skeleton   height={32}  borderRadius={4} /></div>
                
                 
                  </div>
                  <Skeleton  height={20}  borderRadius={12} />
                  </div>
                </div>
              </>
            ))}
          </div>
          <div className="flex justify-center " >
            <span className="w-[200px]"><Skeleton    height={30}  borderRadius={12} /></span></div>
        </div>
      </div>
    </>
  );
}

export default ShopViewAllSkeleton;
