import React from "react";


function ViewBanner() {
  return (
    <div
      className="view flex flex-col justify-between px-[16px] md:px-[24px]    py-[24px]   text-white font-Rubik md:h-[660px] h-[414px] w-full object-cover     "
      style={{
      
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h5 className="text-[#bd00004c] font-semibold text-[22px]">
        #ALWAYS PLAY THE GAME
      </h5>
      <div className="container lg:mx-auto md:pb-[150px] pb-[50px]">
        <div className="md:w-[585px] italic flex flex-col gap-[16px]">
          <h2 className="md:text-[40px] text-[28px] uppercase font-medium">
            Guru Gautam Gambhir's First Training Session at Eden Gardens
          </h2>
          <p className="capitalize md:text-[20px] text-[14px] text-justify font-light">
            Game Day, RCB v CSK, Preview: It’s a blockbuster opening to this new
            chapter in the RCB heritage. Coach, Captain and his Comrades are
            geared up, and keen to begin the season Bold!
          </p>
        </div>
      </div>
    </div>
  );
}

export default ViewBanner;
