import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ShopIndividualPageSkeleton() {
    const skeletonCards = Array(4).fill(0);
  return (
    <>
      <div className="bg-header-gradient h-[144px] z-10"></div>

      <div className="pt-[48px]  md:px-[24px] px-[16px] bg-white relative z-10 pb-[108px]">
        <div className="container mx-auto">
          <div className="flex lg:flex-row flex-col  w-full xl:gap-[240px] lg:gap-[60px]  justify-between">
            <div>
              <div className="h-[548px]   items-center border-[1px] p-[16px]  animate-pulse rounded-[12px] flex md:flex-row flex-col gap-[60px]">
                <div className="md:flex flex-col  hidden gap-[48px]">
                  <div className="w-[132px] bg-[#E9E9E9] h-[140px] rounded-[12px] border-[1px] border-[#c7000019]"></div>
                  <div className="w-[132px] bg-[#E9E9E9] h-[140px] rounded-[12px] "></div>
                  <div className="w-[132px] bg-[#E9E9E9] h-[140px] rounded-[12px] "></div>
                </div>
                <div className="w-[400px] md:block hidden h-[460px] bg-[#E9E9E9] rounded-[12px]"></div>
              </div>
              <div className="flex items-center my-[12px] w-full gap-[8px]">
                <div className="w-full">
                  <Skeleton height={20} borderRadius={4} />
                </div>
                <div className="w-full ">
                  <Skeleton height={20} borderRadius={4} />
                </div>
                <div className="w-full">
                  <Skeleton height={32} borderRadius={4} />
                </div>
              </div>
              <Skeleton height={20} count={3} borderRadius={12} />
              <div className="mt-[10px]">
              <Skeleton width={200} height={25} borderRadius={4} />
              <div className="border-[1px] mt-[10px]"></div>
              <div className="flex gap-[16px] mt-[10px]">
              <Skeleton width={66} height={45} borderRadius={12} />
              <Skeleton width={66} height={45} borderRadius={12} />
              <Skeleton width={66} height={45} borderRadius={12} />
              </div>
              </div>
            </div>
            <div className="w-full mt-[40px] lg:mt-0">
              <Skeleton width={62} height={20} borderRadius={12} />
              <div className="">
                <div className="  w-full">
                  <Skeleton height={20} count={2} borderRadius={12} />
                </div>

                <div className="bg-custom-gradient h-[708px] flex flex-col gap-[32px] rounded-[12px] mt-[10px] py-[32px] px-[24px]">
                <Skeleton height={55}  borderRadius={12} />
                <Skeleton height={55}  borderRadius={12} />
                <Skeleton height={91}  borderRadius={12} />
                <Skeleton height={200}  borderRadius={12} />
                <Skeleton height={55}  borderRadius={12} />
                </div>


              </div>
            </div>
           
          </div>
          <div className="py-[64px]">
               <div className="flex justify-between">
               <Skeleton width={150} height={20} borderRadius={12} />
               <Skeleton width={150} height={20} borderRadius={12} />
               </div>
               <div className="grid mt-[16px] lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {skeletonCards.map((_, index) => (
              <>
                <div className="border-[1px] rounded-[12px]">
                  <div
                    key={index}
                    className=" h-[345px] rounded-t-[12px]  p-[20px] bg-[#c1c1c1] flex flex-col justify-end items-center animate-pulse"
                  ></div>
                  <div className="bg-white px-[16px] py-[12px] rounded-b-[12px] ">
                  <Skeleton width={63} height={25}  borderRadius={4} />
                  <div className="flex items-center my-[12px] w-full gap-[8px]">
                  <div className="w-full"><Skeleton   height={20}  borderRadius={4} /></div>
                  <div className="w-full"><Skeleton   height={20}  borderRadius={4} /></div>
                  <div className="w-full"><Skeleton   height={32}  borderRadius={4} /></div>
                
                 
                  </div>
                  <Skeleton  height={20}  borderRadius={12} />
                  </div>
                </div>
              </>
            ))}
          </div>
                </div>
        </div>
      </div>
    </>
  );
}

export default ShopIndividualPageSkeleton;
