import React from "react";

import OfficialPartners from "../Components/OfficialPartners";
import Getmembershipposter from "../Components/Getmembershipposter";
import Sportsblog from "../Components/SportsBlog/Sportsblog";
import SportsGallery from "../Components/SportsGallery/SportsGallery";
import Highlights from "../Components/Highlights/Highlights";
import MatchScore from "../Components/MatchScore/MatchScore";
import Sportsnews from "../Components/SportsNews/Sportsnews";
import SportsPlayersMain from "../Components/SportsPlayers/SportsPlayersMain";
import ShopMain from "../Components/Shop/ShopMain";
import ViewBanner from "../Components/ViewBanner";
import Homebanner from "../Components/Homebanner";

function Homepage() {
  return (
    <>
      <div className="w-full   ">
      <Homebanner/>
      </div>

      <div>
        <MatchScore />
      </div>

      <div>
        <Highlights />
      </div>

 
      <div>
        <Sportsnews />
      </div>

      <div className="">
        <ViewBanner />
      </div>

      <div>
        <SportsPlayersMain />
      </div>

      <div>
        <Sportsblog />
      </div>

      <div>
        <SportsGallery />
      </div>

      <div>
        <ShopMain />
      </div>

      <div>
        <Getmembershipposter />
      </div>

      <div>
        <OfficialPartners />
      </div>
    </>
  );
}

export default Homepage;
