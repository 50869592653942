import React, { useEffect, useRef, useState } from "react";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import { calender_icon, play_icon } from "../../Constants/Data";
import SocialShareButton from "../../Buttons/SocialShareButton";

function SportsNewsCard({
  youtubeThumbnail,
  youtubeLink,
  title,
  heading,
  image,
  publishedDate,
  content,
  handleNewsClick,
}) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [showShareButtons, setShowShareButtons] = useState(false);
  const componentRef = useRef(null);

  const pageUrl = `${window.location.origin}${handleNewsClick}-${title ? title.replace(/\s+/g, '') : '-'}`;

  const toggleShareButtons = () => {
    setShowShareButtons((prevShowShareButtons) => !prevShowShareButtons);
  };

  const closeShareButtons = () => {
    setShowShareButtons(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        closeShareButtons();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/news/";
  const thumbnailPath = "/storage/youtube_thumbnail/";

  const headingText = heading === "video" ? "Videos" : heading === "image" ? "Photos" : heading;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month name
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };
  
  return (
    <div
      className="flex flex-col gap-[16px] border-[1px] border-solid border-[#2121212f] h-full rounded-[12px]"
      // onClick={handleClick}
    >
       
      {heading === "video" && youtubeLink ? (
        <div
          className="relative rounded-t-[12px] flex items-center justify-center h-[300px] px-4 md:px-0"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${apiUrl}${thumbnailPath}${youtubeThumbnail})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <img src={play_icon} alt="Play" className="w-[54px]" />
          <div className="absolute mt-7 -z-10">
            <iframe
              width="50"
              height="35"
              src={`${youtubeLink}&modestbranding=0&controls=0`}
              frameBorder="8"
              allowFullScreen
              title="video"
            ></iframe>
          </div>
        </div>
      ) : (
        <img
          src={image ? `${apiUrl}${imagePath}${image}` : ""}
          alt="News Thumbnail"
          className="rounded-t-[12px] h-[300px] object-cover"
        />
      )}
      <div className="px-[20px] flex flex-col gap-[20px] pb-[24px]">
        <div className="flex items-center justify-between w-full">
          <h3 className="py-[4px] px-[12px] w-[68px] text-[14px] capitalize justify-center h-[25px] flex items-center text-[#D20000] bg-[#00000025]">
          {headingText}
          </h3>
          <div className="relative">
          <div className="bg-[#B82222] w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-white justify-center" onClick={toggleShareButtons}>
            <GoShareAndroid />
           
          </div>
          {showShareButtons && (
          <div className={`absolute -top-1 -right-2 social-share ${showShareButtons ? 'visible' : 'hidden'}`} ref={componentRef} >
            <SocialShareButton close={closeShareButtons}
            pageUrl={pageUrl}
            // pageTitle={pageTitle}
            // pageImage={youtubeThumbnail}
            // imagePath1={imagePath1}
             />
          </div>
        )}
        </div>
        </div>

        <Link to={handleNewsClick} style={{ textDecoration: "none" }} onClick={handleClick}>
          <div className="flex flex-col gap-[12px]">
            <div className="flex gap-[10px] text-[14px] items-center">
              <img src={calender_icon} alt="Calendar Icon" />
              <h3> {formatDate(publishedDate)}</h3>
            </div>
            <h2 className="font-medium text-[24px] leading-[28px]">{truncateText(title,60)}</h2>
            <p className="text-[16px] leading-[22px]">
              {content}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SportsNewsCard;
