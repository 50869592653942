import React, { useRef, useState } from "react";
import { white_calender } from "../../Constants/Data";
import { GoShareAndroid } from "react-icons/go";
import { Link } from "react-router-dom";
import SocialShareButton from "../../Buttons/SocialShareButton";

function MemorableSportingEvents({ galleryData }) {
  const lastIndex = galleryData.length - 1;
  const limitedGalleryData = galleryData.slice(lastIndex - 1, lastIndex + 1);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const componentRef = useRef(null);

  const closeShareButtons = () => {
    setShowShareButtons(false);
    setCurrentItem(null);
  };

  const toggleShareButtons = (item) => {
    if (currentItem && currentItem.id === item.id) {
      closeShareButtons();
    } else {
      setCurrentItem(item);
      setShowShareButtons(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const thumbnailPath = "/storage/gallery_thumbnail/";

  return (
    <>
      <div className="flex gap-[24px]">
        {limitedGalleryData.reverse().map((item) => {
          const imagesCount = item.medias.reduce((count, media) => {
            return count + (media.images ? JSON.parse(media.images).length : 0);
          }, 0);

          const videosCount = item.medias.reduce((count, media) => {
            return count + (media.youtube_link ? 1 : 0);
          }, 0);

          return (
            <div key={item.id} className="gallery-item w-full">
              <div
                className="h-[262px] w-full  rounded-[12px] flex fle-col p-[24px] text-white font-Rubik object-cover"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)),url(${apiUrl}${thumbnailPath}${item.gallery_thumbnail})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                {/* gallery contents */}

                <Link
                  to={`/galleryindividualpage/${item.id}-${
                    item.title ? item.title.replace(/\s+/g, "") : "-"
                  }`}
                  onClick={scrollToTop}
                  className="w-full"
                >
                  <div className="gallery-contents w-full h-full flex flex-col justify-between">
                    <div className="flex items-center justify-between">
                      <div className="flex h-full gap-[4px] text-[14px]">
                        {videosCount > 0 && (
                          <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                            {videosCount} Videos
                          </h4>
                        )}

                        {imagesCount > 0 && (
                          <h4 className="bg-[#0000006d] px-[8px] py-[4px]">
                            {imagesCount} Photos
                          </h4>
                        )}
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center gap-[10px]">
                        <img src={white_calender} alt="" />
                        <h3>{item.published_date}</h3>
                      </div>
                      <h2 className="lg:w-[425px] capitalize lg:text-[20px] text-[16px] font-medium lg:leading-[28px] leading-[24px]">
                        {item.title}
                      </h2>
                    </div>
                  </div>
                </Link>
                <div className="flex   items-start w-fit justify-end">
                  <div
                    className="bg-[#FFFFFF]  cursor-pointer  absolute   w-[40px] h-[40px] rounded-full flex items-center text-[24px] text-[#9E120E] justify-center"
                    onClick={() => toggleShareButtons(item)}
                  >
                    <GoShareAndroid />
                  </div>

                  {showShareButtons &&
                    currentItem &&
                    currentItem.id === item.id && (
                      <div
                        className={`absolute z-10 -mt-[10px] social-share visible`}
                        ref={componentRef}
                      >
                        <SocialShareButton
                          close={closeShareButtons}
                          pageUrl={`${
                            window.location.origin
                          }/galleryindividualpage/${currentItem.id}-${
                            currentItem.title
                              ? currentItem.title.replace(/\s+/g, "")
                              : "-"
                          }`}
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default MemorableSportingEvents;
