import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import LargeButton from "../../Buttons/Largebutton";
import { players_background } from "../../Constants/Data";
import { Link } from "react-router-dom";

function SportsPlayers({ players, onPlayerClick }) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const swiperRef = useRef(null);

  const handleMoveLeft = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleMoveRight = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/player/";

  return (
    <>
      <div className="flex font-Rubik flex-col justify-end w-full h-full gap-[34px] md:pb-[56px] pb-[20px]">
        <Link to="playerviewall" className="contact-component " onClick={handleClick} >
          <div className="md:flex hidden  justify-end px-[40px]">
            <LargeButton>
              <h3>ALL PLAYERS</h3>
            </LargeButton>
          </div>
        </Link>

        <div className="relative flex">
          <Swiper
            ref={swiperRef}
            loop={true}
            slidesPerView={15}
            spaceBetween={150}
            breakpoints={{
              1536: {
                slidesPerView: 3,
                spaceBetween: 120,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 120,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 90,
              },
              0: {
                slidesPerView: 3,
                spaceBetween: 220,
              },
            }}
          >
            {players.map((player, index) => (
              <SwiperSlide key={index} className="flex">
                <div
                  className="w-[255px] relative h-[164px] flex justify-end z-10 rounded-[12px] overflow-hidden font-Rubik cursor-pointer"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),url(${players_background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  onClick={() => onPlayerClick(player)}
                >
                  <div className="flex flex-col justify-between w-full">
                    <div className="flex justify-start items-start w-full p-[12px]">
                      <h3 className="uppercase bg-white text-[#980C0C] px-[16px] py-[8px] text-[12px] rounded-[8px] font-medium">
                        {player.position}
                      </h3>
                    </div>
                    <div className="player_carosel_card  h-[99px] flex px-[16px] w-full text-center items-end justify-center">
                      <h3 className="text-white uppercase text-[16px] pb-[10px]">
                        {player.name}
                      </h3>
                    </div>
                  </div>
                  <img
                    src={
                      player.image ? `${apiUrl}${imagePath}${player.image}` : ""
                    }
                    alt={player.name}
                    className="object-cover absolute h-[200px] -z-20 -mr-[50px] mt-[10px] rounded-[12px]"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="absolute flex w-fit justify-end items-end m-auto bg-black left-0 right-0 mr-[30px] bottom-8">
            <button
              onClick={handleMoveLeft}
              className="swiper-button-prev group !p-2 flex border border-solid border-[#777777] !w-8 !h-8 transition-all duration-500 rounded-full !-translate-x-16"
            >
              <AiOutlineArrowLeft className="text-[#777777]" />
            </button>
            <button
              onClick={handleMoveRight}
              className="swiper-button-next group !p-2 flex mr-7 border border-solid border-[#777777] !w-8 !h-8 transition-all duration-500 rounded-full !translate-x-16"
            >
              <AiOutlineArrowRight className="text-[#777777]" />
            </button>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
      <Link to="playerviewall"  onClick={scrollToTop}>
        <div className="md:hidden justify-end px-[40px]" >
          <LargeButton>
            <h3>ALL PLAYERS</h3>
          </LargeButton>
        </div>
      </Link>
    </>
  );
}

export default SportsPlayers;
