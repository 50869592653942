import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logoConfig from "../Constants/Logoconfig";
import { menu_icon, trophy_icon } from "../Constants/Data";
import Mobilemenu from "./Mobilemenu";
import { Link } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import TapMobileMenu from "./MobileMenu/TapMobileMenu";

function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="header w-full md:h-[261px] h-[199px] absolute">
      <div className="container mx-auto px-[16px] md:pl-[24px] lg:pr-2 md:px-0">
        <div className="flex md:h-[150px] z-10 h-[120px] w-full items-center justify-between">
          <div className="z-30 flex gap-[12px] items-center">
            <Link to="/">
              <div className="md:h-[95px] md:w-[95px] w-[65px] h-[65px]">
                <img src={logoConfig.src} alt={logoConfig.alt} />
              </div>
            </Link>

            <div className="flex w-full gap-[4px] items-center md:pl-[8px] pl-[6px] border-solid border-l-2 border-white h-[40px]">
              <img src={trophy_icon} alt="" className="h-[38px] trophy-icon" />
              <div className="text-white font-Rubik font-medium md:text-[14px] text-[10px] leading-[20px]">
                <h3>Johnians Cricket Carnival</h3>
                <div className="flex gap-[8px]">
                  <h3>Champions</h3>
                  <h3>2023</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-[20px] z-30 items-center">
            <div className="bg-white lg:flex hidden w-full h-[51px] items-center text-[#9A181A] font-medium font-Rubik text-[16px] gap-[8px]">
              <div className="w-[132px] flex items-center justify-center">
                <h3>Membership</h3>
              </div>
              <Link to="/shopviewall"  >
                <div className="w-[79px] flex justify-center">
                  <h3>Shop</h3>
                </div>
              </Link>
<Link to="/sportsblogviewall">

              <div className="w-[79px] flex justify-center">
                <h3>Blog</h3>
              </div>
              </Link>
            </div>
            <div
              className="menu-box flex px-[16px]  py-[12px] gap-[12px] items-center cursor-pointer bg-[#050505a5] text-white md:text-[18px] text-[12px]"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? (
                <div className="flex items-center gap-[8px]">
                  <IoClose className="text-[24px]" />
                  <h4>Close</h4>
                </div>
              ) : (
                <div className="flex items-center gap-[8px] ">
                  <h4>Menu</h4>
                  <div className="md:w-[24px] md:h-[24px] w-[20px] h-[20px]">
                    <img src={menu_icon} alt="" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="lg:block hidden">
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
            >
              <Mobilemenu
                closeMobileMenu={() => setMobileMenuOpen(false)}
                isMobileMenuOpen={isMobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <div className="lg:hidden block bg-black">
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              
              <TapMobileMenu
                closeMobileMenu={() => setMobileMenuOpen(false)}
                isMobileMenuOpen={isMobileMenuOpen}
                toggleMobileMenu={toggleMobileMenu}
                
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Header;
