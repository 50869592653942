import React, { useEffect, useRef, useState } from "react";
import { GoShareAndroid } from "react-icons/go";
import { calender_icon, play_icon } from "../../Constants/Data";
import SocialShareButton from "../../Buttons/SocialShareButton";

function HighlightsCard({
  id,
  image,
  video_link,
  title,
  heading,
  date,
  cardWidth,
  boxWidth,
  handleHighlightClick,
  highlightId,
  textcolor = "#212121", // Default text color (black)
  dateColor = "#212121",
  color = "#D20000",
  bgColor = "transparent",
  calenderIcon = calender_icon,
}) {
  const handleClick = () => {

    handleHighlightClick(id);
  };

  const [showShareButtons, setShowShareButtons] = useState(false);
  const componentRef = useRef(null);
  const pageUrl = video_link;
  const pageTitle = title;
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };

  const toggleShareButtons = () => {
    setShowShareButtons((prevShowShareButtons) => !prevShowShareButtons);
  };

  const closeShareButtons = () => {
    setShowShareButtons(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        closeShareButtons();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/highlight/";
  
  return (
    <>
      <div
        className="flex   flex-col gap-[16px] border-[1px] border-solid border-[#2121212f] rounded-[12px]  "
        style={{ backgroundColor: bgColor }}>
        <div className="    h-[246px] " style={{ width: cardWidth }} >
          <div
            className="rounded-t-[12px] relative     flex  "
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${apiUrl}${imagePath}${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
              width: "100%",
            }}
            >
            <div className="h-full relative    flex justify-center items-center w-full"  onClick={handleClick} >
              <img src={play_icon} alt="" className="w-[54px]"  />
            </div>

            <div className="absolute mt-7  -z-10 hidden" >
              <iframe
                width="100%"
                height="100%"
                src={`${video_link}&modestbranding=0&controls=0`}
                frameBorder="8"
                allowFullScreen
                title="video"
              ></iframe>
            </div>

            <div className="absolute flex z-10  right-0 items-end  pr-[22px] mt-[20px]  h-full justify-end" >
            {showShareButtons && (
              <div className={`absolute -bottom-2 right-4 social-share ${showShareButtons ? "visible" : "hidden"}`} ref={componentRef}>
                <SocialShareButton close={closeShareButtons} pageUrl={pageUrl} pageTitle={pageTitle} />
              </div>
            )}
              <div className=" bg-[#B82222] w-[40px]    h-[40px]  rounded-full flex items-center text-[24px] text-white justify-center" onClick={toggleShareButtons}>
                <GoShareAndroid />
              </div>


            </div>
            
          </div>
          
        </div>
        <div
          className="flex flex-col   cursor-pointer gap-[20px] text-[14px]  text-black px-[20px] pb-[24px] "
          style={{ width: boxWidth }}
          onClick={handleClick}>
            
          <h3 className="flex justify-center  px-[12px] py-[4px]  bg-[#00000012] w-[68px]  " style={{ color: color }}>
            Video
          </h3>
          <div className="flex flex-col gap-[12px]" >
            <div className="flex gap-[10px] items-center">
            <img src={calenderIcon} alt="" />
            <h3 style={{ color: dateColor }}>{formatDate(date)}</h3>
            </div>
            <h2 className="font-medium text-[#212121] text-[20px] leading-[26px]"style={{ color: color }}>
              {title}...
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default HighlightsCard;
