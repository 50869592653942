import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function NewsSkeletonBanner() {
  return (
    <div className="h-[574px]  px-[24px] bg-[#BDBDBD] p-[16px] md:p-[24px] flex flex-col justify-end ">
      <div className="container mx-auto pb-[64px]">
        <div className="flex flex-col  gap-[10px] mb-[16px] ">
          <div className="md:w-[500px] w-[150px]">
          <Skeleton  height={30} borderRadius={20} />
          </div>
          <div className="md:w-[600px] w-[226px] ">
          <Skeleton  height={30} borderRadius={20} />
          </div>
        </div>

        <div className="flex flex-col  gap-[5px]  ">
          <span className="lg:w-[800px] md:w-[700px] w-full">
          <Skeleton  height={15} borderRadius={20} />
          </span>
          <span className="md:w-[463px] w-full">
          <Skeleton  height={15} borderRadius={20} />
          </span>
        </div>
        <div className="flex md:flex-row flex-col gap-[16px] pt-5">
          <span className=" bg-[#A0A0A0] md:w-[172px] h-[40px] rounded-[20px] animate-pulse "></span>
          <span className="md:w-[172px] bg-[#A0A0A0] h-[40px] rounded-[20px]  animate-pulse"></span>
        </div>
      </div>
    </div>
  );
}

export default NewsSkeletonBanner;
