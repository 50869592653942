import React, { useEffect, useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { Toaster, toast } from 'react-hot-toast'; 
function BlogRequestForm() {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    blogabout: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.success("Order submitted successfully!");
    const validationRules = {
      fullName: "Full Name is required",
      email: "Email is required",
      blogabout: "Blog Title is required",
      message: "Message is required",
    };


    
    console.log("formData:", formData);

    const newErrors = {};

    // Object.entries(validationRules).forEach(([field, rule]) => {
    //   if (!formData[field].trim()) {
    //     newErrors[field] = rule;
    //   } else if (
    //     field === "email" &&
    //     !/^\S+@\S+\.\S+$/.test(formData.email)
    //   ) {
    //     newErrors.email = "Enter a valid email address";
    //   }
    // });

    // setErrors(newErrors);



    

    // if (Object.keys(newErrors).length === 0) {
    //   try {
    //     setLoading(true);

    //     const response = await new Promise((resolve) =>
    //       setTimeout(() => resolve({ status: 200 }), 1000)
    //     );


    Object.entries(validationRules).forEach(([field, rule]) => {
      if (!formData[field].trim()) {
        newErrors[field] = rule;
      } else if (field === "email" && !/^\S+@\S+\.\S+$/.test(formData.email)) {
        newErrors.email = "Enter a valid email address";
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.REACT_API_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (response.status === 200) {
          setFormData({
            fullName: "",
            email: "",
            blogabout: "",
            message: "",
          });
          setSubmitted(true);
        }
      }
      catch (error) {
        console.error("There was an error submitting the form:", error);
        
        toast.error("There was an error submitting the form.");
      }
      
      finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [submitted]);

  return (
    <>
    <Toaster
  position="bottom-center"
  reverseOrder={false}
/>
  
    <div className="flex flex-col gap-[16px] font-Rubik lg:w-[823px] ">
      <div className="text-[#A50808] md:w-[438px] w-[292px]">
        <h2 className="md:text-[36px] text-[20px] leading-[150%]">
          CREATE YOUR AMAZING{" "}
          <span className="md:text-[48px] text-[32px] font-medium italic">
         
            OWN BLOGS HERE !
          </span>{" "}
        </h2>
      </div>

      <div>
        <form className="bg-[#E8E8E8] p-[12px] rounded-[12px]"  onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-[22px] ">
            <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
              <div className="flex  justify-between px-[4px]">
                <label>FULL NAME</label>
              </div>
              <input
                type="text"
                name="fullName"
                placeholder="Enter Name"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
                className={`input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black    focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px] ${
                  errors.fullName ? "border-red-500 " : ""
                }`}
              />
              {errors.fullName && (
                <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                  {errors.fullName}
                  <IoIosWarning />
                </span>
              )}
            </div>

            <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
              <div className="flex justify-between px-[4px]">
                <label>EMAIL ADDRESS </label>
              </div>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Enter email address"
                className={`input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black    focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px]  ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email && (
                <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                  {errors.email}
                  <IoIosWarning />
                </span>
              )}
            </div>

            <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]   ">
              <div className="flex  justify-between px-[4px]">
                <label>BLOG ABOUT</label>
              </div>
              <input
                type="text"
                name="blogabout"
                placeholder="Enter title"
                value={formData.blogabout}
                onChange={(e) =>
                  setFormData({ ...formData, blogabout: e.target.value })
                }
                className={` input bg-[#ffffff]  text-[14px]  text-[#4D4D4D] border-b px-[16px] pl-[16px]   outline-none   lining-nums  placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6]    focus:placeholder-[#F6F6F6] valid:before:bg-black    focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px]  rounded-[12px] ${
                  errors.blogabout ? "border-red-500 " : ""
                }`}
              />
              {errors.blogabout && (
                <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                  {errors.blogabout}
                  <IoIosWarning />
                </span>
              )}
            </div>

            <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px] ">
              <div className="flex justify-between px-[4px]">
                <label>MESSAGE</label>
              </div>
              <textarea
                name="message"
                rows={6}
                id="message"
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                className={`bg-[#ffffff] rounded-[12px]  text-[14px] font-medium text-[#4D4D4D]  px-[16px] pl-[16px]  outline-none   lining-nums  placeholder-[#939191] placeholder:font-medium placeholder:text-[16px] h-[200px] hover:bg-[#F6F6F6] pt-[16px]   focus:placeholder-[#F6F6F6] valid:before:bg-black    placeholder-shown:bg-[#ffffff] border-[1px] focus:bg-[#F6F6F6]   ${
                  errors.message ? "border-red-500" : ""
                }`}
                placeholder="Type something here..."
              ></textarea>
              {errors.message && (
                <span className="flex gap-[4px] items-center text-sm text-red-700  ">
                  {errors.message}
                  <IoIosWarning />
                </span>
              )}
            </div>

            <button
              className={`h-[60px] text-[18px] text-white uppercase flex items-center justify-center ${
                loading
                  ? "bg-[#5D0F0C]"
                  : submitted
                  ? "bg-black"
                  : "bg-[#9E120E] rounded-[8px] hover:bg-[#7E100C]"
              }`}
              disabled={loading}
              onClick={handleSubmit}
              style={{ position: "relative" }}
            >
              {loading && (
                <div className="absolute flex items-center ml-[20px]">
                  <div className="loader"></div>
                </div>
              )}
              {!loading && (
                <>
                  {submitted && (
                    <RiCheckboxCircleFill className="w-6 h-6 text-white mr-2" />
                  )}
                  {submitted ? "Submitted" : "REQUEST FOR WRITE"}
                </>
              )}
              {loading && !submitted && (
                <span className="ml-2">Sending</span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
    </>
  );
}

export default BlogRequestForm;
