import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function AboutsPageSkeleton() {
  const skeletonCards = Array.from({ length: 4 });
  return (
    <>
      {/* hero */}

      <div className="h-[560px] md:px-[24px] px-[16px] flex flex-col justify-center gap-[16px] items-center bg-[#b7b6b6] z-10 animate-pulse relative ">
        <span className="md:w-[413px] w-full">
          <Skeleton height={60} borderRadius={80} />
        </span>
        <span className="md:w-[555px] w-full">
          <Skeleton height={25} borderRadius={40} />
        </span>
      </div>

      {/*  */}
      <div className="container mx-auto">
        {/* about second content */}
        <div className="lg:py-[95px] py-[32px]  md:px-[24px] px-[16px]">
          <div className="lg:flex-row flex-col flex w-full lg:items-center gap-[30px]  justify-between ">
            <div className="">
              <div className="md:w-[378px] pb-[30px]">
                <Skeleton count={2} height={30} borderRadius={40} />
              </div>
              <div className="md:w-[622px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[500px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[590px] pt-[10px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[400px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
            </div>
            <span className="h-[421px]  rounded-[12px] bg-[#E6E6E6] animate-pulse md:w-[681px] w-full"></span>
          </div>

          <div className="lg:flex-row flex-col-reverse pt-[20px] flex w-full lg:items-center pb-[20px] gap-[30px] justify-between ">
            <span className="h-[421px] rounded-[12px] bg-[#E6E6E6] animate-pulse md:w-[681px] w-full"></span>
            <div className="">
              <div className="w-[300px] pb-[30px]">
                <Skeleton count={1} height={30} borderRadius={40} />
              </div>
              <div className="md:w-[622px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[500px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[590px] pt-[10px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
              <div className="md:w-[400px]">
                <Skeleton count={2} height={14} borderRadius={40} />
              </div>
            </div>
          </div>
        </div>

        {/* card */}

        <div className="lg:py-[80px] py-[40px] md:px-[24px] px-[16px]">
          <div className="w-[148px]">
            <Skeleton height={30} borderRadius={40} />
          </div>
          <div className="pt-[10px] lg:w-[932px]">
            <Skeleton height={60} borderRadius={40} />
          </div>
          <div className="pt-[20px] ">
            <Skeleton count={2} height={14} borderRadius={40} />
            {/* grid card */}
          </div>
          <div className="pt-[30px] md:grid hidden lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
            {skeletonCards.map((_, index) => (
              <div
                key={index}
                className="bg-[#E6E6E6] flex flex-col justify-end rounded-[8px] animate-pulse h-[328px] "
              >
                <div className="bg-[#D1D1D1] h-[60px] px-[16px] pt-[10px] rounded-b-[8px]">
                  <div className="w-[50px] ">
                    <Skeleton height={14} borderRadius={40} />
                  </div>
                  <div className="w-[80px]">
                    <Skeleton height={14} borderRadius={40} />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="bg-[#E6E6E6]  md:hidden flex flex-col justify-end rounded-[8px] animate-pulse h-[328px] ">
            <div className="bg-[#D1D1D1]  h-[60px] px-[16px] pt-[10px] rounded-b-[8px]">
              <div className="w-[50px] ">
                <Skeleton height={14} borderRadius={40} />
              </div>
              <div className="w-[80px]">
                <Skeleton height={14} borderRadius={40} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row justify-center flex-col w-full py-[108px] md:px-[24px] md:gap-[72px]  px-[16px]">
          <div className="bg-[#E6E6E6] lg:block hidden animate-pulse rounded-[12px] h-[577px] w-[488px]"></div>
          <div>
            <Skeleton width={191} height={14} borderRadius={40} />
            <div className="md:w-[512px] w-full">
              <Skeleton height={42} borderRadius={40} />
            </div>
            <div className="md:w-[660px] w-full pt-[10px]">
              <Skeleton height={42} borderRadius={40} />
            </div>
            <div className="flex gap-[12px] pt-[20px]">
              <Skeleton width={105} height={32} borderRadius={40} />
              <Skeleton width={105} height={32} borderRadius={40} />
              <Skeleton width={105} height={32} borderRadius={40} />
            </div>

            <div className="pt-[32px]">
              <div className="flex gap-[50px]">
                <Skeleton width={30} height={30} borderRadius={40} />

                <div className="w-full">
                  <div className="md:w-[300px]">
                    <Skeleton height={32} borderRadius={40} />
                  </div>
                  <div className="pt-[10px]">
                    <Skeleton count={2} borderRadius={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-[32px]">
              <div className="flex gap-[50px]">
                <Skeleton width={30} height={30} borderRadius={40} />

                <div className="w-full">
                  <div className="md:w-[300px]">
                    <Skeleton height={32} borderRadius={40} />
                  </div>
                  <div className="pt-[10px]">
                    <Skeleton count={2} borderRadius={40} />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-[32px]">
              <div className="flex gap-[50px]">
                <Skeleton width={30} height={30} borderRadius={40} />

                <div className="w-full">
                  <div className="md:w-[300px]">
                    <Skeleton height={32} borderRadius={40} />
                  </div>
                  <div className="pt-[10px]">
                    <Skeleton count={2} borderRadius={40} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* fund */}

        <div className="md:py-[100px] py-[50px] md:px-[24px]  px-[16px] ">
          <div className="flex gap-[12px] ">
            <Skeleton width={30} height={30} borderRadius={40} />
            <Skeleton width={147} height={26} borderRadius={40} />
          </div>
          <div className="flex gap-[32px] w-full pt-[20px] ">
            <div className="bg-[#d0cfcf] w-full h-[226px] flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
            <div className="bg-[#d0cfcf] w-full h-[226px] hidden md:flex flex-col p-[24px]  justify-between  rounded-[8px] animate-pulse  ">
              <div className="flex justify-between">
                <Skeleton width={100} height={26} borderRadius={40} />
                <Skeleton width={30} height={30} borderRadius={40} />
              </div>
              <div>
                <Skeleton count={3} height={10} borderRadius={40} />
              </div>
            </div>
          </div>
        </div>

        <div className="md:py-[64px] py-[32px] md:px-[24px] px-[16px]">
          <div className="bg-[#C3C3C3] px-[16px] flex flex-col justify-center items-center h-[380px] animate-pulse rounded-[12px]">
            <div className="md:w-[443px] w-full  ">
              <Skeleton height={40} borderRadius={40} />
            </div>
            <div className="flex lg:flex-row gap-[32px] flex-col">
              <div className="flex   gap-[32px]">
                <div className="pt-[20px] flex flex-col  items-center">
                  <Skeleton width={64} height={64} borderRadius={40} />
                  <Skeleton width={120} height={10} borderRadius={40} />
                </div>
                <div className="pt-[20px] md:flex hidden flex-col  items-center">
                  <Skeleton width={64} height={64} borderRadius={40} />
                  <Skeleton width={120} height={10} borderRadius={40} />
                </div>

                <div className="pt-[20px] flex flex-col  items-center">
                  <Skeleton width={64} height={64} borderRadius={40} />
                  <Skeleton width={120} height={10} borderRadius={40} />
                </div>
              </div>
              <div className="flex justify-center gap-[32px]">
                <div className="pt-[20px] flex flex-col  items-center">
                  <Skeleton width={64} height={64} borderRadius={40} />
                  <Skeleton width={120} height={10} borderRadius={40} />
                </div>

                <div className="pt-[20px] flex flex-col  items-center">
                  <Skeleton width={64} height={64} borderRadius={40} />
                  <Skeleton width={120} height={10} borderRadius={40} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-[108px] md:px-[24px] px-[16px]">
          <div className="flex lg:flex-row flex-col gap-[48px] ">
            <div>
              <div className="md:w-[413px]">
                <Skeleton height={42} borderRadius={40} />
              </div>
              <div className="md:w-[300px]">
                <Skeleton height={42} borderRadius={40} />
              </div>
              <div className="flex gap-[12px] pt-[20px]">
                <Skeleton width={105} height={32} borderRadius={40} />
                <Skeleton width={105} height={32} borderRadius={40} />
                <Skeleton width={105} height={32} borderRadius={40} />
              </div>
            </div>
            <div className=" flex flex-col w-full">
              <div className="flex ">
                <div className=" w-full  h-[60px]">
                  <Skeleton count={1} height={64} borderRadius={40} />
                </div>
                <Skeleton width={64} height={64} borderRadius={40} />
              </div>
              <div className="pt-[20px] px-[16px] md:px-[24px] md:w-[600px]">
                <Skeleton count={2} height={10} borderRadius={40} />
              </div>

              <div className="flex  pt-4">
                <div className=" w-full  ">
                  <Skeleton count={1} height={60} borderRadius={40} />
                </div>
                <Skeleton width={64} height={60} borderRadius={40} />
              </div>

              <div className="flex  pt-4">
                <div className=" w-full  ">
                  <Skeleton count={1} height={60} borderRadius={40} />
                </div>
                <Skeleton width={64} height={60} borderRadius={40} />
              </div>
              <div className="flex  pt-4">
                <div className=" w-full  ">
                  <Skeleton count={1} height={60} borderRadius={40} />
                </div>
                <Skeleton width={64} height={60} borderRadius={40} />
              </div>
              <div className="flex  pt-6">
                <div className=" w-full  ">
                  <Skeleton count={1} height={60} borderRadius={40} />
                </div>
                <Skeleton width={64} height={60} borderRadius={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutsPageSkeleton;
