import React from "react";
import { Link } from "react-router-dom";
import {  FaYoutube,FaFacebook } from "react-icons/fa";



function Footer() {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth",
    });
  };
  return (
    <div className="md:py-[16px] flex flex-col  gap-[32px] md:bg-white bg-[#F5F5F5] pt-[24px] pb-[12px] ">
      <div className="md:px-[24px] px-[16px]  ">
      <div className="container mx-auto   ">
        <div className="footer_top_box w-full flex md:flex-row flex-col  items-center md:h-[30px] px-[16px] md:px-0 md:justify-between gap-[32px] justify-center text-center leading-[24px] text-[#231F20]  ">
          <div className="flex md:flex-row flex-col  md:gap-[28px] gap-[20px] font-Rubik font-medium md:text-[18px] text-[20px] ">
            <div className="flex md:gap-[28px] gap-[48px]">

                <Link to="/aboutus" onClick={handleClick}>
              <h4>ABOUT US</h4>
              </Link>
              <Link to="/contactus" onClick={handleClick}>
              <h4>CONTACT US</h4>
              </Link>
            </div>

            <div className="flex md:gap-[28px] gap-[48px]">
                <Link to ="/" onClick={handleClick}>
              <h4>MEMBERSHIP</h4>
              </Link>
              <Link to ="/" onClick={handleClick}>
              <h4>FUNDING</h4>
              </Link>
            </div>

            <div>
                <Link to="/matchscoreviewall" onClick={handleClick}>
              <h4>CRICKET</h4>
              </Link>
            </div>
          </div>

          <div className="icons flex  items-center gap-[22px]  ">
            <FaYoutube className="text-[30.25px]" />
            <FaFacebook  className="text-[25.09px]" />
          </div>
        </div>
      </div>
      </div>
      <div className="flex flex-col gap-[8px] font-Rubik">
      <div className="footer_bottom_box h-[48px] flex text-white    text-[16px]  ">
        <div className="md:px-[24px] px-[16px] w-full flex items-center  ">
        <div className="container mx-auto flex md:justify-between justify-center items-center">
          <h3 className="md:block hidden">Designed by Microwe</h3>

          <div className="flex md:gap-[54px] gap-[38px] ">
          <Link to="/termsandcondition" onClick={handleClick}>
            <h3>Terms & Conditions</h3>
            </Link>
            <Link to="/privacyandpolicy" onClick={handleClick}>
            <h3>Privacy Policy</h3>
            </Link>
          </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex text-[#4D4D4D] justify-center   ">
      <h3 >Designed by Microwe</h3>
      </div>
     
      </div>
    </div>
  );
}

export default Footer;
