import React, { useEffect, useState } from "react";

import SportsBlogCard from "../Cards/SportBlogCard";
function ExploreOursportsblogs({ sportsData }) {
  const [displayCount, setDisplayCount] = useState(2);
  const sortedBlog = sportsData
    .slice()
    .sort((a, b) => a.id - b.id)
    .reverse();
  const limitedBlog = sortedBlog.slice(0, 3);
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath1 = "/storage/blogdetails/";
 
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1540) {
        setDisplayCount(3);
      } else if (windowWidth >= 1280 && windowWidth < 1540) {
        setDisplayCount(3);
      } else if (windowWidth >= 464 && windowWidth < 1280) {
        setDisplayCount(2);
      } else {
        setDisplayCount(1);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
       <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]">
      {limitedBlog.slice(0,displayCount ).map((blog, index) => (
        <SportsBlogCard
        key={index}
        blog={blog}
        apiUrl={apiUrl}
        imagePath1={imagePath1}
        />
      ))}
    </div>
    </>
  );
}

export default ExploreOursportsblogs;
