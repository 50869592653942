import React, { useEffect, useState } from "react";
import { IoPlayCircle } from "react-icons/io5";
import LargeButton from "../../Buttons/Largebutton";
import SportsBlogCard from "../Cards/SportBlogCard";

function BlogViewAll({ sportsData }) {
  const [displayCount, setDisplayCount] = useState(2);
  const [showAllCards, setShowAllCards] = useState(false);

 

 
  const sortedBlog = sportsData.slice().sort((a, b) => b.id - a.id);
  const limitedBlog = sortedBlog.slice(1, 6);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1280) {
        setDisplayCount(showAllCards ? sortedBlog.length : 6);
      } else if (windowWidth >= 660 && windowWidth < 1280) {
        setDisplayCount(showAllCards ? sortedBlog.length : 4);
      } else if (windowWidth >= 468 && windowWidth < 660) {
        setDisplayCount(showAllCards ? sortedBlog.length : 3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showAllCards, sortedBlog.length]);

  const handleViewMore = () => {
    setShowAllCards(!showAllCards);
    
  };

  const apiUrl = process.env.REACT_APP_API_URL;
 
  const imagePath1 = "/storage/blogdetails/";

  if (sortedBlog.length < 2) {
    return <div>
    </div>;
  }

  return (
    <>
      <div className="md:py-[108px] py-[64px] px-[16px] md:px-[24px]">
        <div className="container mx-auto">
          <div className="flex flex-col md:items-start items-center gap-[32px]">
            <div className="flex text-primary italic font-medium gap-[12px] items-center sports-news-heading">
              <IoPlayCircle className="text-[26px]" />
              <h3 className="text-[24px]">RECENT BLOGS</h3>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]">
              
              {limitedBlog.slice(0, displayCount).map((blog, index) => (
                <SportsBlogCard
                  key={index}
                  blog={blog}
                  apiUrl={apiUrl}
                  imagePath1={imagePath1}
                />
              ))}
            </div>

            <div className="w-full md:flex justify-center">
            {showAllCards || displayCount < sportsData.length ? (
                <LargeButton onClick={handleViewMore}>
                  <h3>{showAllCards ? "VIEW LESS" : "VIEW MORE"}</h3>
                </LargeButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogViewAll;
