// logoConfig.js
import logo from "../Assets/Images/logo.png";

const logoConfig = {
  src: logo,
  alt: "Johnians",
};

export default logoConfig;

