import React, { useEffect, useState } from "react";
import {  poster_icon } from "../../Constants/Data";
import PhotooftheweekSkeleton from "../Skeleton/PhotooftheweekSkeleton";

function Sportsnewsposter() {
  const [photoOfTheWeek, setPhotoOfTheWeek] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_PHOTOOFTHEWEEK;
      console.log(`API URL: ${apiUrl}`);

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched data:", data);

        setPhotoOfTheWeek(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      await new Promise((resolve) => setTimeout(resolve, 5000));
    };

    fetchData();
  }, []);

  if (!photoOfTheWeek) {
    return <div>
      <PhotooftheweekSkeleton/>
    </div>;
  }
  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath = "/storage/Photo_of_the_week/";

  return (
    <>
      <div className="md:ml-[85px] ml-[250px] mt-[350px] absolute z-10">
        <img src={poster_icon} alt="Poster Icon" className="object-cover" />
      </div>

      <div
        className="lg:w-[296px] md:w-[260px] w-full h-full relative flex flex-col rounded-[12px] text-white font-Rubik object-contain"
        style={{
          backgroundImage: `linear-gradient(356deg, rgba(0,0,0,0.8), rgba(186,0,0,0.7)), url(${apiUrl}${imagePath}${photoOfTheWeek.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-col gap-[323px] p-[24px]">
          <h4 className="md:text-[14px] text-[16px] leading-[24px]">Photo Of The Week</h4>
          <div className="w-[94px]">
            <h2 className="md:text-[40px] text-[24px] font-semibold italic md:leading-[48px] leading-[28px]">
              {photoOfTheWeek.title || "#ALWAYS PLAY THE GAME"}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sportsnewsposter;
