import React, { useEffect, useState } from 'react'
import { FiArrowUpRight } from 'react-icons/fi'
import MatchCard from '../../Components/Cards/MatchCard';
import CricketViewAllSkeleton from '../../Components/Skeleton/Cricket/CricketViewAllSkeleton';
import { Link } from 'react-router-dom';

function MatchScoreViewAllPage() {
  const [matchData, setMatchData] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL_MATCHES}`);
        const data = await response.json();
        setMatchData(data.data); 
      } catch (error) {
        console.error("Error fetching sports data:", error);
      } finally {
       
      }
    };

    fetchData();
  }, []);

  if (!matchData ) {
    return <div>
          <CricketViewAllSkeleton/>
    </div>;
  }

  const liveMatches = matchData.filter((match) => match.status === "Live");

  const nonLiveMatches = matchData
    .filter((match) => match.status !== "Live")
    .reverse();

  const limitedMatchData = [...liveMatches, ...nonLiveMatches];



  return (
  <>
      <div className="bg-header-gradient h-[144px] "></div>
  
      <div className='pt-[48px] pb-[108px] relative md:px-[24px] px-[16px]'>
        <div className='container mx-auto'>
          <div className='flex flex-col gap-[32px]'>
            <div className='flex flex-col gap-[12px]'>
          <div className="flex gap-[8px] items-center text-[18px] ">
          <Link to='/'>
                <div className="flex gap-[4px] items-center text-[#4D4D4D]">
                  
                  <h3>HOME</h3>
                  <FiArrowUpRight />
                </div>
                </Link>
                <h3 className="text-[#9E120E] underline leading-[20px]">
                  CRICKET
                </h3>
              </div>

              <div className="text-[#A50808] italic lg:text-[36px] md:text-[28px] text-[16px] md:leading-[120%] leading-[160%]">
              <h3> MATCH HIGHLIGHTS</h3>
              <h2 className="font-medium lg:text-[48px] md:text-[40px] text-[28px] ">
              OUR BEST SHOTS ON DISPLAY 
              </h2>
            </div>
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[16px]'>
            {limitedMatchData.map((match) => {
            let statusClassName;
            let viewMatchSummaryClassName;

            switch (match.status) {
              case 'Recent':
                statusClassName = 'bg-[#FFD4D2] text-[#9E120E]';
                viewMatchSummaryClassName = ' flex justify-center text-white text-[14px] font-Rubik bg-red-gradient py-[12px] rounded-b-[12px]';
                break;
              case 'Upcoming':
                statusClassName = 'bg-[#D4F1D2] text-[#006B2B]';
                viewMatchSummaryClassName = 'flex justify-center text-white text-[14px] font-Rubik bg-green-gradient py-[12px] rounded-b-[12px]';
                break;
              case 'Cancelled':
                statusClassName = 'bg-[#D4D4D4] text-[#231F20]';
                viewMatchSummaryClassName = 'flex justify-center text-white text-[14px] font-Rubik bg-[#555555] py-[12px] rounded-b-[12px]';
                break;
                case "Live":
                  statusClassName = "bg-[#F1F1F1] text-[#9E120E]";
                  viewMatchSummaryClassName =
                    "flex justify-center text-white text-[14px] font-Rubik bg-[#B82222] py-[12px] rounded-b-[12px]";
                  break;
              default:
                statusClassName = '';
                viewMatchSummaryClassName = 'flex justify-center text-white text-[14px] font-Rubik py-[12px] rounded-b-[12px]';
                break;
            }

            return (
              <MatchCard 
              key={match.id} 
              title={match.match_name}
              match_date={new Date(match.date).toLocaleDateString("en-US")}
              match_time={match.time}
              status={match.status}
              teams={[
                {
                  school_name: match.team1_name,
                  score: match.team1_score,
                  wicket: match.team1_wicket,
                  overs: match.team1_over,
                  school_logo: match.team1_logo
                },
                {
                  school_name: match.team2_name,
                  score: match.team2_score,
                  wicket: match.team2_wicket,
                  overs: match.team2_over,
                  school_logo: match.team2_logo
                }
              ]}
              result={match.winner_title}
              playground={match.location}
              statusClassName={statusClassName}
              viewMatchSummaryClassName={viewMatchSummaryClassName}
              handleClick={`/matchsummarypage/${match.id}-${match.match_name ? match.match_name.replace(/\s+/g, '') : '-'}-${match.status ? match.status.replace(/\s+/g, '') : '-'}`}
            />

            );
          })}


            </div>

          </div>

        </div>

      </div>
  </>
  )
}

export default MatchScoreViewAllPage