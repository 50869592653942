import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import LatestedBlogs from "../../Components/SportsBlog/LatestedBlogs";
import SocialShareButton from "../../Buttons/SocialShareButton";
import ExtraLargebutton from "../../Buttons/ExtraLargebutton";
import NewsIndividualPageSkeleton from "../../Components/Skeleton/NewsIndividualPageSkeleton";

function BlogIndividualPage() {
  const { id } = useParams();
  const [blogItem, setBlogItem] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = `${process.env.REACT_APP_API_URL_BLOGS}/${id}`;
      console.log("Fetching data from:", apiUrl);

      try {
        const response = await fetch(apiUrl);
        console.log("API response status:", response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log("Fetched data:", data);
        

        setBlogItem(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      }
      
    };

    fetchData();
  }, [id]);

  if (error) {
    return <div><NewsIndividualPageSkeleton/></div>;
  }

  

  if (!blogItem) {
    return <div>Loading...</div>;
  }

  if (blogItem.status === "unpublish") {
    return <div><NewsIndividualPageSkeleton/></div>;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return ` ${day} ${month}, ${year}`;
  };

  const apiUrl = process.env.REACT_APP_API_URL;
  const imagePath1 = "/storage/blogdetails/";
  const imagePath = "/storage/blog/";
  const imagesubtitle = "/storage/blog_subtitles/";
  const blogDetails = blogItem.blog_details;

  const pageUrl = `${window.location.origin}/blogindividualpage/${
    blogItem.id
  }-${
    blogDetails && blogDetails.blog_title
      ? blogDetails.blog_title.replace(/\s+/g, "")
      : ""
  }`;
  const pageTitle =
    blogDetails && blogDetails.blog_title ? blogDetails.blog_title : "";
  const pageImage =
    blogDetails && blogDetails.blog_bannerimage
      ? `${apiUrl}${imagePath1}${blogDetails.blog_bannerimage}`
      : "";

  return (
    <>
      <div className="footer_bottom_box h-[144px]"></div>
      <div className="md:pt-[48px] md:pb-[108px] pb-[64px] font-Rubik ">
        <div className="container mx-auto">
          <div className="flex flex-col gap-[24px]">
            <div className="flex flex-col gap-[48px] md:px-[24px]  ">
              {/* banner content start */}
              <div className="indivual-banner-content flex flex-col gap-[24px]   ">
                <img
                  src={
                    blogDetails.blog_bannerimage
                      ? `${apiUrl}${imagePath1}${blogDetails.blog_bannerimage}`
                      : ""
                  }
                  alt=""
                  className="h-[600px] w-full object-cover md:rounded-[12px]"
                />

                <div className="flex flex-col gap-[16px] px-[16px] md:px-0">
                  <div className="flex justify-between md:flex-row flex-col  gap-[16px]">
                    <div className="flex items-center gap-[16px] italic ">
                      <img
                        src={
                          blogItem.profile_image
                            ? `${apiUrl}${imagePath}${blogItem.profile_image}`
                            : ""
                        }
                        alt=""
                        className="w-[50px] h-[50px] object-cover border-[2px] border-[#D6D6D6] rounded-full"
                      />

                      <div>
                        <h4 className="uppercase font-medium text-[16px]">
                          {blogItem.author_name}
                        </h4>
                        <h5 className="text-[14px]">
                          Published : {formatDate(blogItem.date)}
                        </h5>
                      </div>
                    </div>

                    <div className="share-buttons relative justify-center flex gap-[8px]">
                      <div className=" flex ">
                        <SocialShareButton
                          pageUrl={pageUrl}
                          pageTitle={pageTitle}
                          pageImage={pageImage}
                          imagePath1={imagePath1}
                          className="hidden"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* banner content end */}

              {/* list-content start */}
              <div className="flex flex-col gap-[32px]">
                <div className="list-content flex flex-col gap-[48px] text-[16px]">
                  <h3 className="uppercase px-[16px] md:px-0 font-medium italic text-[#B82222] text-[24px] leading-[150%]">
                    {blogDetails.blog_title}
                  </h3>
                  {Array.isArray(blogDetails.blog_subtitles) &&
                    blogDetails.blog_subtitles.map((subtitle) => (
                      <div
                        key={subtitle.id}
                        className="blog-detail first-content flex flex-col text-[16px]"
                      >
                        <div className="flex lg:flex-row flex-col gap-[32px]">
                          {subtitle.isImageContent === 1 && (
                            <img
                              src={`${apiUrl}${imagesubtitle}${subtitle.subtitle_image}`}
                              alt={subtitle.content_title}
                              className="lg:w-[688px] lg:h-auto h-[402px] object-cover md:rounded-[12px]"
                            />
                          )}
                          <div className="px-[16px] flex flex-col gap-[32px] md:px-0">
                            <div>
                              <h3 className="italic font-medium uppercase">
                                {subtitle.content_title}
                              </h3>
                            </div>
                            <div className="flex flex-col">
                              {subtitle.contents.map((content) => {
                                if (content.content_type === "paragraph") {
                                  return (
                                    <p
                                      className="pt-[16px] text-[16px] text-justify font-light leading-[24px]"
                                      key={content.id}
                                    >
                                      {content.description}
                                    </p>
                                  );
                                } else if (content.content_type === "list") {
                                  return (
                                    <ul
                                      className="pt-[16px] list-disc text-[16px] font-light leading-[24px] pl-[20px]"
                                      key={content.id}
                                    >
                                      <li>{content.description}</li>
                                    </ul>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>

                <div className="flex md:flex-row flex-col  md:px-[24px] px-[16px] gap-4 justify-between">
                  
                  <Link to="/sportsblogviewall#blogform"  className="contact-component">
                    <ExtraLargebutton>
                      <h3 className="uppercase ">create your own blog</h3>
                    </ExtraLargebutton>
                  </Link>

                  <div className=" flex items-center justify-center">
                    <SocialShareButton
                      pageUrl={pageUrl}
                      pageTitle={pageTitle}
                      pageImage={pageImage}
                      imagePath1={imagePath1}
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              {/* list-content end */}
            </div>
          </div>
        </div>
      </div>

      <div>
        <LatestedBlogs idIndivitual={blogItem.id} />
      </div>
    </>
  );
}

export default BlogIndividualPage;
