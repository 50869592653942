import herobanner from "../Assets/Images/hero_banner.png";
import trophy from "../Assets/Images/trophy.svg";
import menu from "../Assets/Images/menu.svg";
import food_locate from "../Assets/Images/food_locate.svg";
import shree_diamond from "../Assets/Images/shree_diamond.svg";
import ceylonce_traders from "../Assets/Images/ceylonce_traders.svg";
import microwe from "../Assets/Images/microwe.svg";
import eagles from "../Assets/Images/eagles.svg";
import yazh_cheetahs from "../Assets/Images/yazh_cheetahs.svg";
import jaffna_stallions from "../Assets/Images/jaffna_stallions.svg";
import get_membership from "../Assets/Images/get_membership_bg.png";
import contact_herobanner from "../Assets/Images/contact_herobanner.png";
import location from "../Assets/Images/location.svg";
import phone from "../Assets/Images/phone.svg";
import gmail from "../Assets/Images/gmail.svg";
import facebook from "../Assets/Images/facebook.svg";
import pattern from "../Assets/Images/pattern.svg";
import sportpattern from "../Assets/Images/sport_pattern.svg";
import plus from "../Assets/Images/plus.svg";
import minus from "../Assets/Images/minus.svg";
import mobileminus from "../Assets/Images/mobile_minus.svg";
import mobileplus from "../Assets/Images/mobile_plus.svg";
import about_why_choose_us from "../Assets/Images/about_why_choose_us.png";
import icon from "../Assets/Images/Icon.svg";
import about_hero from "../Assets/Images/about_hero.png";
import committe_bg from "../Assets/Images/committe_bg.png";
import committe_img from "../Assets/Images/committe_img.png";
import old_history_img from "../Assets/Images/old_history_img.png";
import new_history_img from "../Assets/Images/new_history_img.png";
import get_connected_bg from "../Assets/Images/get_connected_bg.png";
import side_pattern from "../Assets/Images/side_pattern.svg";
import fb from "../Assets/Images/fb.svg";
import youtube from "../Assets/Images/youtube.svg";
import twitter from "../Assets/Images/twitter.svg";
import mail from "../Assets/Images/mail.svg";
import phoneicon from "../Assets/Images/phone_icon.svg";
import videothumbline from "../Assets/Images/video_thumbline.png";
import calender from "../Assets/Images/calender.svg";
import whitecalender from "../Assets/Images/white_calender.svg";
import redcalender from "../Assets/Images/red_calender.svg";
import playbutton from "../Assets/Images/play_button.svg";
import posterbg from "../Assets/Images/poster_bg.png";
import postericon from "../Assets/Images/poster_#.png";
import newsherosection from "../Assets/Images/news_hero_section.png";
import profile from "../Assets/Images/profile.png";
import playgroundicon from "../Assets/Images/playground_Icon.svg";
import match_pattern from "../Assets/Images/match_pattern.svg";
import player_background from "../Assets/Images/player_background.png";
import playerbg from "../Assets/Images/player_bg.png";
import playercardbg from "../Assets/Images/player_card_bg.png";
import viewcardbg from "../Assets/Images/view_card.png";
import playerpattern from "../Assets/Images/player-pattern.svg";
import shopbg from "../Assets/Images/shop-bg.png";
import jersey from "../Assets/Images/jersey.png";
import higlights_banner from "../Assets/Images/higlights_banner.png";
import view_banner from "../Assets/Images/view_banner.png";
import poster1 from "../Assets/Images/poster1.png";
import poster2 from "../Assets/Images/poster2.png";
import poster3 from "../Assets/Images/poster3.png";
const navigationItems = [
  { label: "ABOUT US", path: "/aboutus" },
  { label: "CONTACT US", path: "/contactus" },
  { label: "MEMBERSHIP", path: "/membership" },
  { label: "FUNDING", path: "/funding" },
  { label: "CRICKET", path: "/cricket" },
];
export default navigationItems;



export const homebanner = herobanner;
export const trophy_icon = trophy;
export const menu_icon = menu;
export const foodlocate_logo = food_locate;
export const shreediamond_logo = shree_diamond;
export const ceyloncetraders_logo = ceylonce_traders;
export const microwe_logo = microwe;
export const eagles_logo = eagles;
export const yazh_cheetahs_logo = yazh_cheetahs;
export const jaffna_stallions_logo = jaffna_stallions;
export const get_membership_bg = get_membership;
export const contact_herobanner_bg = contact_herobanner;
export const phone_icon = phone;
export const gmail_icon = gmail;
export const facebook_icon = facebook;
export const location_icon = location;
export const pattern_bg = pattern;
export const sport_pattern_bg = sportpattern;
export const plus_icon = plus;
export const minus_icon = minus;
export const mobile_minus_icon = mobileminus;
export const mobile_plus_icon = mobileplus;
export const about_why_choose_us_img = about_why_choose_us;
export const icon_icon = icon;
export const about_hero_bg = about_hero;
export const committe_background = committe_bg;
export const committe_image = committe_img;
export const old_history_image = old_history_img;
export const new_history_image = new_history_img;
export const get_connected_image = get_connected_bg;
export const side_pattern_pattern = side_pattern;
export const youtube_thumbline = videothumbline;
export const fb_icon = fb;
export const youtube_icon = youtube;
export const twitter_icon = twitter;
export const mail_icon = mail;
export const phone_icon_icon = phoneicon;
export const calender_icon = calender;
export const white_calender = whitecalender;
export const red_calender = redcalender;
export const play_icon = playbutton;
export const poster_bg = posterbg;
export const poster_icon = postericon;
export const newsherosection_bg = newsherosection;
export const profile_img = profile;
export const playground_icon = playgroundicon;
export const match_pattern_pattern = match_pattern;
export const player_bg = player_background;
export const players_background = playerbg;
export const player_card_bg = playercardbg;
export const view_card_bg = viewcardbg;
export const player_pattern = playerpattern;
export const shop_bg = shopbg;
export const jersey_img = jersey;
export const higlightsbanner = higlights_banner;
export const viewbanner = view_banner;
export const posterfirst = poster1;
export const postersecond = poster2;
export const posterthird = poster3;