import React, { useEffect, useState } from "react";
import { IoIosWarning } from "react-icons/io";
import { RiCheckboxCircleFill } from "react-icons/ri";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast'; 


function ShopContactForm({ shopProduct }) {
  const [formData, setFormData] = useState({
    customer_name: "",
    customer_phone: "",
    customer_address: "",
    description: "",
    product_id: shopProduct.id, 
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChangePhone = (value) => {
    // console.log("Phone change:", value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      customer_phone: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      customer_phone: "",
    }));
  };


  
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(`Change in ${name}:`, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form submitted with data:", formData);
    toast.success("Order submitted successfully!");
    const validationRules = {
      customer_name: "Full Name is required",
      customer_address: "Address is required",
      customer_phone: "Mobile Number is required",
      description: "Message is required",
    };

    const newErrors = {};

    Object.entries(validationRules).forEach(([field, rule]) => {
      if (!formData[field].trim()) {
        newErrors[field] = rule;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        setLoading(true);
        const response = await axios.post(`${import.meta.env.REACT_APP_API_URL}/api/order/create`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        
        if (response.status ) {
          setFormData({
            customer_name: "",
            customer_phone: "",
            customer_address: "",
            description: "",
            product_id: shopProduct.id, 
          });
          setSubmitted(true);
          toast.success("Order submitted successfully!");
          
        }
      } catch (error) {
        console.error("There was an error submitting the form:", error);
        
        toast.error("There was an error submitting the form.");
      } finally {
        setLoading(false);
      }
      
    }else {
      console.log("Form validation errors:", newErrors);
    }
  };

  useEffect(() => {
    if (submitted) {
      const timer = setTimeout(() => {
        setSubmitted(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [submitted]);

  return (
    <>

<Toaster
  position="bottom-center"
  reverseOrder={false}
/>
      <div className="rounded-[12px] flex flex-col gap-[8px] w-full font-Rubik">
        <h4 className="text-[#21212167] uppercase font-medium text-[16px]">
          {shopProduct.product_category}
        </h4>
        <h2 className="text-[#9E120E] md:text-[36px] text-[24px] uppercase font-medium xl:w-[566px] italic">
          THE JOHNIANS CRICKET {shopProduct.name}
        </h2>
        <div className="contact-form-background font-Rubik rounded-[20px] h-full w-full pt-[34px] md:px-[24px] px-[16px] pb-[24px]">
          <form className="flex flex-col w-full gap-[20px]" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-[16px]">
              <div className="grid items-start lg:grid-cols-1 grid-cols-1 md:grid-cols-2 gap-4 font-medium leading-[24px]">
                <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]">
                  <div className="flex justify-between px-[4px]">
                    <label>FULL NAME</label>
                  </div>
                  <input
                    type="text"
                    name="customer_name"
                    placeholder="Enter Name"
                    value={formData.customer_name}
                    onChange={handleChange}
                    className={`input bg-[#ffffff] text-[14px] text-[#4D4D4D] border-b px-[16px] pl-[16px] outline-none lining-nums placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6] focus:placeholder-[#F6F6F6] valid:before:bg-black focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px] rounded-[12px] ${
                      errors.customer_name ? "border-red-500" : ""
                    }`}
                  />
                  {errors.customer_name && (
                    <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                      {errors.customer_name}
                      <IoIosWarning />
                    </span>
                  )}
                </div>
                <div className="grid items-start lg:grid-cols-1 grid-cols-1 gap-4 font-medium leading-[24px]">
                  <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]">
                    <div className="flex justify-between px-[4px]">
                      <label>PHONE</label>
                    </div>
                    <PhoneInput
                      defaultCountry="LK"
                      value={formData.customer_phone}
                      onChange={handleChangePhone}
                      placeholder="(000) 000-0000"
                      className={`input bg-[#ffffff] text-[14px] text-[#4D4D4D] border-b px-[16px] pl-[16px] outline-none lining-nums placeholder-[#939191] placeholder:text-[14px] h-[55px] hover:bg-[#F6F6F6] focus:placeholder-[#F6F6F6] valid:before:bg-black focus:bg-[#F6F6F6] placeholder-shown:bg-[#ffffff] border-solid border-[1px] rounded-[12px] ${
                        errors.customer_phone ? "border-red-500" : ""
                      }`}
                    />
                    {errors.customer_phone && (
                      <span className="flex gap-[4px] items-center text-sm text-red-700 font-Raleway">
                        {errors.customer_phone}
                        <IoIosWarning />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]">
                <div className="flex justify-between px-[4px]">
                  <label>ADDRESS</label>
                </div>
                <textarea
                  name="customer_address"
                  rows={6}
                  value={formData.customer_address}
                  onChange={handleChange}
                  className={`bg-[#ffffff] rounded-[12px] text-[14px] font-medium text-[#4D4D4D] px-[16px] pl-[16px] outline-none lining-nums placeholder-[#939191] placeholder:font-medium placeholder:text-[16px] h-[91px] hover:bg-[#F6F6F6] pt-[16px] focus:placeholder-[#F6F6F6] valid:before:bg-black placeholder-shown:bg-[#ffffff] border-[1px] focus:bg-[#F6F6F6] ${
                    errors.customer_address ? "border-red-500" : ""
                  }`}
                  placeholder="Type your current address here..."
                ></textarea>
                {errors.customer_address && (
                  <span className="flex gap-[4px] items-center text-sm text-red-700">
                    {errors.customer_address}
                    <IoIosWarning />
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-[8px] text-[16px] font-medium text-[#4D4D4D] leading-[24px]">
                <div className="flex justify-between px-[4px]">
                  <label>MESSAGE</label>
                </div>
                <textarea
                  name="description"
                  rows={6}
                  value={formData.description}
                  onChange={handleChange}
                  className={`bg-[#ffffff] rounded-[12px] text-[14px] font-medium text-[#4D4D4D] px-[16px] pl-[16px] outline-none lining-nums placeholder-[#939191] placeholder:font-medium placeholder:text-[16px] h-[200px] hover:bg-[#F6F6F6] pt-[16px] focus:placeholder-[#F6F6F6] valid:before:bg-black placeholder-shown:bg-[#ffffff] border-[1px] focus:bg-[#F6F6F6] ${
                    errors.description ? "border-red-500" : ""
                  }`}
                  placeholder="Type something here..."
                ></textarea>
                {errors.description && (
                  <span className="flex gap-[4px] items-center text-sm text-red-700">
                    {errors.description}
                    <IoIosWarning />
                  </span>
                )}
              </div>
            </div>

            <button
              className={`h-[60px] text-[18px] text-white uppercase flex items-center justify-center ${
                loading
                  ? "bg-[#5D0F0C]"
                  : submitted
                  ? "bg-black"
                  : "bg-[#9E120E] rounded-[8px] hover:bg-[#7E100C]"
              }`}
              disabled={loading}
              style={{ position: "relative" }}
            >
              {loading && (
                <div className="absolute flex items-center ml-[20px]">
                  <div className="loader"></div>
                </div>
              )}
              {!loading && (
                <>
                  {submitted && (
                    <RiCheckboxCircleFill className="w-6 h-6 text-white mr-2" />
                  )}
                  {submitted ? "Submitted" : "SEND YOUR ORDER"}
                </>
              )}
              {loading && !submitted && <span className="ml-2">Sending</span>}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default ShopContactForm;
