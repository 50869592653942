import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import SportsBlogCard from "../Cards/SportBlogCard";

function SportBlogMobileCarosel({ sportsData }) {


  const sortedNews = sportsData
    .slice()
    .sort((a, b) => a.id - b.id)
    .reverse();
  const limitedNews = sortedNews.slice(0, 3);

  const apiUrl = process.env.REACT_APP_API_URL;

  const imagePath1 = "/storage/blogdetails/";

  return (
    <Swiper
    spaceBetween={30}
    centeredSlides={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    pagination={{
      clickable: true,
    }}
    navigation={true}
    modules={[Autoplay, Pagination, Navigation]}
    className="swiper-container"
  >
    {limitedNews.map((blog, index) => (
      <SwiperSlide key={index}>
        <SportsBlogCard
         blog={blog}
         apiUrl={apiUrl}
         imagePath1={imagePath1}
        />
      </SwiperSlide>
    ))}
  </Swiper>

  );
}

export default SportBlogMobileCarosel;
