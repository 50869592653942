import React, { useEffect, useState } from "react";
import PlayerProfile from "./PlayerProfile";
import SportsPlayers from "./SportsPlayers";
import SportsPlayerCarosel from "./SportsPlayerCarosel";
import { player_bg } from "../../Constants/Data";
import PlayersLandingSkeleton from "../Skeleton/Players/PlayersLandingSkeleton";

function SportsPlayersMain() {
  const [players, setAllSportsPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL_PLAYERS;

      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        if (data.success && Array.isArray(data.data)) {
          const playerData = data.data.map((item) => item.player);
          const publishedPlayers = playerData.filter(
            (player) => player.status === "Published"
          );
          console.log("Filtered published players:", publishedPlayers);
          setAllSportsPlayers(publishedPlayers);
          if (publishedPlayers.length > 0) {
            setSelectedPlayer(publishedPlayers[0]);
          }
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
  };

  console.log("All sports players:", players);
  if (!players.length) {
    return (
      <div>
        <PlayersLandingSkeleton />
      </div>
    );
  }

  return (
    <div
      className="w-full flex flex-col opacity-100  text-white font-Rubik object-contain"
      style={{
        backgroundImage: ` linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),url(${player_bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="py-[40px] md:px-[24px] px-[16px]">
        <div className="container mx-auto">
          <div className="flex lg:flex-row flex-col gap-[36px] xl:gap-[73px] justify-between">
            <div className="w-full">
              {selectedPlayer && <PlayerProfile player={selectedPlayer} />}
            </div>
            <div className="sportsplayers overflow-hidden">
              <SportsPlayers
                players={players}
                onPlayerClick={handlePlayerClick}
              />
            </div>
            <div className="playercarosel">
              <SportsPlayerCarosel players={players} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SportsPlayersMain;
