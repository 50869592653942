import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {  player_pattern } from "../../../Constants/Data";
function PlayerIndividualSkeleton() {
    const skeletonCards = Array(4).fill(0);
  return (
    <>
         <div className="bg-header-gradient h-[144px] z-10 "></div>

    <div className="py-[48px] relative z-10">
        <div className="container mx-auto">
      <div className="lg:h-[544px] mb-[92px] bg-custom-gradient animate-pulse flex lg:flex-row flex-col w-full  rounded-[12px]">
        <div className=" bg-[#F2F2F2] lg:hidden flex justify-center">
        <img src={player_pattern} alt="" className="    " />
        </div>
        <div className="h-full flex flex-col py-[16px]   justify-center px-[20px] w-full rounded-r-[12px]">
          <Skeleton width={150} height={40} count={2} borderRadius={12} />
          <span className="mt-[16px]">
            {" "}
            <Skeleton width={65} height={25} borderRadius={12} />
          </span>
          <div className="flex ">
            <div className="border-t-[1px] border-[#CECECE] mt-[16px]">
              <div className="border-r-[1px] px-[16px] py-[12px]  border-[#CECECE]">
                <span>
                  <Skeleton width={65} height={15} borderRadius={12} />

                  <Skeleton width={120} height={15} borderRadius={12} />
                </span>
              </div>
            </div>

            <div className="border-t-[1px] border-[#CECECE] mt-[16px]">
              <div className="border-r-[1px] px-[16px] py-[12px]  border-[#CECECE]">
                <span className="">
                  <Skeleton width={65} height={15} borderRadius={12} />

                  <Skeleton width={120} height={15} borderRadius={12} />
                </span>
              </div>
            </div>
            <div className="border-t-[1px] md:block hidden border-[#CECECE] mt-[16px]">
              <div className=" px-[16px] py-[12px]  border-[#CECECE]">
                <span className="">
                  <Skeleton width={65} height={15} borderRadius={12} />

                  <Skeleton width={120} height={15} borderRadius={12} />
                </span>
              </div>
            </div>
          </div>
          <div className="mt-[32px]">
          <div className="">
            <div className="w-[220px]">
                  <Skeleton  height={15} borderRadius={12} />
                  </div>

                  <Skeleton count={5}  height={15} borderRadius={12} />
                </div>

          </div>
        </div>
        <div className=" lg:flex justify-start items-center  hidden ">
                  <img src={player_pattern} alt="" className=" absolute  -z-10   " />
                </div>

        <div className="lg:flex hidden  justify-end w-full">
          <div className="bg-[#adabab] h-full w-full lg:w-[220px] rounded-r-[12px]"></div>
        </div>
        <div className="h-[614px] lg:hidden bg-[#DAD9DC]">

        </div>
      </div>
      <div className="my-[64px]">
        <div className="flex justify-between">
        <Skeleton width={200} height={25}  borderRadius={12} />
        <Skeleton width={200} height={25}  borderRadius={12} /> 
        </div>
        <div className="grid mt-[16px] lg:grid-cols-3 xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
        {skeletonCards.map((_, index) => (
          <div
            key={index}
            className=" h-[341px] rounded-[12px] p-[20px] bg-[#E9E9E9] flex flex-col justify-end items-center animate-pulse"
          >
            <div>
              <Skeleton width={170} height={20} borderRadius={12} />
            </div>
            <div className="bg-[#b1b1b1] mt-[10px] h-[75px] w-full rounded-[8px]"></div>
          </div>
        ))}
      </div>
      </div>
      </div>
      </div>
    </>
  );
}

export default PlayerIndividualSkeleton;
