import React, { useEffect, useState } from "react";
import { IoPlayCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import Smallbutton from "../../Buttons/Smallbutton";
import HighlightsMain from "./HighlightsMain";
import HighlightsMobileCarosel from "./HighlightsMobileCarosel";
import HighlightsLandingSkeleton from "../Skeleton/Highlights/HighlightsLandingSkeleton";
import { higlightsbanner } from "../../Constants/Data";

function Highlights() {
  const [highlightsData, setHighlightsData] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [setCountText] = useState("1 of 0");

  

  const [modalOpen, setModalOpen] = useState(false); 
  const [selectedHighlightId, setSelectedHighlightId] = useState(null);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"  
    });
  };
  

  useEffect(() => {
    fetchHighlightsData();
  }, []);

  const fetchHighlightsData = () => {
    fetch(`${process.env.REACT_APP_API_URL_HIGHLIGHTS}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setHighlightsData(data.data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const handleHighlightClick = (highlightId) => {
    setSelectedHighlightId(highlightId);
    setModalOpen(true);
  };

  // Function to close modal
  const handleClose = () => {
    setModalOpen(false);
  };

  const nextItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === highlightsData.length - 1 ? 0 : prevIndex + 1
    );
  };
  
  const prevItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === 0 ? highlightsData.length - 1 : prevIndex - 1
    );
  };

  const updateCountNumber = (index) => {
    const currentCount = index + 1;
    const totalCount = highlightsData.length;
    setCountText(`${currentCount} of ${totalCount}`);
  };
  if (!highlightsData.length) {
    return <div>
<HighlightsLandingSkeleton/>
    </div>;
  }
  

  return (
    <div
    className=" flex md:flex-row flex-col px-[16px] md:px-[24px] pt-[50px] md:pt-0 md:justify-center   md:items-end  text-start text-white font-Rubik md:h-[616px]  md:w-full object-cover     "
    style={{
      backgroundImage: `url(${higlightsbanner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <div className="pb-[110px] md:px-[24px] px-[16px] overflow-hidden  ">
      <div className="container mx-auto">
        <div className="flex flex-col gap-[16px]">
          {/* gallery heading */}
          <div className="flex md:justify-between justify-center pr-[30px]">
            <div className="flex text-white italic font-medium  gap-[12px] items-center sports-news-heading">
              <IoPlayCircle className="text-[26px]" />
              <h3 className="md:text-[24px] text-[18px]">HIGHLIGHTS </h3>
            </div>

            <Link to="/highlightsviewall" className="md:block hidden contact-component" onClick={handleClick} >
              <Smallbutton bgColor="#FFFFFF" textColor="#9E120E">
                
                <h2>VIEW ALL</h2>
              </Smallbutton>
            </Link>
          </div>
          

          <div className="md:block hidden">
            <HighlightsMain
              highlightsData={highlightsData}
              handleHighlightClick={handleHighlightClick}
              modalOpen={modalOpen}
              selectedHighlightId={selectedHighlightId}
              handleClose={handleClose}
              nextItem={nextItem}
              prevItem={prevItem}
              updateCountNumber={updateCountNumber}
              currentItemIndex={currentItemIndex}
            
            />
          </div>
          
          <div className="md:hidden block">
            <HighlightsMobileCarosel
             highlightsData={highlightsData}
             handleHighlightClick={handleHighlightClick}
             modalOpen={modalOpen}
             selectedHighlightId={selectedHighlightId}
             handleClose={handleClose}
             nextItem={nextItem}
             prevItem={prevItem}
             updateCountNumber={updateCountNumber}
             currentItemIndex={currentItemIndex}
              
            />
          </div>

          <Link to="/highlightsviewall" className="md:hidden block z-0" onClick={handleClick}>
            <Smallbutton bgColor="#FFFFFF" textColor="#9E120E">
              <h2>VIEW ALL</h2>
            </Smallbutton>
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Highlights;
