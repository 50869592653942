import React from 'react'
import { GoArrowUp } from 'react-icons/go'

function ExtraLargebutton({ onClick, disabled, children }) {
  return (
    <div className=" md:peer-hover:bg-[#414040] hover:rounded-[20px]   flex items-center  justify-start ">
      <button
        className=" bg-[#9E120E] z-10 hover:bg-[#414040] md:relative flex md:flex-row  flex-row-reverse items-center justify-center  md:w-[232px] w-full   h-[44px] rounded-[20px] text-white font-Rubik italic text-[16px] leading-[16px]"
        onClick={onClick}
        disabled={disabled}
      >
        <div className=" md:flex flex-col hover:bg-[#414040]  hidden overflow-hidden md:absolute relative md:ml-[250px] md:items-center items-end md:justify-center justify-end text-[24px] h-[44px] w-[44px] text-white rounded-full bg-[#9E120E] -ml-[30px]">
          <ul className="menu rotate-45">
            <li>
              <a>
                <span>
                  <GoArrowUp className="hi-icon hi-icon-mobile" />
                </span>
                <span>
                  <GoArrowUp className="hi-icon hi-icon-mobile" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        {children}
      </button>

    </div>
  )
}

export default ExtraLargebutton